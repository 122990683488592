import React from 'react'

export default function ChatHistoryComponent() {
  return (
    <div class="chatbot-history-grid">
      <header class="d-md-flex align-items-center justify-content-between">
          <h3>My Chat History</h3>

          <div class="group-form d-md-flex">
              <input type="date" class="form-control w-140" id="" name="" />							
              <input type="time" class="form-control w-140" id="" name="" />
              <select class="form-select w-200">
                  <option selected="">Chatbot: All</option>
                  <option value="">Chatbot 1</option>
                  <option value="">Chatbot 2</option>
              </select>
          </div>
      </header>

      <div class="table-responsive">
          <table class="table align-middle">
              <thead>
                  <tr>
                      <th class="text-left">User Name</th>
                      <th class="text-center">Date</th>
                      <th class="text-center">Time</th>
                      <th class="text-left message">Message</th>
                      <th class="text-center">Chatbot</th>
                      <th class="text-center action">Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Jane Cooper</td>
                      <td class="text-center">12 Jan 2024</td>
                      <td class="text-center">10:20 am</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 1</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>
                  <tr>
                      <td class="text-left">Eugene Goostman</td>
                      <td class="text-center">03 Oct 24</td>
                      <td class="text-center">John Doe</td>
                      <td class="text-left message">when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</td>
                      <td class="text-center">chatbot 2</td>
                      <td class="text-center action">
                          <a href="javascript:void(0)"><i class="fa-solid fa-ellipsis-vertical"></i></a>
                      </td>
                  </tr>									
              </tbody>
          </table>
      </div>
  </div>
  )
}
