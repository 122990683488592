import React from "react";

const Dropdown = ({ label, options, value, onChange, error, isRequired= true, disabled=false }) => {
  return (
    <div className="field">
      <label>{label}{isRequired &&<span style={{color: "red"}}>*</span>}</label>
      <select
        className={`form-select ${error ? "is-invalid" : ""}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        style={{
          color: disabled && 'black',
          fontWeight: disabled && '600',
        }}
      >
        <option value="">Please Select</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className="invalid-feedback">{error.message}</span>}
    </div>
  );
};

export default Dropdown;
