import React from 'react';
import Header from './header';
import Footer from './footer';

const GuestLayout = ({ children }) => {

    return (
        <>
            <Header/>
            {children}
            <Footer />
        </>
    )
}

export default GuestLayout