import React, { useEffect } from "react";
import Select from "react-select";

const CustomSelect = ({
  label,
  options,
  value,
  onChange,
  error,
  isMulti = false,
}) => {
  const handleChange = (selectedOptions) => {
    if (isMulti) {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      onChange(selectedValues);
    } else {
      onChange(selectedOptions?.value || "");
    }
  };

  return (
    <div className="field">
      {label && <label>{label}</label>}
      <Select
        isMulti={isMulti}
        options={options}
        value={
          isMulti
            ? options.filter((option) => value?.includes(option.value))
                .length !== 0
              ? options.filter((option) => value?.includes(option.value))
              : value
            : options.find((option) => option.value === value) || null
        }
        onChange={handleChange}
        placeholder={
          isMulti ? "Select multiple options..." : "Select an option..."
        }
        noOptionsMessage={() => "No options available"}
        classNamePrefix="custom-select" // Scoped class name prefix for styling
        defaultValue={value}
      />
      {error && <span className="invalid-feedback">{error.message}</span>}
    </div>
  );
};

export default CustomSelect;
