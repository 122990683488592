import React from 'react';
import { MuiTelInput } from "mui-tel-input"
import styled from 'styled-components';
import PhoneNumber from "awesome-phonenumber"

const ChatTemplate = styled.div`
  width: 365px;
  height: 60vh;
  z-index: 100;
  position: fixed;
  right: 60px;
  bottom: 0;
  border-radius: 16px;
  overflow: hidden;
`;

const LiveChatboxGrid = styled.div`
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const LiveChatboxMessage = styled.div`
  height: calc(100% - 88px);
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 83px;
`;

const MessageBox = styled.div`
  align-items: flex-start;
`;

const LiveChatboxFooter = styled.div`
  bottom: 0;
  position: absolute;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;

  &.live-chat_one {
    background: #f8f8f8;
  }

  &.live-chat_two {
    background: #f8f8f8;
  }
`;

const FormControlLg = styled.input`
  border: 0;
  width: 100%;
  font-size: 14px;
  display: block;
  padding: 12px 10px 12px 10px;
  background: #6c757d;
  color: #000000;

  &.live-chat_two {
    background: #fff;
  }
`;

const BotColorBox = styled.div`
  border-radius: 15px;
  background-color: rgba(57, 192, 237, 0.2);
  padding: 10px;
`;

const SenderColorBox = styled.div`
  border-radius: 15px;
  padding: 10px;
  background-color: #e9ecef;
`;

const AvatarImage = styled.img`
  width: 45px;
`;


export function BotAppearance1({ ...props }) {

    console.log("props?.messageList1--", props?.messageList, props?.type)

    return (
        <div >
            <ChatTemplate className="col-md-12 col-lg-12 col-xl-12">
                <LiveChatboxGrid className="card" id="chat1" style={{ borderRadius: '15px' }}>
                    <div
                        className="card-header header-back-color d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                        style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                    >
                        <p className="mb-0 fw-bold">Live Chat</p>
                        <i className="fas fa-times" onClick={() => props?.setOpenBotTestModal(false)}></i>
                    </div>
                    <LiveChatboxMessage ref={props.chatContainerRef} className="card-body page-back-color">
                        {props?.messageList?.length > 0 && props?.messageList?.map((msg, index) => (
                            <>
                                {msg.directionType == "receive"
                                    ? <MessageBox className="d-flex flex-row justify-content-start mb-4 message_box align-items-start">
                                        <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                        <BotColorBox className="p-3 ms-3 bot-color-box"
                                            style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}>
                                            <p className="small mb-0 bot-text-color">{msg?.question}</p>
                                            {msg?.options &&
                                                <ul className="chatflow-option">
                                                    {msg?.options?.length > 0 && msg.options.map((option, ind) =>
                                                    (msg?.type == "single-choice"
                                                        ? <button disabled={msg.isAnswered} className="cta-border-w option-color" onClick={() => props.handleReply(option, "single-choice")}>{option.label}</button>
                                                        : < >
                                                            <input type="checkbox"
                                                                disabled={msg.isAnswered}
                                                                value={option}
                                                                onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                onKeyDown={(e) => props.handleKeypress(e, props?.type)} />
                                                            <label for="vehicle1">{option}</label><br />
                                                        </>)
                                                    )}
                                                </ul>}
                                        </BotColorBox>
                                    </MessageBox>
                                    : <MessageBox className="d-flex flex-row justify-content-end mb-4">
                                        <SenderColorBox className="p-3 me-3 border bg-body-tertiary sender-color-box" style={{ borderRadius: '15px' }}>
                                            <p className="small mb-0 sender-text-color">{msg.user_message}</p>
                                        </SenderColorBox>
                                    </MessageBox>}
                            </>
                        )
                        )}
                        {props.responseDelay &&
                            <MessageBox className="d-flex flex-row justify-content-end mb-4 message_box align-items-start">
                                <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                <div>
                                    <p className="small mb-0 bot-text-color">...</p>
                                </div>
                            </MessageBox>}
                    </LiveChatboxMessage>

                    <LiveChatboxFooter className="card-footer footer-back-color live-chatbox_footer live-chat_one text-muted d-flex justify-content-start align-items-center p-3">
                        <div className="card-footer_box d-flex align-items-center w-100">
                            {props?.type == "phone"
                                ? <MuiTelInput
                                    name="mobile"
                                    value={props.inputValue}
                                    id="input-control-id"
                                    fullWidth={true}
                                    variant="outlined"
                                    forceCallingCode
                                    onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                    enableLongNumbers={true}
                                    autoFormat={false}
                                    disableAreaCodes={true}
                                    countryCodeEditable={false}
                                    autoComplete="off"
                                    className="customtel"
                                />
                                : <FormControlLg
                                    id="input-control-id"
                                    value={props.inputValue}
                                    disabled={props?.type == "single-choice"}
                                    type="text"
                                    className="form-control-lg live-chat_one"
                                    placeholder="Type your message"
                                    onChange={(e) => props.handleSetInputvalue(e)}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                />}
                            <a className="ms-3" href="javascript:void(0)" onClick={(e) => props.handleReply(e, props?.type)}>
                                <i className="fas fa-paper-plane button-text-color"></i>
                            </a>

                        </div>
                    </LiveChatboxFooter>

                </LiveChatboxGrid>
            </ChatTemplate>
        </div>
    )
}

export function BotAppearance2({ ...props }) {

    console.log("props?.messageList2--", props?.messageList, props?.type)

    return (
        <div>
            <ChatTemplate className="col-md-12 col-lg-12 col-xl-12">
                <LiveChatboxGrid className="card live-chatbox_grid" id="chat2">
                    <div className="card-header header-back-color d-flex justify-content-between align-items-center text-white p-3">
                        <h5 className="mb-0">Live Chat</h5>
                        <i className="fas fa-times" onClick={() => props?.setOpenBotTestModal(false)}></i>
                    </div>
                    <LiveChatboxMessage
                        ref={props.chatContainerRef}
                        className="card-body page-back-color perfect-scrollbar ps ps--active-y"
                        style={{ position: 'relative', height: '100%' }}
                        data-mdb-perfect-scrollbar-init
                    >
                        {props?.messageList?.length > 0 && props?.messageList?.map((msg, index) => (
                            <>
                                {msg.directionType == "receive"
                                    ? <MessageBox className="d-flex flex-row justify-content-start">
                                        <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                        <div>
                                            <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color">{msg?.question}</p>
                                            {msg?.options &&
                                                <ul className="chatflow-option">
                                                    {msg?.options?.length > 0 && msg.options.map((option, ind) =>
                                                    (msg?.type == "single-choice"
                                                        ? <button disabled={msg.isAnswered} className="cta-border-w option-color" onClick={() => props.handleReply(option, "single-choice")}>{option.label}</button>
                                                        : < >
                                                            <input type="checkbox"
                                                                value={option}
                                                                disabled={msg.isAnswered}
                                                                onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                onKeyDown={(e) => props.handleKeypress(e, props?.type)} />
                                                            <label for="vehicle1">{option}</label><br />
                                                        </>)
                                                    )}
                                                </ul>}
                                        </div>
                                    </MessageBox>
                                    : <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                        <div>
                                            <p className="small p-2 me-3 mb-1 text-white rounded-4 bg-secondary sender-color-box sender-text-color">{msg.user_message}</p>
                                        </div>
                                    </div>}
                            </>
                        )
                        )}
                        {props.responseDelay &&
                            <MessageBox className="d-flex flex-row justify-content-start message_box align-items-start">
                                <AvatarImage src={props?.botBubbleIcon} alt="avatar 1" />
                                <div>
                                    <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color">...</p>

                                </div>
                            </MessageBox>}
                    </LiveChatboxMessage>

                    <LiveChatboxFooter className="card-footer footer-back-color live-chatbox_footer live-chat_two text-muted d-flex justify-content-start align-items-center p-3">
                        <div className="card-footer_box rounded-3 pr-10 border d-flex align-items-center w-100 bg-white">

                            {props?.type == "phone"
                                ? <MuiTelInput
                                    name="mobile"
                                    value={props.inputValue}
                                    id="input-control-id"
                                    fullWidth={true}
                                    variant="outlined"
                                    forceCallingCode
                                    onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                    enableLongNumbers={true}
                                    autoFormat={false}
                                    disableAreaCodes={true}
                                    countryCodeEditable={false}
                                    autoComplete="off"
                                    className="customtel"
                                />
                                : <FormControlLg
                                    id="input-control-id"
                                    value={props.inputValue}
                                    disabled={props?.type == "single-choice"}
                                    type="text"
                                    className="form-control-lg"
                                    placeholder="Type your message"
                                    onChange={(e) => props.handleSetInputvalue(e)}
                                    onKeyDown={(e) => props.handleKeypress(e, props?.type)}
                                />}
                            {/* {props?.type == "phone" && !new PhoneNumber(`${props.inputValue}`).isValid()
                                ? <i className="fas fa-paper-plane button-text-color"></i>
                                : <> */}
                            <a className="ms-3" href="javascript:void(0)" onClick={(e) => props.handleReply(e, props?.type)}>
                                <i className="fas fa-paper-plane button-text-color"></i>
                            </a>
                            {/* </>} */}
                        </div>
                    </LiveChatboxFooter>
                </LiveChatboxGrid>
            </ChatTemplate>
        </div>
    )
}