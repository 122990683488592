import React, { useCallback, useEffect, useMemo } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import { LABEL, CONTACT_US_TABLE_CONSTANT } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import useAuth from "../../hooks/useAuth";

export default function ContactUsManagement() {
  const { getAllContactUs, contactUsUsers, isLoading } = useCmsManagement();

  const { user } = useAuth();

  useEffect(() => {
    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    fetchAllData(1);
  }, []);

  function fetchAllData(page) {
    getAllContactUs(page);
  }

  function handlePagination(page) {
    fetchAllData(page);
  }

  const pagination = useMemo(() => {
    return {
      current: contactUsUsers.currentPage,
      pageSize: contactUsUsers.pageSize,
      total: contactUsUsers.totalRecords,
      onChange: handlePagination,
    };
  }, [
    contactUsUsers.currentPage,
    contactUsUsers.pageSize,
    contactUsUsers.totalRecords,
    fetchAllData,
  ]);

  console.log("==========>contactUsUsers", contactUsUsers);

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Contact Us Management</h3>
        </header>
        <CommonTable
          data={contactUsUsers?.contactUsData || []}
          columns={CONTACT_US_TABLE_CONSTANT.usersManagementcolumns}
          pagination={pagination}
          isPagination={true}
          loading={isLoading}
        />
      </div>
    </React.Fragment>
  );
}
