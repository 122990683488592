import React, { createContext, useEffect, useReducer } from 'react';
// import { useHistory } from 'react-router-dom';
import Cookie from '../utils/Cookie';
import SplashScreen from '../components/SplashScreen';
import { jwtDecode } from "jwt-decode"
import { CMS_BASE_URL, EMAIL_VERIFICATION_OTP, lOGIN, ME, SIGN_UP, SUPER_LOGIN } from '../utils/APIEndPoints';
import { CallAPI } from '../utils/apiActions';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../helper/toastHelper';
import { LABEL } from '../utils/constant';
import useDashboard from '../hooks/useDashboard';
import Signup from '../pages/Signup';

const initialAuthState = {
  subscriptionPayload: null,
  isEmailVerified: "NA",
  isAuthenticated: false,
  isLogout: false,
  isInitialised: false,
  user: null,
  loader: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user: user
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'USER_UPDATE': {
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        isInitialised: true,
      };
    }
    case 'LOADER_UPDATE': {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    }
    case 'EMAIL_VERIFIED': {
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    }
    case 'CREATE_SUBSCRIPTION_PAYLOAD': {
      return {
        ...state,
        subscriptionPayload: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => { },
  updateUser: () => Promise.resolve(),
  updateLoader: () => Promise.resolve(),
  updateEmailVerified: () => Promise.resolve(),
  createSubscriptionPayload: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { planPurchase, setPlanPurchase } = useDashboard();

  const me = async (cb) => {
    const response = await CallAPI("WEBSITE", "GET", ME);
    if (response?.res?.data?.status === 200) {
      if (response.res.data.data.userType!=1 && !response.res.data.data.isEmailVerified) {
        await CallAPI("USER", "POST", EMAIL_VERIFICATION_OTP, { email: response.res.data.data.email, actionType: "post_signup" })
        updateEmailVerified(false)
        localStorage.setItem("email-verify", response.res.data.data.email)
        localStorage.setItem("action-type", "post_signup")
        navigate('/email-verification')
      }
      else {
        dispatch({
          type: 'USER_UPDATE',
          payload: response?.res?.data?.data || [],  // New role value
        });
      }
      if (cb) cb();
    } else {
      return { loginData: response.msg, response: false };
    }
  };

  const cb = () => navigate('/dashboard')

  const login = async (body, type = "", promoCode = "") => {
    updateLoader(true)
    const response = await CallAPI("WEBSITE", "POST", lOGIN, body);
    if (response.status) {
      localStorage.setItem('accessToken', response.res.data.token);
      if (response.res.data.data.userType!=1 && !response.res.data.data.isEmailVerified) {
        if (type == "with-plan") {
          createSubscriptionPayload({
            userId: response.res.data.data.id,
            planId: planPurchase?.id,
            promotionCode: !!promoCode ? promoCode : "",
            // returnUrl: `${process.env.REACT_APP_FRONTEND_BASE_URL}payment-status`,
            returnUrl: 'http://localhost:3000/payment-status',
          })
        }
        await CallAPI("USER", "POST", EMAIL_VERIFICATION_OTP, { email: body.email, actionType: "post_signup" })
        updateEmailVerified(false)
        localStorage.setItem("email-verify", body.email)
        localStorage.setItem("action-type", "post_signup")
        navigate('/email-verification')
        // if(planPurchase?.id){
        //   const user = response?.res?.data?.data;
        //   const createSubscriptionPayload = {
        //     userId: user.id,
        //     planId: planPurchase?.id,
        //     promotionCode: user?.promoCode && !user?.isUsedPromoCode ? user.promoCode : "",
        //     returnUrl: `${process.env.REACT_APP_FRONTEND_BASE_URL}payment-status`,
        //   }

        //   const createSubscriptionResponse = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.USER_SUBSCRIPTION, createSubscriptionPayload);

        //   const redirectUrl = createSubscriptionResponse.res.data?.data.url;

        //   if(redirectUrl){
        //     window.location.href = redirectUrl;
        //   }

        // }
        // navigate('/dashboard')
        // me(cb)
      }
      else {
        dispatch({
          type: 'USER_UPDATE',
          payload: response?.res?.data?.data || [],  // New role value
        });
      }
    } else {
      updateLoader(false)
      return { loginData: response.msg, response: false };
    }
    updateLoader(false)
  };

  const superLogin = async (body) => {
    updateLoader(true)
    const response = await CallAPI("WEBSITE", "POST", SUPER_LOGIN, body);
    if (response.status) {
      localStorage.setItem('accessToken', response.res.data?.data);
      // localStorage.setItem('accessToken', response.res.data.token);
      // dispatch({
      //   type: 'USER_UPDATE',
      //   payload: response?.res?.data?.data || [],  // New role value
      // });
      me()
    } else {
      return { loginData: response.msg, response: false };
    }
    updateLoader(false)
  };

  const register = async (payload) => {
    updateLoader(true)
    try {
      const response = await CallAPI("WEBSITE", "POST", SIGN_UP, payload);
      console.log("Registration Response:", response);
      if (response?.res?.status === 201) {
        if (planPurchase?.id) {
          let loginBody = { email: payload.email, password: payload.password }
          await login(loginBody, "with-plan", !!payload?.promoCode ? payload?.promoCode : "")
          showToast(response.msg,
            LABEL.TOAST_TYPE.SUCCESS
          );

        } else {
          showToast(response.msg,
            LABEL.TOAST_TYPE.SUCCESS
          );
          navigate('/login')
        }

        /* this bellow part will be functional at the time of email verification after 
        Signup and the if else part will be move to src\pages\ResetPassword.jsx page 
        (task name : emai_verification -----> By Sharmishtha)*/

        // localStorage.setItem("reset-verify-mail", payload.email)
        // updateLoader(false)
        // return true
      }
    } catch (error) {
      showToast(LABEL.SIGNUP.ERROR_MESSAGE.FAILED,
        LABEL.TOAST_TYPE.SUCCESS
      );
      console.error("Register User Error:", error);
    }
    updateLoader(false)
  };

  const logout = async () => {
    sessionStorage.clear();
    localStorage.removeItem("accessToken")
    localStorage.removeItem("userObj")
    localStorage.removeItem("botModification")
    localStorage.removeItem("botStyle")
    localStorage.removeItem("botWorkFlowEdge")
    localStorage.removeItem("botWorkFlowNode")
    localStorage.removeItem("chatElement")
    localStorage.removeItem("chatNode")
    dispatch({ type: 'LOGOUT' });
    navigate('/login')
  };

  const updateUser = (user) => {
    dispatch({ type: 'USER_UPDATE', payload: user })
  }

  const setSession = (accessToken, accessRole) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessRole', accessRole);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('accessRole', accessRole);
    }
  };

  const isValidToken = accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > Math.ceil(currentTime);
  };

  useEffect(() => {
    const onStripeOnboard = async () => {
      const createSubscriptionResponse = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.DASHBOARD.USER_SUBSCRIPTION, state.subscriptionPayload);
      const redirectUrl = createSubscriptionResponse.res.data?.data.url;
      setPlanPurchase(null)
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
    const initialise = async () => {
      try {
        if (localStorage.getItem('email-verify') && localStorage.getItem('action-type')) {
          updateEmailVerified(false)
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            }
          });
        }
        else {
          const accessToken = localStorage.getItem('accessToken');
          if (accessToken) {
            me()
          } else {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null,
              }
            });
          }
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
        // logout();
      }
    };
    if (planPurchase?.id && state.isEmailVerified) {
      onStripeOnboard();
    }
    else {
      initialise();
    }

  }, [state.isEmailVerified]);

  const updateLoader = (loader) => {
    dispatch({ type: 'LOADER_UPDATE', payload: loader })
  }

  const updateEmailVerified = (value) => {
    dispatch({
      type: 'EMAIL_VERIFIED',
      payload: value,  // New role value
    });
  }

  const createSubscriptionPayload = (payload) => {
    dispatch({
      type: 'CREATE_SUBSCRIPTION_PAYLOAD',
      payload: payload,  // New role value
    });
  }

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
        register,
        superLogin,
        me,
        updateLoader,
        updateEmailVerified,
        createSubscriptionPayload
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
