import React, { useState } from "react";
import ToggleSwitch from "../../UI/toggle-switch";
import { CallAPI } from "../../../utils/apiActions";
import { showToast } from "../../../helper/toastHelper";
import { LABEL } from "../../../utils/constant";
import { CMS_BASE_URL } from "../../../utils/APIEndPoints";

const UpdateViewModal = ({ onClose = () => {}, item = {},fetchUsers=()=>{} }) => {
  const [status, setStatus] = useState(item.status ? true : false);
  const updateStatus = async () => {
    try {
      const res =await CallAPI(
        "WEBSITE",
        "PUT",
        `${CMS_BASE_URL.UPDATE_USERS_STATUS_BY_ADMIN}/${item.id}`,
        {}
      );
      console.log("res_", res);
      onClose();
      showToast(res?.msg||"Successfully updated!",LABEL.TOAST_TYPE.SUCCESS);
      fetchUsers()
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (val) => {
    setStatus(val);
    updateStatus();
  };
  return (
    <div class="modalOverlay modal-open">
      <div class="modal-container width-small modal-dark-bg rounded-4 p-4">
        <a href="javascript:void(0)" class="modalClose" onClick={onClose}>
          <img src="images/icons/modal-close-icon.svg" alt="" />
        </a>
        <div class="modal-form-grid pt-3">
          <h3>User Details</h3>
          <table>
            <tbody>
              <tr>
                <th>User Name</th>
                <input value={item?.fullName || ""} disabled />
              </tr>
              <tr>
                <th>Email</th>
                <input value={item?.email || ""} disabled />
              </tr>
              <tr>
                <th>Phone Number</th>
                <input value={item?.phoneNumber || ""} disabled />
              </tr>
              <tr>
                <th>Status</th>
                <ToggleSwitch value={status} onChange={handleChange} />
              </tr>
            </tbody>
          </table>
          <div class="button-group">
            <button class="cta-border-w full with-icon" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateViewModal;
