import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Reducer from '../../utils/Reducer';
import validationUtility from '../../utils/Validation';
import { CallAPI } from '../../utils/apiActions';
import { ADMIN_GET_OTP, GET_OTP } from '../../utils/APIEndPoints';
import { LABEL } from '../../utils/constant';
import { showToast } from '../../helper/toastHelper';

const initState = {
    email: "",
    emailErr: "",
    loading: false,
};

const ForgetPass = ({ ...props }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(Reducer, initState);
    const GET_OTP_URL = props.userType == "user" ? GET_OTP : ADMIN_GET_OTP

    const handleFormInput = (e) => {
        if (e.target.value == null || e.target.value == '' || e.target.value == undefined) {
            dispatch({ type: "SETDATA", payload: { "name": e.target.name + 'Err', "value": `${e.target.name} is required` } })
        }
        else {
            dispatch({ type: "SETDATA", payload: { "name": e.target.name + 'Err', "value": '' } })
        }
        dispatch({ type: "SETDATA", payload: { "name": e.target.name, "value": e.target.value } })
    };

    const handleForgetPass = async () => {
        let flag = true
        if (!validationUtility.email(state.email)) {
            flag = false
            dispatch({ type: "SETDATA", payload: { "name": 'emailErr', "value": 'Not a valid Email' } })
        }
        else if (state.email.length > 50) {
            flag = false
            dispatch({ type: "SETDATA", payload: { "name": 'emailErr', "value": 'Email can be maximum of 50 characters' } })
        }
        else if (state.email == null || state.email == "" || state.email == undefined) {
            flag = false
            dispatch({ type: "SETDATA", payload: { "name": 'emailErr', "value": `Email is required` } })
        }
        if (flag) {
            dispatch({ type: "SETDATA", payload: { "name": "loading", "value": true } })
            let response = await CallAPI("USER", "POST", GET_OTP_URL, { email: state.email })
            if (response.status) {
                if (props.userType == "user") {
                    localStorage.setItem("reset-verify-mail", state.email)
                    navigate('/reset-password')
                }
                else {
                    localStorage.setItem("admin-reset-verify-mail", state.email)
                    navigate('/admin/reset-password')
                }
                showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
            }
            else {
                showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
            }
            dispatch({ type: "SETDATA", payload: { "name": "loading", "value": false } })
        }
    }

    return (
        <div className="form-grid_left">
            <div className="form-grid_gredient">

                <div className="form-grid_block">
                    <figure>
                        <img src="/images/coobiz-logo.svg" alt />
                    </figure>

                    <div className="loginForm">
                        <header>
                            <h4>Forget your password?</h4>
                            <p>Please enter your email to
                                continue</p>
                        </header>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="field">
                                    <label for="txtEmail">Email</label>
                                    <input
                                        value={state.email}
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        placeholder="Enter Your Email Or Password"
                                        onChange={(e) => handleFormInput(e)} />
                                    {state.emailErr && <p>{state.emailErr}</p>}
                                </div>
                            </div>
                            <div
                                className="col-12 text-center mb--20">
                                <button className="cta-green" disabled={!!state.loading} onClick={() => handleForgetPass()}>
                                    {!!state.loading && <i class="fa-solid fa-spinner fa-spin"></i>}Send Now</button>
                            </div>

                            <div
                                className="col-12 text-center mb--20 text-12">
                                Remember Password <a
                                    href="javascript:void(0)"
                                    className="text-link" onClick={() => navigate('/login')}>Sign In</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgetPass;