import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dropdown from "../../UI/dropdown";
import ToggleSwitch from "../../UI/toggle-switch";
import {
  couponDurationOptions,
  currencyOptions,
  defaultValuesForCoupon,
  discountTypeOptions,
  LABEL,
} from "../../../utils/constant";
import { couponSchema } from "../../../utils/validationSchema";
import useAuth from "../../../hooks/useAuth";
import { showToast } from "../../../helper/toastHelper";
import useCmsManagement from "../../../hooks/useCmsManagement";
import { useNavigate } from "react-router-dom";

const showExpiry = ['once', 'repeating']

const GeneratePromocode = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { createCoupon } = useCmsManagement();

  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(couponSchema),
    defaultValues: defaultValuesForCoupon,
  });

  const { couponDuration } = watch()

  const afterSuccess = (msg) => {
    navigate("/admin/promocode-management");
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  }

  const onSubmit = (data) => {
    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    const payload = { ...data, userId: user.id };
    createCoupon(payload, afterSuccess);
  };

  console.log("=====>couponDuration", couponDuration)

  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>{LABEL.COUPON_SETTINGS.TITLE}</h3>
            </header>

            <div className="chatSettings-grid">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="coupon-form-grid"
              >
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="row">

                      {/* Coupon Name */}

                      <div class="col-md-6">
                        <div className="field">
                          <label>{LABEL.COUPON_SETTINGS.COUPON_NAME}</label>
                          <input
                            {...register("couponName")}
                            className={`form-control ${errors.couponName ? "is-invalid" : ""
                              }`}
                            placeholder="Enter coupon name"
                          />
                          {errors.couponName && (
                            <span className="invalid-feedback">
                              {errors.couponName.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Coupon Duration */}

                      <div className="col-md-6">
                        <Controller
                          name="couponDuration"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={LABEL.COUPON_SETTINGS.COUPON_DURATION}
                              options={couponDurationOptions}
                              error={errors.couponDuration}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      {/* Expires At */}


                      {showExpiry.includes(couponDuration) ? <div className="col-md-6">
                        <div className="field">
                          <label>{LABEL.COUPON_SETTINGS.EXPIRES_AT}</label>
                          <input
                            type="date"
                            {...register("expiresAt")}
                            className={`form-control ${errors.expiresAt ? "is-invalid" : ""
                              }`}
                          />
                          {errors.expiresAt && (
                            <span className="invalid-feedback">
                              {errors.expiresAt.message}
                            </span>
                          )}
                        </div>
                      </div> : null}

                      {/* Discount Type */}

                      <div className="col-md-6">
                        <Controller
                          name="discountType"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={LABEL.COUPON_SETTINGS.DISCOUNT_TYPE}
                              options={discountTypeOptions}
                              error={errors.discountType}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      {/* Coupon Amount */}

                      <div className="col-md-6">
                        <div className="field">
                          <label>{LABEL.COUPON_SETTINGS.COUPON_AMOUNT}</label>
                          <input
                            type="number"
                            {...register("couponAmount")}
                            className={`form-control ${errors.couponAmount ? "is-invalid" : ""
                              }`}
                            placeholder="Enter coupon amount"
                          />
                          {errors.couponAmount && (
                            <span className="invalid-feedback">
                              {errors.couponAmount.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Currency */}

                      <div className="col-md-6">
                        <Controller
                          name="currency"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={LABEL.COUPON_SETTINGS.CURRENCY}
                              options={currencyOptions}
                              error={errors.currency}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      {/* Max Redemptions */}

                      {/* <div className="col-md-6">
                        <div className="field">
                          <label>{LABEL.COUPON_SETTINGS.MAX_REDEMPTIONS}</label>
                          <input
                            type="number"
                            {...register("max_redemptions")}
                            className={`form-control ${errors.max_redemptions ? "is-invalid" : ""
                              }`}
                            placeholder="Enter max redemptions"
                          />
                          {errors.max_redemptions && (
                            <span className="invalid-feedback">
                              {errors.max_redemptions.message}
                            </span>
                          )}
                        </div>
                      </div> */}

                      {/* Eligible for First-Time Users */}

                      {/* <div className="col-md-12">
                        <div className="d-flex align-items-center gap-3">
                          <Controller
                            name="isEligibleForFirstTime"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <ToggleSwitch
                                label={
                                  LABEL.COUPON_SETTINGS.ELIGIBLE_FOR_FIRST_TIME
                                }
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div> */}

                      <div class="col-md-12">
                        <div class="field">
                          <div class="group-form d-flex align-items-center gap-3">
                            {/* Submit Button */}
                            <button type="submit" className="cta-green mt-3">
                              {LABEL.COUPON_SETTINGS.SAVE_AND_CONTINUE}
                            </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePromocode;
