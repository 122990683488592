/* eslint-disable radix */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const UnAuthGuard = ({ children }) => {
  const { isAuthenticated, user, isEmailVerified } = useAuth();
  const location = useLocation();
  // if (!isAuthenticated && user === null) {
  //   return <Navigate to="/login" />;
  // }
  console.log('isAuthenticated guest guard', isAuthenticated, user)

  if (isAuthenticated && user !== null) {
    return <Navigate to="/dashboard" />;
  }
  else if (location.pathname == "/reset-password" && !localStorage.getItem("reset-verify-mail")) {
    return <Navigate to="/" />;
  }
  else if (location.pathname == "/email-verification" && !localStorage.getItem("email-verify") && !!isEmailVerified) {
    return <Navigate to="/" />;
  }
  else if (location.pathname == "/admin/reset-password" && !localStorage.getItem("admin-reset-verify-mail")) {
    return <Navigate to="/admin/login" />;
  }
  if (location.pathname != "/reset-password") {
    localStorage.removeItem("reset-verify-mail")
  }
  if (location.pathname != "/admin/reset-password") {
    localStorage.removeItem("admin-reset-verify-mail")
  }
  if (location.pathname != "/email-verification") {
    localStorage.removeItem("email-verify")
    localStorage.removeItem("action-type")
  }


  return (
    <>
      {children}
    </>
  );
};

UnAuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnAuthGuard;
