import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

export default function PasswordFields({ id, name,placeholder, control, errors }) {
  console.log(errors)
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility((prev) => !prev);
  };

  return (
    <div className="field">
      <div className="changePass-grid">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type={passwordVisibility ? "text" : "password"}
              className="form-control"
              id={id}
              placeholder={placeholder}
              autoComplete='new-password'
            />
          )}
        />
        {errors[name] && <p>{errors[name].message}</p>}

        <span
          className="toggle-password"
          onClick={togglePasswordVisibility}
        >
          <i
            className={
              passwordVisibility
                ? "fa-regular fa-eye"
                : "fa-regular fa-eye-slash"
            }
          />
        </span>
      </div>
    </div>
  );
}
