import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function DashboardMain() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="dashboard-feature d-flex justify-content-between">
        <div className="cards total-chatbot">
          <img src="images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
          <div className="cards_feature">
            <figure><img src="images/icons/chatbot-icon.svg" alt="" /></figure>
            <aside>
              <h4>Total Chatbot</h4>
              <span>18</span>
            </aside>
          </div>
        </div>
        <div className="cards new-messages">
          <img src="images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
          <div className="cards_feature">
            <figure><img src="images/icons/message-icon.svg" alt="" /></figure>
            <aside>
              <h4>New Messages</h4>
              <span>22</span>
            </aside>
          </div>
        </div>
        <div className="cards total-activ-boat">
          <img src="images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
          <div className="cards_feature">
            <figure><img src="images/icons/active-bots-icon.svg" alt="" /></figure>
            <aside>
              <h4>Total Active Boat</h4>
              <span>12</span>
            </aside>
          </div>
        </div>
        <div className="cards all-message">
          <img src="images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
          <div className="cards_feature">
            <figure><img src="images/icons/all-message-icon.svg" alt="" /></figure>
            <aside>
              <h4>All Message</h4>
              <span>155</span>
            </aside>
          </div>
        </div>
      </div>

      <div className="dashboard-chart-grid">
        <header className="d-md-flex align-items-center justify-content-between">
          <h6>Incoming Message</h6>

          <div className="dropdown_select">
            <select className="form-select" aria-label="Default select example">
              <option selected="">Select Option</option>
              <option value="">Medical Chatbot 1</option>
              <option value="">Medical Chatbot 2</option>
            </select>
          </div>

          <div className="dropdown acc none">
            <div className="acc-head dropdown_green">Medical Chatbot</div>
            <div className="acc-info none">
              <ul>
                <li><a href="javascript:void(0)">Medical Chatbot 1</a></li>
                <li><a href="javascript:void(0)">Medical Chatbot 2</a></li>
                <li><a href="javascript:void(0)">Medical Chatbot 3</a></li>
                <li><a href="javascript:void(0)">Medical Chatbot 4</a></li>
              </ul>
            </div>
          </div>
        </header>

        <div className="dashboard-chart-block">
          <img src="images/incoming-message-chart.svg" alt="" />
        </div>
      </div>

      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>All Chatbots</h3>

          <a href="javascript:void(0)" className="cta-green" onClick={() => navigate('/bot-settings?tab=1')}>
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            Create New Chatbot
          </a>
        </header>
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr>
                <th className="text-left">Chatbot name</th>
                <th className="text-center">Create date</th>
                <th className="text-center">Author</th>
                <th className="text-center">Chatbot Description</th>
                <th className="text-center action">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-left">Eugene Goostman</td>
                <td className="text-center">03 Oct 24</td>
                <td className="text-center">John Doe</td>
                <td className="text-center">chatbot will function as virtual diabetes physician
                  / Doctor</td>
                <td className="text-center action">
                  <a href="javascript:void(0)"><img src="images/icons/edit-icon.svg"
                    alt="" /></a>
                  <a href="javascript:void(0)"><img src="images/icons/delete-icon.svg"
                    alt="" /></a>
                </td>
              </tr>
              <tr>
                <td className="text-left">Eugene Goostman</td>
                <td className="text-center">03 Oct 24</td>
                <td className="text-center">John Doe</td>
                <td className="text-center">chatbot will function as virtual diabetes physician
                  / Doctor</td>
                <td className="text-center action">
                  <a href="javascript:void(0)"><img src="images/icons/edit-icon.svg"
                    alt="" /></a>
                  <a href="javascript:void(0)"><img src="images/icons/delete-icon.svg"
                    alt="" /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </React.Fragment>
  )
}
