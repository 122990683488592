import React, { useEffect, useState } from "react";

const FileUpload = ({ label, accept = "image/*", onChange }) => {
  const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setFileType(file.type);
      onChange(file);
    }
  };
  const handleRemove = () => {
    setPreview(null);
    setFileType(null);
    onChange(null);
  };

  return (
    <React.Fragment>
      <div className="image-preview">
        {preview ? (
          fileType.startsWith("image/") ? (
            <img src={preview} alt="Preview" />
          ) : fileType.startsWith("video/") ? (
            <video height={'70px'} width={'70px'}>
              <source src={preview} type={fileType} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>Unsupported file type</p>
          )
        ) : (
          <img src="/images/icons/upload-img-icon.svg" alt="upload icon" />
        )}
        {preview ? <img src="/images/icons/delete-icon.svg" alt="Preview" onClick={handleRemove} /> : null}
        {/* {preview ? <img src={preview} alt="Preview" /> : <img src="/images/icons/upload-img-icon.svg" alt="upload icon" />} */}
      </div>
      <label className="file-upload-label">
        {label}
        <input
          type="file"
          className="file-upload-input"
          accept={accept}
          onChange={handleFileChange}
        />
      </label>
    </React.Fragment>
  );
};

export default FileUpload;
