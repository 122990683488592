import * as yup from 'yup';
import { validationMessages, validationMessagesForBotChatSettings, validationMessagesForCms, validationMessagesForCoupon, validationMessagesForPlan, validationMessagesForUser } from './constant';
import { matchIsValidTel } from 'mui-tel-input';
import validationUtility from './Validation';
import PhoneNumber from "awesome-phonenumber"

const validUrl = (url) => {
  return validationUtility.validUrl(url);
};

export const registrationSchema = yup.object({
  fullName: yup.string().required(validationMessages.fullName),
  email: yup.string().email(validationMessages.emailInvalid).required(validationMessages.emailRequired),
  // phoneNumber: yup
  //   .string()
  //   .required(validationMessages.phoneRequired)
  //   .test("is_valid_phone", validationMessages.phoneInvalid, (value) => {
  //     if (!value) return false;
  //     return matchIsValidTel(value);
  //   }),
  phoneNumber: yup
    .string()
    .required(validationMessages.phoneRequired)
    .test("is_valid_phone", validationMessages.phoneInvalid, (value) => {
      if (!value) return false;
      return new PhoneNumber(`${value}`).isValid();
    }),
  password: yup
    .string()
    .min(8, validationMessages.passwordErr)
    .required(validationMessages.passwordRequired),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], validationMessages.confirmPasswordMismatch)
    .required(validationMessages.confirmPasswordRequired),

  businessUrl: yup.string()
    .nullable()
    .test('valid-url', validationMessages.validUrl, value => !value || validUrl(value)),

  organizationName: yup.string()
    .nullable(),
  // .min(3, validationMessages.orgNameMin),

  promoCode: yup.string()
    .nullable()
  // .matches(regex.promoCodePattern, validationMessages.promoCodePattern),
});

export const loginSchema = yup.object({
  email: yup.string().email(validationMessages.emailInvalid).required(validationMessages.emailRequired),
  password: yup
    .string()
    .min(8, validationMessages.passwordErr)
    .required(validationMessages.passwordRequired),
});

const validUrlWithoutScheme = /^((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?:\/[^\s]*)?$/;

export const botChatSettingsSchema = yup.object().shape({
  chatbotDescription: yup.string().required(validationMessagesForBotChatSettings.chatbotDescription),
  tone: yup.string().required(validationMessagesForBotChatSettings.toneRequired),
  language: yup.string().required(validationMessagesForBotChatSettings.languageRequired),
  fontHeader: yup.string().required(validationMessagesForBotChatSettings.fontHeaderRequired),
  fontBody: yup.string().required(validationMessagesForBotChatSettings.fontBodyRequired),
  responseLength: yup.string().required(validationMessagesForBotChatSettings.responseLengthRequired),
  // domainLink: yup
  //   .string()
  //   .url(validationMessagesForBotChatSettings.domainLinkInvalid)
  //   .required(validationMessagesForBotChatSettings.domainLinkRequired),
  domainLink: yup
    .string()
    .matches(validUrlWithoutScheme, validationMessagesForBotChatSettings.domainLinkInvalid)
    .required(validationMessagesForBotChatSettings.domainLinkRequired),
});

export const couponSchema = yup.object().shape({
  expiresAt: yup
    .date()
    .nullable()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .when('couponDuration', {
      is: (value) => ['once', 'repeating'].includes(value), // Condition
      then: (schema) => schema.required(validationMessagesForCoupon.expiresAtRequired),
      otherwise: (schema) => schema.notRequired(),
    }),
  couponName: yup
    .string()
    .trim()
    .required(validationMessagesForCoupon.couponNameRequired),
  couponDuration: yup
    .string()
    .oneOf(["forever", "once", "repeating"], validationMessagesForCoupon.couponDurationInvalid)
    .required(validationMessagesForCoupon.couponDurationRequired),
  // discountType: yup
  //   .number()
  //   .transform((value, originalValue) =>
  //     originalValue === "" ? null : value
  //   )
  //   .oneOf([1, 2], validationMessagesForCoupon.discountTypeInvalid)
  //   .required(validationMessagesForCoupon.discountTypeRequired),
  // couponAmount: yup
  //   .number()
  //   .transform((value, originalValue) =>
  //     originalValue === "" ? null : value
  //   )
  //   .positive(validationMessagesForCoupon.couponAmountPositive)
  //   .required(validationMessagesForCoupon.couponAmountRequired),

  discountType: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .oneOf([1, 2], validationMessagesForCoupon.discountTypeInvalid)
    .required(validationMessagesForCoupon.discountTypeRequired),
  couponAmount: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .when('discountType', {
      is: 1, // If discountType is Percent
      then: (schema) => schema
        .min(1, "Min discount type value should be 1") // Min value for Percent
        .max(100, "Max discount type value should be 100"), // Max value for Percent
      otherwise: (schema) => schema
        .positive(validationMessagesForCoupon.couponAmountPositive), // For Fixed discount type
    })
    .required(validationMessagesForCoupon.couponAmountRequired),

  currency: yup.string().required(validationMessagesForCoupon.currencyRequired),
  // isEligibleForFirstTime: yup.boolean().required(validationMessagesForCoupon.isEligibleRequired),
});

export const planSchema = yup.object().shape({
  planName: yup
    .string()
    .trim()
    .required(validationMessagesForPlan.planNameRequired),

  price: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .positive(validationMessagesForPlan.pricePositive)
    .required(validationMessagesForPlan.priceRequired),

  currency: yup.string().required(validationMessagesForPlan.currencyRequired),

  isFreePlan: yup.boolean(),

  // noOfDay: yup
  // .string()
  // .when('isFreePlan', {
  //   is: true,
  //   then: (schema) => schema
  //     .trim()
  //     .required(validationMessagesForPlan.noOfDayRequired),
  //   otherwise: (schema) => schema
  //     .nullable()
  // }),

  noOfDay: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .when('isFreePlan', {
      is: true,
      then: (schema) => schema
        .positive(validationMessagesForPlan.noOfDayMustBeNumber)
        .required(validationMessagesForPlan.noOfDayRequired),
      otherwise: (schema) => schema
        .nullable()
    }),

  module: yup
    .array()
    .min(1, validationMessagesForPlan.planDescriptionMustBeOne)
    .required(validationMessagesForPlan.planDescriptionRequired),
});

export const cmsSchema = yup.object().shape({
  title: yup.string().required(validationMessagesForCms.title),
  // subTitle: yup.string().required(validationMessagesForCms.subTitle),
  description: yup.string().required(validationMessagesForCms.description),
  cmsType: yup.string().required(validationMessagesForCms.cmsType),
});
export const userSchema = yup.object().shape({
  fullName: yup.string().required(validationMessagesForUser.name),
  // subTitle: yup.string().required(validationMessagesForUser.subTitle),
  email: yup.string().email(validationMessagesForUser.emailInValid).required(validationMessagesForUser.email),
  phoneNumber: yup.string().required(validationMessagesForUser.phoneNumber),
});
export const contactUsSchema = yup.object().shape({
  name: yup.string().required(validationMessages.fullName),
  email: yup.string().email(validationMessages.emailInvalid).required(validationMessages.emailRequired),
  phone: yup
    .string()
    .matches(/^\d{10}$/, validationMessages.phoneRequired) // 10-digit validation
    .required(validationMessages.phoneRequired),
  // agree: yup.boolean().required("Please accept term & condition")
});


