import './App.css';
import './assets/css/coolbiz_dashboard.css'
import { AuthProvider } from './contexts/JWTAuthContext';
import { AdminProvider } from './contexts/AdminContext';
import { DasboardProvider } from './contexts/DashboardContext';
import routes, { renderRoutes } from './routes';
import ScrollToTop from './utils/ScrollToTop';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ScrollToHash from './components/ScrollToHash';

const App = () => {
  return (
    <React.Fragment>
      <ScrollToHash />
      <ToastContainer />
      <DasboardProvider>
        <AuthProvider>
          <AdminProvider>
            <ScrollToTop />
            {renderRoutes(routes)}
          </AdminProvider>
        </AuthProvider>
      </DasboardProvider>
    </React.Fragment>
  );
}

export default App;
