import React, { useEffect, useState } from 'react';
import BotAppearance from '../components/my-chatboat/BotAppearance';
import BotKnowledgeSource from '../components/my-chatboat/BotKnowledgeSource';
import BotChatSettings from '../components/my-chatboat/BotChatSettings';
import { useLocation, useSearchParams } from 'react-router-dom';
import BotBuilder from '../components/my-chatboat/BotBuilder';

const BotSettings = () => {
    const location = useLocation();
    const [tab, setTab] = useState(new URLSearchParams(location?.search).get("tab"))
    const [botName, setBotName] = useState(new URLSearchParams(location?.search).get("botname"))
    const [finalStep, setFinalStep] = useState(0);

    const handleSetTab = (selectedTab, botname) => {
        setBotName(botname)
        // if (finalStep + 1 >= parseInt(selectedTab)) {
            setTab(selectedTab)
            const url = new URL(window.location);
            url.searchParams.set('tab', selectedTab);  // Set 'tab' to 2
            window.history.pushState({}, '', url);
        // }
    }

    console.log("finalStep--", finalStep)

    return (
        <div>
            <div className="mychatbot-grid">
                <header className="">
                    <h3>{botName || "Chatbot - 1"}</h3>
                </header>

                <div className="tabs-grid">
                    <ul className="tab-lists">
                        <li className={tab == "1" ? "active" : ""}><a href="javascript:void(0)" onClick={() => handleSetTab("1")}>Appearance</a></li>
                        <li className={tab == "2" ? "active" : finalStep >= 1 ? "" : "inactive"}><a href="javascript:void(0)" onClick={() => handleSetTab("2")}>Knowledge Sources</a></li>
                        <li className={tab == "3" ? "active" : finalStep >= 2 ? "" : "inactive"}><a href="javascript:void(0)" onClick={() => handleSetTab("3")}>Question Area</a></li>
                        <li className={tab == "4" ? "active" : finalStep >= 3 ? "" : "inactive"}><a href="javascript:void(0)" onClick={() => handleSetTab("4")}>Chat Settings</a></li>
                    </ul>
                    {tab == "1" && <BotAppearance handleSetTab={handleSetTab} setFinalStep={setFinalStep}/>}
                    {tab == "2" && <BotKnowledgeSource handleSetTab={handleSetTab} setFinalStep={setFinalStep} finalStep={finalStep}/>}
                    {tab == "3" && <BotBuilder handleSetTab={handleSetTab} setFinalStep={setFinalStep}/>}
                    {tab == "4" && <BotChatSettings handleSetTab={handleSetTab} setFinalStep={setFinalStep} finalStep={finalStep}/>}
                </div>
            </div>
        </div>
    )
}

export default BotSettings;