import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Reducer from "../../utils/Reducer";
import validationUtility from "../../utils/Validation";
import useAuth from "../../hooks/useAuth";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { Controller, useForm } from "react-hook-form";
import PasswordFields from "../UI/PasswordFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValuesLogin } from "../../utils/constant";
import { loginSchema } from "../../utils/validationSchema";

const AdminLogin = () => {
  const navigate = useNavigate();
  const { loader,superLogin } = useAuth();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();


  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: defaultValuesLogin,
  });

  const onSubmit = (data) => {
    superLogin(data);
  };

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const onLogout = useCallback(() => { }, []);

  const handleFBLogin = (response) => {
    console.log(response);
    // setProfile(response.data);
  };

  const handleFBLoginErr = (error) => {
    console.log(error);
  };

  return (
    <div id="window">
      <section className="body-wrap">
        <div className="container">
          <div className="form-grid justify-content-center">
            <div className="form-grid_left">
              <div className="form-grid_gredient">
                <div className="form-grid_block">
                  <figure>
                    <Link to="/">
                      <img src="/images/coobiz-logo.svg" alt />
                    </Link>
                  </figure>

                  <div className="loginForm">
                    <header>
                      <h4>Administrator Sign In</h4>
                    </header>
                    <form id method="get" action role="form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="field">
                            <label for="txtEmail">Email</label>

                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="txtEmail"
                                  placeholder="Enter Your Email"
                                />
                              )}
                            />
                            {errors.email && <p>{errors.email.message}</p>}

                          </div>
                          <div className="field">
                            <label for="txtPassword">Password</label>
                            <PasswordFields
                              id="txtPassword"
                              name="password"
                              control={control}
                              errors={errors}
                              placeholder="Enter Your Password"
                            />
                          </div>
                        </div>

                        <div className="col-12 text-right forgot-pass">
                          <a
                            href="javascript:void(0)"
                            className="changeModal"
                            onClick={() => navigate("/admin/forget-password")}
                          >
                            Forgotten Password
                          </a>
                        </div>
                        <div className="col-12 text-center mb--20">
                          <button
                            className="cta-green"
                            disabled={!!loader}
                            id="loginFormSubmit"
                            typeof="submit"
                          >
                            {!!loader && <i class="fa-solid fa-spinner fa-spin"></i>}
                            Sign IN Now
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <div className="or-devider">
                            <span>or</span>
                          </div>
                        </div>
                        <div className="text-center">
                          <Link to="/login" className="cta-border">
                            User Sign In
                          </Link>
                        </div>
                       
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Content  */}
    </div>
  );
};

export default AdminLogin;
