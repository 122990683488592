import React from 'react'
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export function AgencyGuard({ children }) {
  const { role, isAuthenticated, user } = useAuth();

  if (!isAuthenticated && user === null) {
    return <Navigate to="/" />;
  }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/admin/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/dashboard" />;
  // }

  // if (user && user.userType === 1) {
  //   return <Navigate to="/affiliate/dashboard" />;
  // }

  return (
    <>
      {children}
    </>
  );
}

AgencyGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AgencyGuard;

