import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "../../utils/apiActions";
import useAuth from "../../hooks/useAuth";
// http://localhost:3000/payment-status?session_id=cs_test_a1AXx3lGN06rLbqZhcBQES1wRaGJuJXIoSs2rT4rw5mUwy5qoh82CElHDI&priceId=price_1QOwZ8JaceqUNJwHnIY6ahC5&userId=21
// http://44.214.202.159/?session_id=cs_test_a1AXx3lGN06rLbqZhcBQES1wRaGJuJXIoSs2rT4rw5mUwy5qoh82CElHDI&priceId=price_1QOwZ8JaceqUNJwHnIY6ahC5&userId=21
export default function PaymentStatus() {
  const token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true); // To show the loading indicator
  const navigate = useNavigate();
  const { me } = useAuth();

  const gotToSuccessPage = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const handleSubscription = async () => {
      try {
        // Extract query parameters from the URL
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get("session_id");
        const priceId = params.get("priceId");
        const userId = params.get("userId");

        if (!sessionId || !priceId || !userId) {
          navigate("/error");
          return;
        }

        const payload = {
          userId: Number(userId),
          sessionId,
          priceId,
        };

        const response = await CallAPI(
          "WEBSITE",
          "POST",
          "subscription/handle-subscription",
          payload
        );
        // Handle API response
        if (response?.res?.data?.data) {
          token ? me(gotToSuccessPage) : navigate("/payments-success");
        } else {
          navigate("/payment-failure");
          token ? navigate("/payment-failure") : navigate("/payments-failure");
        }
      } catch (error) {
        console.error("Error handling subscription:", error);
        navigate("/payment-failure");
      } finally {
        setLoading(false);
      }
    };

    // Call the function
    handleSubscription();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
}
