import React from 'react'

export default function TransactionManagement() {
  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Transaction Management</h3>

          <a href="javascript:void(0)" className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            Create New Chatbot
          </a>
        </header>
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr>
                <th className="text-left">Transaction id</th>
                <th className="text-center">Transaction date</th>
                <th className="text-center">Author</th>
                <th className="text-center">Chatbot Description</th>
                <th className="text-center action">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                Array(9).fill(null).map(item =>
                  <tr>
                    <td className="text-left">SER52458B</td>
                    <td className="text-center">03 Oct 24</td>
                    <td className="text-center">John Doe</td>
                    <td className="text-center">chatbot will function as virtual diabetes physician
                      / Doctor</td>
                    <td className="text-center action">
                      <a href="javascript:void(0)"><img src="images/icons/edit-icon.svg"
                        alt="" /></a>
                      <a href="javascript:void(0)"><img src="images/icons/delete-icon.svg"
                        alt="" /></a>
                    </td>
                  </tr>)
              }

            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}
