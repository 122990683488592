import React, { useState, useEffect, useRef } from "react";

const TableSearchFilter = ({ columns, defaultColumn, onFilter }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(defaultColumn); // Default single selected column
  const [showDropdown, setShowDropdown] = useState(false);
  const debounceTimeout = useRef(null);

  // Local debounce implementation
  const debounce = (callback, delay) => {
    return (...args) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const debouncedFilter = debounce((value, column) => {
    onFilter(value, column);
  }, 300); // Adjust debounce delay (300ms)

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    debouncedFilter(value, selectedColumn);
  };

  const selectColumn = (column) => {
    setSelectedColumn(column);
    // Trigger filter immediately on column change
    debouncedFilter(searchValue, column);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {/* Search Field */}
      <input
        type="text"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSearch}
        style={{
          padding: "8px",
          width: "300px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />

      {/* Filter Icon */}
      <div
        style={{
          position: "relative",
          cursor: "pointer",
        }}
        onClick={toggleDropdown}
      >
        <img
          src="https://img.icons8.com/material-outlined/24/filter--v1.png" // Free filter icon
          alt="Filter"
          style={{ width: "24px", height: "24px",backgroundColor:"red" }}
        />
        {showDropdown && (
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "0",
              backgroundColor: "red",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: "1000",
              padding: "10px",
              minWidth: "200px",
            }}
          >
            <strong>Select Column:</strong>
            <div style={{ marginTop: "10px" }}>
              {columns.map((column) => (
                <div key={column} style={{ marginBottom: "5px" }}>
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio" // Ensures single selection
                      name="columnFilter"
                      checked={selectedColumn === column}
                      onChange={() => selectColumn(column)}
                      style={{ marginRight: "10px" }}
                    />
                    {column}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableSearchFilter;
