import openSocket from "socket.io-client";

export const socketConnection = (short_code = '') => {
  const socket = openSocket("http://localhost:5000", {
    // process.env.REACT_APP_SOCKET_URL, {
    // path: "/v3/channel_123?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV&notify_self",
    // path: `/ws/chat/${short_code}/`,
    path: `/socket.io`,
    transports: ["websocket"],
    // query: {
    //   userId: userId,
    // },
  });
  return socket;
};

export const emitEvents = {
  sendMessage: "send_message",
};

export const listenerEvents = {
  receiveMessage: "receive_message",
};

export const withTimeout = (onSuccess, onTimeout, timeout) => {
  let called = false;

  const timer = setTimeout(() => {
    if (called) return;
    called = true;
    onTimeout();
  }, timeout);

  return (...args) => {
    if (called) return;
    called = true;
    clearTimeout(timer);
    onSuccess.apply(this, args);
  }
}


const test = () => {
  // useEffect(() => {
  //   const sct = socketConnection("951644555488");
  //   sct.on("connect", () => { });
  //   console.log("sct---", sct)
  //   // // if (sct.connected) {
  //   // setGlobalSocket(sct);
  //   sct.emit("joinRoom", "Please Create")
  //   // sct.on("roomConfirm", (data) => {
  //   //     console.log(data)
  //   //     sct.emit("sendMesg", { roomId: data.roomId })
  //   // });
  //   // }

  //   // sct.on("receive", (data) => {
  //   //     let newMessage = JSON.parse(data);
  //   //     updateReceivedMsg(JSON.parse(data));
  //   //     updateMsgList({ ...state.messageList, newMessage })
  //   // });

  //   return () => {
  //     sct.disconnect();
  //   };
  // }, []);
}