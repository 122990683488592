import React, { useState } from 'react';
import SignUpBG from '../components/signin-signup/SignUpBG';
import ResetPassword from '../components/signin-signup/ResetPassword';
import SignUpForm from '../components/signin-signup/SignUpForm';

const cleanNumber = (value) => {
  return value.replace(/\s/g, "");
};

const Signup = ({ ...props }) => {
  const [resgisterData, setRegisterData] = useState(null)
  const [view, setView] = useState("signup")

  return (
    <div id="window">
      <section className="body-wrap">
        <div className="container">
          <div className="form-grid">
            {view == "signup"
              ? <SignUpForm setRegisterData={setRegisterData} setView={setView}/>
              : <ResetPassword type={"emailVerification"} userType={props.userType} data={resgisterData} />}
            {props.userType == "user" && <SignUpBG />}
          </div>
        </div>
      </section>
      {/* End Content  */}
    </div>

  )
}

export default Signup;