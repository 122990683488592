import React from 'react';
import OTPInput from 'react-otp-input';
const OtpView = ({...props}) => {
    return (
        <>
            <div className="field">
                <label for="txtCurrentPassword">Enter OTP code sent to </label>
                <h5>{props?.EmailOrPhone}</h5>
            </div>
            <div className="field">
                <label for="txtCurrentPassword">Enter Your OTP</label>
                <div className="changePass-grid enter-otp-block">
                    <OTPInput
                        value={props?.otp}
                        onChange={(value) => props?.handleSetOtp(value)}
                        numInputs={6}
                        className="customotp"
                        renderInput={(props) => (
                            <input
                                {...props}
                                placeholder=""
                                type="number"
                            // onKeyUp={e => {
                            //     if (e.key === 'Enter' && state.otp.length == 6) {
                            //         handleOTPVerification()
                            //     }
                            // }}
                            />
                        )}
                    />
                </div>
                {props?.otpErr && <span>{props?.otpErr}</span>}
            </div>
            <p>Not received the One Time Password? </p> <button onClick={() => props.ReSendOtp()} className="cta-green">Resend OTP</button>
        </>
    )
}
export default OtpView