import React from "react";
import BookingCalendar from "../components/UI/calender/BookingCalender";
import ContactUsInfo from "../components/dashboard/contact/ContactUsInfo";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { showToast } from "../helper/toastHelper";
import {
  defaultValuesForCms,
  defaultValuesForContactUs,
  LABEL,
} from "../utils/constant";
import { cmsSchema, contactUsSchema } from "../utils/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import useDashboard from "../hooks/useDashboard";

const createFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

const ContactUs = () => {
  const navigate = useNavigate();

  const { contactUsRecordCreate } = useDashboard()
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(contactUsSchema),
    defaultValues: defaultValuesForContactUs,
  });

  const afterSuccess = (msg) => {
    setTimeout(() => {
      navigate('/')
    }, 1000)
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  };

  const onSubmit = (data) => {
    if (!data.slot) return showToast("Please Select A Time Slote", LABEL.TOAST_TYPE.ERROR);

    const payloadResponse = createFormData(data);
    contactUsRecordCreate(payloadResponse, afterSuccess, 'file')
  };

  return (
    <section className="body-wrap">
      <div className="contact-grid">
        <div className="container">
          <div className="banner-home_text with-h1-line text-center">
            <h1>Contact Us</h1>
          </div>
        </div>

        <div className="contact-grid_block">
          <div className="container">
            <div className="contact-info">
              <ul>
                <li>
                  <img src="images/icons/phone-icon.svg" alt="Phome" />
                  <a href="tell:(530) 691-1055">(530) 691-1055</a>
                </li>
                <li>
                  <img src="images/icons/fax-icon.svg" alt="Phome" />
                  <a href="fax:(530) 691-1055">(800) 644-9062</a>
                </li>
                <li>
                  <img src="images/icons/email-icon.svg" alt="Phome" />
                  <a href="mailto:info@coolbiz.info">info@coolbiz.info</a>
                </li>
                <li>
                  <img src="images/icons/location-icon.svg" alt="Phome" />
                  1534 Purple Way Redding, CA 96003
                </li>
              </ul>
            </div>

            <div className="contact-block">
              {/* <div className="contact-left">
                <ContactUsInfo
                  register={register}
                  control={control}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
              </div>

              <div className="contact-right">
                <BookingCalendar setValue={setValue} />
              </div> */}

              <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
                <iframe
                  src="https://api.leadconnectorhq.com/widget/booking/TwTsCBXeeA81wNR3uicA"
                  title="Booking Widget"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "hidden"
                  }}
                  allowFullScreen
                ></iframe>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
