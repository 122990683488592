import React from "react";

const ViewPlanModal = ({ onClose = () => {} ,item={}}) => {
  console.log(item)
  return (
    <div class="modalOverlay modal-open">
      <div class="modal-container width-small modal-dark-bg rounded-4 p-4">
        <a href="javascript:void(0)" class="modalClose" onClick={onClose}>
          <img src="images/icons/modal-close-icon.svg" alt="" />
        </a>
       <div class="modal-form-grid pt-3">
        <h3>Plan Details</h3>
        <table>
              <tbody>
                <tr>
                  <th>Plan Name</th>
                  <td>{item?.planName||""}</td>
                </tr>
                <tr>
                  <th>Available With Trial</th>
                  <td>{item?.noOfDay?"YES":"NO"}</td>
                </tr>
                <tr>
                  <th>Free Trial Days</th>
                  <td>{item?.noOfDay||"NA"}</td>
                </tr>
                <tr>
                  <th>Currency</th>
                  <td>{item?.currency.toUpperCase()}</td>
                </tr>
                <tr>
                  <th>Plan Price</th>
                  <td>{item?.price}</td>
                </tr>
                <tr>
                  <th>Allow Promotion Code</th>
                  <td>{item?.allowPromotionCode===0?"NO":"YES"}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{item?.status===1?"ACTIVE":"IN-ACTIVE"}</td>
                </tr>
              </tbody>
            </table>
           <div class="button-group">
            <button class="cta-border-w full with-icon" onClick={onClose}>
             Close
            </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ViewPlanModal;
