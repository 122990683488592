import React from 'react'

export default function ChatboatAnalytics() {
  return (
    <div>    
      <div class="modalOverlay modal-open none">
        <div class="modal-container width-lg modal-dark-bg">
          <a href="javascript:void(0)" class="modalClose"><img src="images/icons/modal-close-icon.svg" alt="" /></a>
          <div class="modal-form-grid pt-3"> 
            <h3>Most Visited URLs’</h3>
            
            <div class="table-responsive">
              <table class="table align-middle">
                  <thead>
                      <tr>
                          <th class="text-left">Chatbot name</th>
                          <th class="text-left">Page URL</th>
                          <th class="text-center">Traffic</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td class="text-left">Chatbot1</td>
                          <td class="text-left page-url"><a href="javascript:void(0)">websitename/homepage</a></td>                          
                          <td class="text-center">66</td>
                      </tr>
                      <tr>
                          <td class="text-left">Chatbot2</td>
                          <td class="text-left page-url"><span>websitename/homepage</span></td>                          
                          <td class="text-center">66</td>
                      </tr>
                      <tr>
                          <td class="text-left">Chatbot1</td>
                          <td class="text-left page-url"><span>websitename/homepage</span></td>                          
                          <td class="text-center">66</td>
                      </tr>
                      <tr>
                          <td class="text-left">Chatbot2</td>
                          <td class="text-left page-url"><span>websitename/homepage</span></td>                          
                          <td class="text-center">66</td>
                      </tr>						
                  </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

      <div className="chatbot-analytics-grid">
        <header className="">
          <h3>Chatbot Analytics</h3>
        </header>

        <div className="chart-container">
          <div className="chart-block dark-bg">
            <header className="with-selectbox">
              <h6>Chatbot Analytics</h6>
              <span>Chat Summary</span>

              <select className="form-select">
                <option selected="">Select Years</option>
                <option value="">2024 - 2025</option>
                <option value="">2023 - 2024</option>
                <option value="">2022 - 2023</option>
              </select>
            </header>
            <div className="chart-grid d-flex gap-3">
              <div className="chart-grid_card analytics-active">
                <figure><img src="images/icons/analytics-active-icon.svg" alt="" /></figure>
                <h6>10</h6>
                <p className="text-10">Total Active Chatbot</p>
                <span className="text-8">+5 from last year</span>
              </div>
              <div className="chart-grid_card analytics-incoming">
                <figure><img src="images/icons/analytics-incoming-icon.svg" alt="" /></figure>
                <h6>5000</h6>
                <p className="text-10">Total Incoming Message</p>
                <span className="text-8">+8% from last year</span>
              </div>
              <div className="chart-grid_card analytics-reply">
                <figure><img src="images/icons/analytics-reply-icon.svg" alt="" /></figure>
                <h6>9000</h6>
                <p className="text-10">Total Reply by bot</p>
                <span className="text-8">+29% from last year</span>
              </div>
              <div className="chart-grid_card analytics-user">
                <figure><img src="images/icons/analytics-user-icon.svg" alt="" /></figure>
                <h6>1282</h6>
                <p className="text-10">New User</p>
                <span className="text-8">+33% from last year</span>
              </div>
            </div>
          </div>
          <div className="chart-block dark-bg block-350">
            <header>
              <h6>Message & Reply</h6>
            </header>
            <div className="chart-grid">
              <img src="images/message-chart-bg.png" alt="" />
            </div>
          </div>
        </div>

        <div className="chart-container">
          <div className="chart-block dark-bg flex-grow12">
            <header>
              <h6>Total Message</h6>
            </header>
            <div className="chart-grid">
              <img src="images/total-message-chart-bg.png" alt="" />
            </div>
          </div>
        </div>

        <div className="chart-container">
          <div className="chart-block dark-bg block-250">
            <header>
              <h6>User Engagement</h6>
              <span>This Year</span>
            </header>
            <div className="chart-grid">
              <img src="images/user-engagement-chart-bg.png" alt="" />
            </div>
          </div>
          <div className="chart-block dark-bg">
            <header>
              <h6>Top Products</h6>
            </header>
            <div className="chart-grid">
              <img src="images/top-productst-chart-bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
