import React, { useEffect } from 'react';
import useDashboard from '../hooks/useDashboard';
import { Link, useNavigate } from 'react-router-dom';

const Pricing = () => {
  const { planList, getAllPlan, setPlanPurchase } = useDashboard();
  const navigate = useNavigate();

  useEffect(() => {
    getAllPlan()
  }, []);


  const handleGetPlan = (plan) => {
    setPlanPurchase(plan)
  }

  // console.log("===========>", planList)
  return (
    <section className="body-wrap">

      <div className="pricing-grid">
        <div className="container sum-med">
          <div className="banner-home_text with-h1-line text-center">
            <h1>14-day <span>free trial</span></h1>
            <h2>for any plan.</h2>
            <a href="javascript:void(0)" className="cta-green"><i
              className="fa-solid fa-sparkles"></i> Free
              Trial: Try Any Plan for 14 Days!</a>
          </div>

          <div className="pricing-grid_list">
            <header>
              <h3>Power Up Your Business with Our Plans</h3>
            </header>

            <div className="row">
              {
                planList?.map((plan) => (
                  <div key={plan.id} className="col-md-4 mb--30">
                    <div className="pricing-plan businessPlan">
                      <div className="top-header">
                        <h5>{plan.planName || ""}</h5>
                        {/* <p>Ideal for businesses seeking to
                        enhance customer interactions
                        with a versatile chatbot widget
                        for support, sales, and
                        information.</p> */}
                      </div>
                      <div
                        className="plan-price">$<span>{plan.price}</span>/mo</div>
                      <div className="plan-lists">
                        <ul>
                          {
                            plan?.module?.map((module) => (<li key={module.id}>{module.moduleDescription.description || ""}</li>))
                          }
                        </ul>
                      </div>
                      <div className="get-it-button">
                        <Link to="/signup"
                          onClick={() => handleGetPlan(plan)}
                          className="cta-dark">GET IT NOW</Link>
                      </div>
                    </div>
                  </div>))
              }

              {/* <div className="col-md-4 mb--30">
                <div className="pricing-plan businessPlan">
                  <div className="top-header">
                    <h5>Monthly Business Plan</h5>
                    <p>Ideal for businesses seeking to
                      enhance customer interactions
                      with a versatile chatbot widget
                      for support, sales, and
                      information.</p>
                  </div>
                  <div
                    className="plan-price">$<span>97</span>/mo</div>
                  <div className="plan-lists">
                    <ul>
                      <li>Dashboards</li>
                      <li>Unlimited AI ChatBots</li>
                      <li>Unlimited Integrations</li>
                      <li>Unlimited ChatBot
                        Sessions</li>
                      <li>Sites for Engagement
                        Analytics</li>
                      <li>Unlimited Session Duration
                        Trackers</li>
                      <li>No Barriers to Chatbot
                        Interactions</li>
                      <li>Customizable AI Models for
                        Sales, Support, and General
                        Business</li>
                      <li>Advanced AI Models for
                        Specialized Industries
                        (Healthcare, Finance,
                        Insurance, Real Estate)</li>
                      <li>CCPA/GDPR Compliance
                        Automation</li>
                      <li>File and Image Upload for
                        Automated Analysis: Users
                        can upload documents and
                        images for real-time
                        analysis,
                        ideal for industries like
                        healthcare, finance, and
                        legal services</li>
                      <li>Up to 20 calendar link
                        options for scheduling via
                        CRM, Google Calendar, or
                        Office 365</li>
                      <li>Persistent Memory for Chat
                        History</li>
                      <li>PDF Downloads for Chat
                        Histories</li>
                      <li>User Opt-In Status
                        Tracking</li>
                      <li>Multilingual Language
                        Support: Chatbot supports
                        multiple languages, allowing
                        users to use their preferred
                        language</li>
                      <li>White Label Branding
                        (Subscribers can use their
                        brand)</li>
                    </ul>
                  </div>
                  <div className="get-it-button">
                    <a href="javascript:void(0)"
                      className="cta-dark">GET IT NOW</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb--30">
                <div className="pricing-plan monthlyAgencyPlan">
                  <div className="top-header">
                    <h5>Monthly Agency Plan</h5>
                    <p>Ideal for agencies to offer
                      white-labeled or client-branded
                      chatbot services with flexible
                      pricing control.</p>
                  </div>
                  <div
                    className="plan-price">$<span>297</span>/mo</div>
                  <div className="plan-lists">
                    <ul>
                      <li>Unlimited Sub-Accounts</li>
                      <li>Dashboards</li>
                      <li>Unlimited AI ChatBots</li>
                      <li>Unlimited Integrations</li>
                      <li>Unlimited ChatBot
                        Sessions</li>
                      <li>Sites for Engagement
                        Analytics</li>
                      <li>Unlimited Session Duration
                        Trackers</li>
                      <li>No Barriers to Chatbot
                        Interactions</li>
                      <li>Customizable AI Models for
                        Sales, Support, and General
                        Business</li>
                      <li>Advanced AI Models for
                        Specialized Industries
                        (Healthcare, Finance,
                        Insurance, Real Estate)</li>
                      <li>CCPA/GDPR Compliance
                        Automation</li>
                      <li>File and Image Upload for
                        Automated Analysis: Users
                        can upload documents and
                        images for real-time
                        analysis,
                        ideal for industries like
                        healthcare, finance, and
                        legal services</li>
                      <li>Up to 20 calendar link
                        options for scheduling via
                        CRM, Google Calendar, or
                        Office 365</li>
                      <li>Persistent Memory for Chat
                        History</li>
                      <li>PDF Downloads for Chat
                        Histories</li>
                      <li>User Opt-In Status
                        Tracking</li>
                      <li>Multilingual Language
                        Support: Chatbot supports
                        multiple languages, allowing
                        users to use their preferred
                        language.</li>
                      <li>Complete Branding
                        Control:</li>
                      <li>Agencies can choose their
                        own branding or allow
                        sub-accounts to apply custom
                        branding, giving full
                        flexibility and
                        control.</li>
                      <li>Advanced Client and User
                        Interaction Analytics</li>
                    </ul>
                  </div>
                  <div className="get-it-button">
                    <a href="javascript:void(0)"
                      className="cta-dark">GET IT NOW</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb--30">
                <div className="pricing-plan annualAgencyPlan">
                  <div className="top-header">
                    <h5>Annual Agency Plan</h5>
                    <p>Ideal for agencies to offer
                      white-labeled or client-branded
                      chatbot services with flexible
                      pricing control.</p>
                  </div>
                  <div
                    className="plan-price">$<span>2997</span>/yr</div>
                  <div className="plan-lists">
                    <ul>
                      <li>Unlimited Sub-Accounts</li>
                      <li>Dashboards</li>
                      <li>Unlimited AI ChatBots</li>
                      <li>Unlimited Integrations</li>
                      <li>Unlimited ChatBot
                        Sessions</li>
                      <li>Sites for Engagement
                        Analytics</li>
                      <li>Unlimited Session Duration
                        Trackers</li>
                      <li>No Barriers to Chatbot
                        Interactions</li>
                      <li>Customizable AI Models for
                        Sales, Support, and General
                        Business</li>
                      <li>Advanced AI Models for
                        Specialized Industries
                        (Healthcare, Finance,
                        Insurance, Real Estate)</li>
                      <li>CCPA/GDPR Compliance
                        Automation</li>
                      <li>File and Image Upload for
                        Automated Analysis: Users
                        can upload documents and
                        images for real-time
                        analysis,
                        ideal for industries like
                        healthcare, finance, and
                        legal services</li>
                      <li>Up to 20 calendar link
                        options for scheduling via
                        CRM, Google Calendar, or
                        Office 365</li>
                      <li>Persistent Memory for Chat
                        History</li>
                      <li>PDF Downloads for Chat
                        Histories</li>
                      <li>User Opt-In Status
                        Tracking</li>
                      <li>Multilingual Language
                        Support: Chatbot supports
                        multiple languages, allowing
                        users to use their preferred
                        language.</li>
                      <li>Complete Branding Control:
                        Agencies can choose their
                        own branding or allow
                        sub-accounts to
                        apply custom branding,
                        giving full flexibility and
                        control.</li>
                      <li>Advanced Client and User
                        Interaction Analytics</li>
                    </ul>
                  </div>
                  <div className="get-it-button">
                    <a href="javascript:void(0)"
                      className="cta-dark">GET IT NOW</a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default Pricing;