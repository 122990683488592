import React from "react"
import '../../../index.css'
const ConfirmationModal=({handleDelete=()=>{},cancelDelete=()=>{}})=>{
    return(
        <div className="custom-modal-overlay">
        <div className="custom-modal">
          <h2>Delete Confirmation</h2>
          {/* <p>Are you sure you want to delete <strong>{selectedItem?.couponName}</strong>?</p> */}
          <p>Are you sure you want to delete?</p>

          <div className="modal-actions">
            <button className="btn-danger" onClick={handleDelete}>
              Delete
            </button>
            <button className="btn-secondary" onClick={cancelDelete}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
}
export default ConfirmationModal