import React from "react"
const AccessManagement=()=>{
    return(
        <div>
            <div className="mychatbot-grid">
                <header className="">
                    <h3>Access Management</h3>
                </header>

                <div className="tabs-grid">
                    <ul className="tab-lists">
                        <li className="active"><a href="javascript:void(0)">Agency</a></li>
                        <li className=""><a href="javascript:void(0)">User</a></li>
                        <li className=""><a href="javascript:void(0)">Affiliate</a></li>
                        <li className=""><a href="javascript:void(0)">Sub Account</a></li>
                    </ul>

                    <div>
                        <div className="tab-content-grid">
                            <div className="tab-content active">
                                <div className="table-responsive access-management">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Menu</th>
                                                <th className="text-center">View</th>
                                                <th className="text-center">Add</th>
                                                <th className="text-center">Edit</th>
                                                <th className="text-center">Delete</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th colSpan={5}>
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="SelectAll" className="checkbox" />
                                                        <label for="SelectAll" className="form-checkbox">Select All</label>												
                                                    </div>
                                                </th>
                                            </tr>
                                        </tbody>

                                        <tbody>                                            
                                            <tr>
                                                <td className="text-left w-350">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="DashboardCheckbox" className="checkbox" checked />
                                                        <img src="/images/icons/menu-dashboard.svg" alt=""></img>
                                                        <label for="DashboardCheckbox" className="form-checkbox">Dashboard</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">&nbsp;</td>
                                                <td className="text-center">&nbsp;</td>
                                                <td className="text-center">&nbsp;</td>
                                            </tr>  
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="myChatbotsCheckbox" className="checkbox" />
                                                        <img src="/images/icons/menu-my-chatbots.svg" alt=""></img>
                                                        <label for="myChatbotsCheckbox" className="form-checkbox">My chatbots</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked disabled />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked disabled />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked disabled />												
                                                    </div>
                                                </td>
                                                <td className="text-center">&nbsp;</td>
                                            </tr> 
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="chatbotAnalyticsCheckbox" className="checkbox" checked />
                                                        <img src="/images/icons/pai-chart-icon.svg" alt=""></img>
                                                        <label for="chatbotAnalyticsCheckbox" className="form-checkbox">Chatbot Analytics </label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="accessManagementCheckbox" className="checkbox" />
                                                        <img src="/images/icons/menu-my-subscription.svg" alt=""></img>
                                                        <label for="accessManagementCheckbox" className="form-checkbox">Access Management</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="subscriptionManagementCheckbox" className="checkbox" />
                                                        <img src="/images/icons/subscription-management-icon.svg" alt=""></img>
                                                        <label for="subscriptionManagementCheckbox" className="form-checkbox">Subscription Management</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="promoCodeCheckbox" className="checkbox" />
                                                        <img src="/images/icons/promocode-icon.svg" alt=""></img>
                                                        <label for="promoCodeCheckbox" className="form-checkbox">Promo Code Management</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="userManagementCheckbox" className="checkbox" />
                                                        <img src="/images/icons/user-management-icon.svg" alt=""></img>
                                                        <label for="userManagementCheckbox" className="form-checkbox">User management</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check mb-3">
                                                        <input type="checkbox" name="checkbox" id="agencyManagementCheckbox" className="checkbox" checked />
                                                        <a href="javascript:void(0)" className="checkbox-with-link">
                                                            <img src="/images/icons/agency-management-icon.svg" alt=""></img>
                                                            <img src="/images/icons/agency-management-hover-icon.svg" alt=""></img>
                                                            <label for="agencyManagementCheckbox" className="form-checkbox">Agency Management</label>	
                                                        </a>											
                                                    </div> 
                                                    <div className="form-check mb-3 ms-5">
                                                        <input type="checkbox" name="checkbox" id="parentAgencyCheckbox" className="checkbox" />
                                                        <label for="parentAgencyCheckbox" className="with-circle">Parent Agency</label>											
                                                    </div> 
                                                    <div className="form-check ms-5">
                                                        <input type="checkbox" name="checkbox" id="agencyUserActivityCheckbox" className="checkbox" />
                                                        <label for="agencyUserActivityCheckbox" className="with-circle">Agency user Activity</label>											
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check mb-3">
                                                        <input type="checkbox" name="checkbox" id="affiliateManagementCheckbox" className="checkbox" checked />
                                                        <a href="javascript:void(0)" className="checkbox-with-link">
                                                            <img src="/images/icons/affiliate-management-icon.svg" alt=""></img>
                                                            <img src="/images/icons/affiliate-management-hover-icon.svg" alt=""></img>
                                                            <label for="affiliateManagementCheckbox" className="form-checkbox">Affiliate Management</label>	
                                                        </a>											
                                                    </div> 
                                                    <div className="form-check mb-3 ms-5">
                                                        <input type="checkbox" name="checkbox" id="AffiliateUserListCheckbox" className="checkbox" />
                                                        <label for="AffiliateUserListCheckbox" className="with-circle">Affiliate User List</label>											
                                                    </div> 
                                                    <div className="form-check mb-3 ms-5">
                                                        <input type="checkbox" name="checkbox" id="AffiliateEarningCheckbox" className="checkbox" />
                                                        <label for="AffiliateEarningCheckbox" className="with-circle">Affiliate Earning</label>											
                                                    </div> 
                                                    <div className="form-check ms-5">
                                                        <input type="checkbox" name="checkbox" id="AffiliatePayoutHistoryCheckbox" className="checkbox" />
                                                        <label for="AffiliatePayoutHistoryCheckbox" className="with-circle">Affiliate Payout History</label>											
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center mb-3">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="ChatHistoryCheckbox" className="checkbox" checked />
                                                        <img src="/images/icons/menu-chat-history.svg" alt=""></img>
                                                        <label for="ChatHistoryCheckbox" className="form-checkbox">Chat History</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" checked />												
                                                    </div>
                                                </td>
                                                <td className="text-center">&nbsp;</td>
                                            </tr> 
                                            <tr>
                                                <td className="text-left">
                                                    <div className="form-check">
                                                        <input type="checkbox" name="checkbox" id="SettingsCheckbox" className="checkbox" />
                                                        <img src="/images/icons/menu-setting.svg" alt=""></img>
                                                        <label for="SettingsCheckbox" className="form-checkbox">Settings</label>										
                                                    </div>                                                
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div class="form-check center">
                                                        <input type="checkbox" name="checkbox" className="checkbox" />												
                                                    </div>
                                                </td>
                                            </tr>                                     
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccessManagement