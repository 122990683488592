import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Reducer from "../../utils/Reducer";
import validationUtility from "../../utils/Validation";
import useAuth from "../../hooks/useAuth";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValuesLogin } from "../../utils/constant";
import { loginSchema } from "../../utils/validationSchema";
import PasswordFields from "../UI/PasswordFields";

const LogInForm = ({...props}) => {
    const navigate = useNavigate();
    const { loader, login } = useAuth();
    const [provider, setProvider] = useState("");
    const [profile, setProfile] = useState();


    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: defaultValuesLogin,
    });

    const onSubmit = (data) => {
        login(data);
    };

    const onLoginStart = useCallback(() => {
        alert("login start");
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider("");
        alert("logout success");
    }, []);

    const onLogout = useCallback(() => { }, []);

    const handleFBLogin = (response) => {
        console.log(response);
        // setProfile(response.data);
    };

    const handleFBLoginErr = (error) => {
        console.log(error);
    };

    return (
        <div className="form-grid_left">
            <div className="form-grid_gredient">
                <div className="form-grid_block">
                    <figure>
                        <Link to="/">
                            <img src="images/coobiz-logo.svg" alt />
                        </Link>
                    </figure>

                    <div className="loginForm">
                        <header>
                            <h4>Sign In to your account</h4>
                            <p>Please sign in your account to continue</p>
                        </header>
                        <form id method="get" action role="form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="field">
                                        <label for="txtEmail">Email</label>

                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtEmail"
                                                    placeholder="Enter Your Email"
                                                />
                                            )}
                                        />
                                        {errors.email && <p>{errors.email.message}</p>}

                                    </div>
                                    <div className="field">
                                        <label for="txtPassword">Password</label>
                                        <PasswordFields
                                            id="txtPassword"
                                            name="password"
                                            control={control}
                                            errors={errors}
                                            placeholder="Enter Your Password"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 text-right forgot-pass">
                                    <a
                                        href="javascript:void(0)"
                                        className="changeModal"
                                        onClick={() => navigate("/forget-password")}
                                    >
                                        Forgot Password
                                    </a>
                                </div>
                                <div className="col-12 text-center mb--20">
                                    <button
                                        className="cta-green"
                                        id="loginFormSubmit"
                                        disabled={!!loader}
                                        typeof="submit"
                                    >
                                        {!!loader && <i class="fa-solid fa-spinner fa-spin"></i>}
                                        Sign In Now
                                    </button>
                                </div>
                                {/* <div className="col-12 text-center">
                          <div className="or-devider">
                            <span>or</span>
                          </div>
                        </div>
                        <div className="login-with-social">
                          <LoginSocialGoogle
                            client_id={
                              process.env.REACT_APP_GG_APP_ID || "256847589958"
                            }
                            onLoginStart={onLoginStart}
                            // redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                              setProvider(provider);
                              setProfile(data);
                            }}
                            onReject={(err) => {
                              console.log(err);
                            }}
                          >
                            <GoogleLoginButton
                              text="Google"
                              style={{
                                background: "#020823",
                                border: "1px solid white",
                                borderRadius: "24px",
                                fontSize: "14px",
                                color: "white",
                              }}
                            />
                          </LoginSocialGoogle>

                          <LoginSocialFacebook
                            appId="458756252885064"
                            onResolve={handleFBLogin}
                            onReject={handleFBLoginErr}
                            // appId={process.env.REACT_APP_FB_APP_ID || ''}
                            // fieldsProfile={
                            //   'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            // }
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                          // redirect_uri={REDIRECT_URI}
                          >
                            <FacebookLoginButton
                              text="Facebook"
                              // iconSize="20"
                              style={{
                                background: "#020823",
                                border: "1px solid white",
                                borderRadius: "24px",
                                fontSize: "14px",
                              }}
                            />
                          </LoginSocialFacebook>
                        </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LogInForm