import React, { useEffect, useState } from 'react';
import Header from './header';
import Footer from './footer';

const UnAuthLayout = ({ children, ...props }) => {

    return (
        <>
            {props.isHeaderFooter && <Header/>}
            {children}
            {props.isHeaderFooter && <Footer/>}
        </>
    )
}

export default UnAuthLayout