import React from 'react';

function Error() {
    
    return (
        <>404 ERROR</>
    )
}

export default Error
