import React, { useState } from 'react'
import validationUtility from '../utils/Validation'
import { LABEL, validationMessages } from '../utils/constant'
import { CallAPI } from '../utils/apiActions'
import useAuth from '../hooks/useAuth'
import { ADMIN_CHANGE_PASSWORD, CHANGE_PASSWORD } from '../utils/APIEndPoints'
import { showToast } from '../helper/toastHelper'

const ChangePasswordSection = () => {
    const { user } = useAuth();
    const [passDataValue, setPassDataValue] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
    const [passDataErr, setPassDataErr] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
    const [passDataShow, setPassDataShow] = useState({ currentPassword: false, newPassword: false, confirmPassword: false })
    const [loader, setLoader] = useState(false)

    const handleSetValue = (e) => {
        setPassDataValue({ ...passDataValue, [e.target.name]: e.target.value })
        setPassDataErr({ ...passDataErr, [e.target.name]: '' })
    }

    const handleShowPassword = (type = "") => {
        setPassDataShow({ ...passDataShow, [type]: !passDataShow[type] })
    }

    const handleChangePassword = async () => {
        setLoader(true)
        let laPassErr = {}
        let flag = true
        let requiredField = [{ key: "currentPassword", value: "old Password" }, { key: "newPassword", value: "New Password" }, { key: "confirmPassword", value: "Confirm Password" }]
        requiredField.map((field, ind) => {
            if (field.key == "newPassword" && !validationUtility.passwordCheck(passDataValue.newPassword)) {
                flag = false
                laPassErr[field.key] = validationMessages.passwordErr
            }
            if (field.key == "confirmPassword" && !validationUtility.passwordConfirmCheck(passDataValue.newPassword, passDataValue.confirmPassword)) {
                flag = false
                laPassErr[field.key] = validationMessages.confirmPasswordMismatch
            }
            if (passDataValue[field.key].trim() == "" || passDataValue[field.key] == null || passDataValue[field.key] == undefined) {
                flag = false
                laPassErr[field.key] = `Please enter ${field.value}`
            }
        })
        if (flag) {
            let url = user.userType == 1 ? ADMIN_CHANGE_PASSWORD : CHANGE_PASSWORD
            const response = await CallAPI("USER", "PUT", url, passDataValue)
            if (response.status) {
                showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
                setPassDataValue({ ...passDataValue, currentPassword: '', newPassword: '', confirmPassword: '' })
            }
            else {
                showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
            }
            console.log("response---", response)
        }
        else {
            setPassDataErr({ ...laPassErr })
        }
        setLoader(false)
    }

    return (
        <div className="setting-grid mt--30">
            <div className="setting-block">
                <div className="change-pass">
                    <div className="change-pass_info">
                        <h4>Change Password</h4>
                        <p className="text-12">To change your password, please fill in the field below, Your password must
                            contain at least 8 characters, it must also include at least one upper case letter, one lower case
                            letter and one special character.</p>
                    </div>

                    <div className="change-pass_form">
                        <div className="field">
                            <label for="txtCurrentPassword">Current Password</label>
                            <div className="changePass-grid">
                                <input
                                    type={passDataShow.currentPassword ? "text" : "password"}
                                    className="form-control"
                                    id="txtCurrentPassword"
                                    name="currentPassword"
                                    value={passDataValue.currentPassword}
                                    placeholder="Enter Your Password"
                                    onChange={(e) => handleSetValue(e)} />
                                <span className="toggle-password" data-target="txtCurrentPassword" onClick={() => handleShowPassword('currentPassword')}>
                                    <i className={passDataShow.currentPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"} aria-hidden="true"></i>
                                </span>
                            </div>
                            {!!passDataErr.currentPassword && <span>{passDataErr.currentPassword}</span>}
                        </div>
                        <div className="field">
                            <label for="txtNewPassword">New Password</label>
                            <div className="changePass-grid">
                                <input
                                    type={passDataShow.newPassword ? "text" : "password"}
                                    className="form-control"
                                    id="txtNewPassword"
                                    name="newPassword"
                                    value={passDataValue.newPassword}
                                    placeholder="Enter Your Password"
                                    onChange={(e) => handleSetValue(e)} />
                                <span className="toggle-password" data-target="txtNewPassword" onClick={() => handleShowPassword('newPassword')}>
                                    <i className={passDataShow.newPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"} aria-hidden="true"></i>
                                </span>
                            </div>
                            {!!passDataErr.newPassword && <span>{passDataErr.newPassword}</span>}
                        </div>
                        <div className="field">
                            <label for="txtConfirmNewPassword">Confirm New Password</label>
                            <div className="changePass-grid">
                                <input
                                    type={passDataShow.confirmPassword ? "text" : "password"}
                                    className="form-control"
                                    id="txtConfirmNewPassword"
                                    name="confirmPassword"
                                    value={passDataValue.confirmPassword}
                                    placeholder="Enter Your Password"
                                    onChange={(e) => handleSetValue(e)} />
                                <span className="toggle-password" data-target="txtConfirmNewPassword" onClick={() => handleShowPassword('confirmPassword')}>
                                    <i className={passDataShow.confirmPassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"} aria-hidden="true"></i>
                                </span>
                            </div>
                            {!!passDataErr.confirmPassword && <span>{passDataErr.confirmPassword}</span>}
                        </div>
                        <div className="field text-center">
                            <button disabled={!!loader} className="cta-green" onClick={() => handleChangePassword()}>{loader && <i class="fa-solid fa-spinner fa-spin"></i>}Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChangePasswordSection