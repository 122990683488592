import React, { useState } from 'react';
const AffiliateEarning = ({ ...props }) => {
    return(
        <div>    
            <div className="all-chatboat">
                <header className="d-md-flex align-items-center justify-content-between">
                    <h3>My Earning</h3>
                    
                    <div class="group-form d-md-flex">
                        <span className="totalEarning">Total Earning: <strong>$240</strong></span>

                        <select className="form-select w-140">
                            <option value="" disabled="">Month</option>
                            <option value="0">January</option>
                            <option value="1">February</option>
                            <option value="2">March</option>
                            <option value="3">April</option>
                            <option value="3">May</option>
                            <option value="3">June</option>
                            <option value="3">July</option>
                            <option value="3">August</option>
                            <option value="3">September</option>
                            <option value="3">October</option>
                            <option value="3">November</option>
                            <option value="3">December</option>
                        </select>

                        <a href="javascript:void(0)" className="cta-dark with-icon">
                            <i className="fa-solid fa-download"></i>
                            DOWNLOAD
                        </a>
                    </div>
                    
                </header>
                <div className="table-responsive">
                    <table className="table align-middle">
                    <thead>
                        <tr>
                        <th className="text-left">User name</th>
                        <th className="text-center">Email ID</th>
                        <th className="text-center">Invite date</th>
                        <th className="text-center">Converted date</th>
                        <th className="text-center">Invitation Link</th>
                        <th className="text-center">Status</th>
                        <th className="text-center action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>  
        </div>
    )
}
export default AffiliateEarning