import React, { useState } from 'react';
const AffiliateDashboard = ({ ...props }) => {
    return(
        
    <div>
        <div className="dashboard-feature d-flex justify-content-between">
          <div className="cards total-chatbot">
            <img src="/images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
            <div className="cards_feature">
              <figure><img src="/images/icons/chatbot-icon.svg" alt="" /></figure>
              <aside>
                <h4>Total Invited Link</h4>
                <span>55</span>
              </aside>
            </div>
          </div>
          <div className="cards new-messages">
            <img src="/images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
            <div className="cards_feature">
              <figure><img src="/images/icons/message-icon.svg" alt="" /></figure>
              <aside>
                <h4>Converted User</h4>
                <span>22</span>
              </aside>
            </div>
          </div>
          <div className="cards total-activ-boat">
            <img src="/images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
            <div className="cards_feature">
              <figure><img src="/images/icons/active-bots-icon.svg" alt="" /></figure>
              <aside>
                <h4>Earning This Month</h4>
                <span>12</span>
              </aside>
            </div>
          </div>
          <div className="cards all-message">
            <img src="/images/feature-curve-bg.svg" className="feature-curve-bg" alt="" />
            <div className="cards_feature">
              <figure><img src="/images/icons/all-message-icon.svg" alt="" /></figure>
              <aside>
                <h4>Total Earning</h4>
                <span>22000</span>
              </aside>
            </div>
          </div>
        </div>
  
        <div className="dashboard-chart-grid">
          <header className="d-md-flex align-items-center justify-content-between">
            <h6>Incoming Message</h6>
  
            <div className="dropdown_select">
              <select className="form-select w-160" aria-label="Default select example">
                <option selected="">This Month</option>
                <option value="">Medical Chatbot 1</option>
                <option value="">Medical Chatbot 2</option>
              </select>
            </div>
  
            <div className="dropdown acc none">
              <div className="acc-head dropdown_green">Medical Chatbot</div>
              <div className="acc-info none">
                <ul>
                  <li><a href="javascript:void(0)">Medical Chatbot 1</a></li>
                  <li><a href="javascript:void(0)">Medical Chatbot 2</a></li>
                  <li><a href="javascript:void(0)">Medical Chatbot 3</a></li>
                  <li><a href="javascript:void(0)">Medical Chatbot 4</a></li>
                </ul>
              </div>
            </div>
          </header>
  
          <div className="dashboard-chart-block">
            <img src="/images/incoming-message-chart.svg" alt="" />
          </div>
        </div>
  
        <div className="all-chatboat">
          <header className="d-md-flex align-items-center justify-content-between">
            <h3>Referral Activity</h3>
  
            <a href="javascript:void(0)" className="cta-green">
              <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
              Send New Invitation
            </a>
          </header>
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th className="text-left">User name</th>
                  <th className="text-center">Email ID</th>
                  <th className="text-center">Invite date</th>
                  <th className="text-center">Converted date</th>
                  <th className="text-center">Invitation Link</th>
                  <th className="text-center">Status</th>
                  <th className="text-center action">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">Elizabath Garsia</td>
                  <td className="text-center">elizabath.garsia@email.com</td>
                  <td className="text-center">03 Oct 24</td>
                  <td className="text-center">04 Oct 24</td>
                  <td className="text-center">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                  <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                  <td className="text-center action">
                    <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                      alt="" /></a>
                    <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                      alt="" /></a>
                  </td>
                </tr>

                <tr>
                  <td className="text-left">Elizabath Garsia</td>
                  <td className="text-center">elizabath.garsia@email.com</td>
                  <td className="text-center">03 Oct 24</td>
                  <td className="text-center">04 Oct 24</td>
                  <td className="text-center">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                  <td className="text-center"><a href="javascript:void(0)" className="cta-red">Pending</a></td>
                  <td className="text-center action">
                    <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                      alt="" /></a>
                    <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                      alt="" /></a>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
  
      </div>
    )
}
export default AffiliateDashboard