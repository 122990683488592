import { MuiTelInput } from "mui-tel-input"
import PhoneNumber from "awesome-phonenumber"


export function BotTemplate1({ ...props }) {

    return (
        <div className={props?.type == "test-bot" ? "chat-template-wrap" : ""}>
            <div className={props?.type == "test-bot" ? "col-md-12 col-lg-12 col-xl-12 chat-template" : "col-md-12 col-lg-12 col-xl-12"}>
                <div className={props?.type == "test-bot" ? "card live-chatbox_grid" : "card"} id="chat1" style={{ borderRadius: '15px' }}>
                    <div
                        className="card-header header-back-color d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                        style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                    >
                        <p className="mb-0 fw-bold">Live chat</p>
                        {props?.type != "appearance" && <i className="fas fa-times" onClick={() => props?.setOpenBotTestModal(false)}></i>}
                    </div>
                    <div ref={props.chatContainerRef} className={props?.type == "test-bot" ? "card-body live-chatbox_message page-back-color" : "card-body page-back-color"}>
                        {props?.type == "appearance" &&
                            <>
                                <div className="d-flex flex-row justify-content-start align-items-start mb-4">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div
                                        className="p-3 ms-3 bot-color-box"
                                        style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}
                                    >
                                        <p className="small mb-0 bot-text-color" >
                                            Hi there! Welcome to CoolBiz. I’m here to assist you with everything you need today. 😊
                                        </p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4">
                                    <div className="p-3 me-3 border bg-body-tertiary sender-color-box" style={{ borderRadius: '15px' }}>
                                        <p className="small mb-0 sender-text-color">Thank you, I really like your support.</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-start align-items-start mb-4">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div
                                        className="p-3 ms-3 bot-color-box"
                                        style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}
                                    >
                                        <p className="small mb-0 bot-text-color" >
                                            Please check out the bellow link to get frequently asked questions
                                        </p>
                                        <a href="javascript:void(0)">
                                            <p className="small mb-0 link-color link-primary">https://www.google.com/</p>
                                        </a>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4">
                                    <div className="p-3 me-3 border bg-body-tertiary sender-color-box" style={{ borderRadius: '15px' }}>
                                        <p className="small mb-0 sender-text-color">Thank you. It's cool.</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-start align-items-start mb-4">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div
                                        className="p-3 ms-3 bot-color-box"
                                        style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}
                                    >
                                        <p className="small mb-0 bot-text-color" >
                                            Please select one from the given options
                                        </p>
                                        <ul className="chatflow-option d-flex gap-2">
                                            <a href="javascript:void(0)" className="cta-border-w option-color" >Option 1</a>
                                            <a href="javascript:void(0)" className="cta-border-w option-color" >Option 2</a>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4">
                                    <div className="p-3 me-3 border bg-body-tertiary sender-color-box" style={{ borderRadius: '15px' }}>
                                        <p className="small mb-0 sender-text-color">Option 1</p>
                                    </div>
                                </div>
                            </>}
                        {props?.type == "test-bot" &&
                            <>
                                {props?.testChatFlow?.length > 0 && props?.testChatFlow?.map((flow, index) => (
                                    <>
                                        {!!flow.question &&
                                            <div className={props?.type == "test-bot" ? "d-flex flex-row justify-content-start mb-4 message_box align-items-start" : "d-flex flex-row justify-content-start mb-4"}>
                                                <img
                                                    src={!!props?.botIcon
                                                        ? typeof (props?.botIcon) == "object"
                                                            ? URL.createObjectURL(props?.botIcon)
                                                            : props?.botIcon
                                                        : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                                    alt="avatar 1"
                                                    style={{ width: '45px' }}
                                                />
                                                <div className="p-3 ms-3 bot-color-box"
                                                    style={{ borderRadius: '15px', backgroundColor: 'rgba(57, 192, 237,.2)' }}>
                                                    <p className="small mb-0 bot-text-color">{flow?.question}</p>
                                                    {flow?.options &&
                                                        <ul className="chatflow-option">
                                                            {flow?.options?.length > 0 && flow.options.map((option, ind) =>
                                                            (flow?.type == "single-choice"
                                                                ? <button disabled={!!flow.answer} className="cta-border-w option-color" onClick={() => props.handleSetUserReply(option, "single-choice")}>{option.label}</button>
                                                                : < >
                                                                    <input type="checkbox"
                                                                        disabled={!!flow.answer}
                                                                        value={option}
                                                                        onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                        onKeyDown={(e) => props.handleKeypress(e, props?.questionType)} />
                                                                    <label for="vehicle1">{option}</label><br />
                                                                </>)
                                                            )}
                                                        </ul>}
                                                </div>
                                            </div>}
                                        {flow.answer &&
                                            <div className={props?.type == "test-bot" ? "d-flex flex-row justify-content-end mb-4 message_box" : "d-flex flex-row justify-content-end mb-4"}>
                                                <div className="p-3 me-3 border bg-body-tertiary sender-color-box" style={{ borderRadius: '15px' }}>
                                                    <p className="small mb-0 sender-text-color">{flow.answer}</p>
                                                </div>
                                            </div>}
                                    </>
                                )
                                )}
                                {props.questionDelay &&
                                    <div className={props?.type == "test-bot" ? "d-flex flex-row justify-content-end mb-4 message_box align-items-start" : "d-flex flex-row justify-content-end mb-4"}>
                                        <img
                                            src={!!props?.botIcon
                                                ? typeof (props?.botIcon) == "object"
                                                    ? URL.createObjectURL(props?.botIcon)
                                                    : props?.botIcon
                                                : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                            alt="avatar 1"
                                            style={{ width: '45px' }}
                                        />
                                        <div>
                                            <p className="small mb-0 bot-text-color">...</p>
                                        </div>
                                    </div>}
                            </>
                        }
                    </div>

                    <div className={props?.type == "test-bot" ? "card-footer footer-back-color live-chatbox_footer live-chat_one text-muted d-flex justify-content-start align-items-center p-3" : "card-footer footer-back-color text-muted d-flex justify-content-start align-items-center p-3"}>
                        <div className="card-footer_box d-flex align-items-center w-100">
                            {props?.type == "test-bot" &&
                                (props?.questionType == "phone"
                                    ? <MuiTelInput
                                        name="mobile"
                                        value={props.inputValue}
                                        id="full-width-text-field-mobile"
                                        fullWidth={true}
                                        variant="outlined"
                                        forceCallingCode
                                        onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                        onKeyDown={(e)=>props.handleKeypress(e, props?.questionType)}
                                        enableLongNumbers={true}
                                        autoFormat={false}
                                        disableAreaCodes={true}
                                        countryCodeEditable={false}
                                        autoComplete="off"
                                        className="customtel"
                                    />
                                    : <input
                                        value={props.inputValue}
                                        disabled={props?.questionType == "single-choice"}
                                        type="text"
                                        className="form-control-lg live-chat_one"
                                        placeholder="Type your message"
                                        onChange={(e) => props.handleSetInputvalue(e)}
                                        onKeyDown={(e)=>props.handleKeypress(e, props?.questionType)}
                                    />)
                            }
                            {props?.type == "appearance" &&
                                <>
                                    <label className="type-text w-100 rounded-3 border bg-secondary text-white">Type your message</label>
                                    <a className="ms-1 text-muted" href="javascript:void(0)">
                                        <i className="fas fa-paperclip button-text-color"></i>
                                    </a>
                                    <a className="ms-3 text-muted" href="javascript:void(0)">
                                        <i className="fas fa-smile button-text-color"></i>
                                    </a>
                                </>}
                            <a className="ms-3" href="javascript:void(0)" onClick={(e) => props.handleSetUserReply(e)}>
                                <i className="fas fa-paper-plane button-text-color"></i>
                            </a>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export function BotTemplate2({ ...props }) {

    return (
        <div className={props?.type == "test-bot" ? "chat-template-wrap" : ""}>
            <div className={props?.type == "test-bot" ? "col-md-12 col-lg-12 col-xl-12 chat-template" : "col-md-12 col-lg-12 col-xl-12"}>
                <div className={props?.type == "test-bot" ? "card live-chatbox_grid" : "card"} id="chat2">
                    <div className="card-header header-back-color d-flex justify-content-between text-white align-items-center p-3">
                        <h5 className="mb-0">Live chat</h5>
                        {props?.type != "appearance" && <i className="fas fa-times" onClick={() => props?.setOpenBotTestModal(false)}></i>}
                    </div>
                    <div
                        ref={props.chatContainerRef}
                        className={props?.type == "test-bot" ? "card-body live-chatbox_message page-back-color perfect-scrollbar ps ps--active-y" : "card-body page-back-color perfect-scrollbar ps ps--active-y"}
                        style={{ position: 'relative', height: '100%' }}
                        data-mdb-perfect-scrollbar-init
                    >
                        {props?.type == "appearance" &&
                            <>
                                <div className="d-flex flex-row justify-content-start align-items-start">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div>
                                        <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color">Hi there! Welcome to CoolBiz. I’m here to assist you with everything you need today. 😊</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-4 bg-secondary  sender-color-box sender-text-color">Thank you, I really like your support.</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-start align-items-start">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box">
                                        <p className="bot-text-color">Please check out the bellow link to get frequently asked questions</p>
                                        <a href="javascript:void(0)">
                                            <p className="link-primary link-color">https://www.google.com/</p>
                                        </a>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-4 bg-secondary  sender-color-box sender-text-color">Thank you. It's cool.</p>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-start align-items-start">
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box">
                                        <p className="bot-text-color" >
                                            Please select one from the given options
                                        </p>
                                        <ul className="chatflow-option d-flex gap-2">
                                            <a href="javascript:void(0)" className="cta-border-w option-color" >Option 1</a>
                                            <a href="javascript:void(0)" className="cta-border-w option-color" >Option 2</a>
                                        </ul>
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                    <div>
                                        <p className="small p-2 me-3 mb-1 text-white rounded-4 bg-secondary  sender-color-box sender-text-color">Option 1</p>
                                    </div>
                                </div>
                            </>
                        }
                        {props?.type == "test-bot" &&
                            <>
                                {props?.testChatFlow?.length > 0 && props?.testChatFlow?.map((flow, index) => (
                                    <>
                                        {!!flow.question &&
                                            <div className={props?.type == "test-bot" ? "d-flex flex-row justify-content-start message_box align-items-start" : "d-flex flex-row justify-content-start"}>
                                                <img
                                                    src={!!props?.botIcon
                                                        ? typeof (props?.botIcon) == "object"
                                                            ? URL.createObjectURL(props?.botIcon)
                                                            : props?.botIcon
                                                        : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                                    alt="avatar 1"
                                                    style={{ width: '45px' }}
                                                />
                                                <div>
                                                    <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color">{flow?.question}</p>
                                                    {flow?.options &&
                                                        <ul className="chatflow-option">
                                                            {flow?.options?.length > 0 && flow.options.map((option, ind) =>
                                                            (flow?.type == "single-choice"
                                                                ? <button disabled={!!flow.answer} className="cta-border-w option-color" onClick={() => props.handleSetUserReply(option, "single-choice")}>{option.label}</button>
                                                                : < >
                                                                    <input type="checkbox"
                                                                        value={option}
                                                                        disabled={!!flow.answer}
                                                                        onClick={() => props.handleSetInputvalue(option, "multi-choice")}
                                                                        onKeyDown={(e) => props.handleKeypress(e, props?.questionType)} />
                                                                    <label for="vehicle1">{option}</label><br />
                                                                </>)
                                                            )}
                                                        </ul>}
                                                </div>
                                            </div>}
                                        {flow.answer &&
                                            <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                                <div>
                                                    <p className="small p-2 me-3 mb-1 text-white rounded-4 bg-secondary  sender-color-box sender-text-color">{flow.answer}</p>
                                                </div>
                                            </div>}
                                    </>
                                )
                                )}
                                {props.questionDelay && <div className={props?.type == "test-bot" ? "d-flex flex-row justify-content-start message_box align-items-start" : "d-flex flex-row justify-content-start"}>
                                    <img
                                        src={!!props?.botIcon
                                            ? typeof (props?.botIcon) == "object"
                                                ? URL.createObjectURL(props?.botIcon)
                                                : props?.botIcon
                                            : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                        alt="avatar 1"
                                        style={{ width: '45px' }}
                                    />
                                    <div>
                                        <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary bot-color-box bot-text-color">...</p>

                                    </div>
                                </div>}
                            </>
                        }
                    </div>

                    <div className={props?.type == "test-bot" ? "card-footer footer-back-color live-chatbox_footer live-chat_two text-muted d-flex justify-content-start align-items-center p-3" : "card-footer footer-back-color text-muted d-flex justify-content-start align-items-center p-3"}>
                        <div className="card-footer_box rounded-3 pr-10 border d-flex align-items-center w-100 bg-white">

                            {props?.type == "test-bot" &&
                                (props?.questionType == "phone"
                                    ? <MuiTelInput
                                        name="mobile"
                                        value={props.inputValue}
                                        id="full-width-text-field-mobile"
                                        fullWidth={true}
                                        variant="outlined"
                                        forceCallingCode
                                        onChange={(e) => props.handleSetInputvalue(e, "phone")}
                                        onKeyDown={(e)=>props.handleKeypress(e, props?.questionType)}
                                        enableLongNumbers={true}
                                        autoFormat={false}
                                        disableAreaCodes={true}
                                        countryCodeEditable={false}
                                        autoComplete="off"
                                        className="customtel"
                                    />
                                    : <input
                                        value={props.inputValue}
                                        disabled={props?.questionType == "single-choice"}
                                        type="text"
                                        className="form-control-lg"
                                        placeholder="Type your message"
                                        onChange={(e) => props.handleSetInputvalue(e)}
                                        onKeyDown={(e)=>props.handleKeypress(e, props?.questionType)}
                                    />)
                            }
                            {props?.type == "appearance" &&
                                <>
                                    <label className="type-text w-100">Type your message</label>
                                    <a className="ms-1 text-muted" href="javascript:void(0)">
                                        <i className="fas fa-paperclip button-text-color"></i>
                                    </a>
                                    <a className="ms-3 text-muted" href="javascript:void(0)">
                                        <i className="fas fa-smile button-text-color"></i>
                                    </a>

                                </>
                            }
                            {/* {props?.questionType == "phone" && !new PhoneNumber(`${props.inputValue}`).isValid()
                                ? <i className="fas fa-paper-plane button-text-color"></i>
                                : <> */}
                            <a className="ms-3" href="javascript:void(0)" onClick={(e) => props.handleSetUserReply(e)}>
                                <i className="fas fa-paper-plane button-text-color"></i>
                            </a>
                            {/* </>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}