import React, { useEffect, useState } from 'react'
import { CallAPI } from '../../utils/apiActions';
import { BOT_APPEARANCE_DETAILS, BOT_EMBED } from '../../utils/APIEndPoints';
import Templates from '../my-chatboat/Templates/Templates';
import styled from 'styled-components';
import axios from 'axios';
import useChatBot from '../../hooks/useChatBot';
import { ChatBotProvider } from '../../contexts/ChatBotContext';
import ChatSocketInitialization from '../ChatSocketInitialization';

const BottomChatbotMessage = styled.div`
  position: fixed;
  bottom: 6%;
  right: 6%;
  z-index: 9;
  display: flex;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
`;

const Figure = styled.figure`
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

const Img = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
`;

const MessageBox = styled.div`
  position: absolute;
  right: 0;
  bottom: 54px;
  width: 240px;
  font-size: 14px;
  background: #fff;
  color: #000;
  padding: 24px 12px 12px;
  border-radius: 10px;
`;

const MessageBoxText = styled.p`
  margin-bottom: 0;
  line-height: 1.6;
`;

const CloseBox = styled.div`
  background-color: #ccc;
  width: 22px;
  height: 22px;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover {
    background-color: #000000;
    color: #fff;
  }
`;

export default function LiveChat() {

  // const searchParams = new URLSearchParams(window.location.search);
  // const [idParam,setIdParam] = searchParams.get('id');

  const [shortCode, setShortCode] = useState('')

  const [colorSettingsArray, setColorSettingsArray] = useState([
    { key: "header_bg", lable: "Header Background", color: "#355266", elementClassName: "header-back-color", props: "background-color" },
    { key: "page_bg", lable: "Page Background", color: "#ffffff", elementClassName: "page-back-color", props: "background-color" },
    { key: "bot_tb_color", lable: "Bot Text Box Color", color: "#ffffff", elementClassName: "bot-color-box", props: "background-color" },
    { key: "bot_txt_color", lable: "Bot Text Color", color: "#000000", elementClassName: "bot-text-color", props: "color" },
    { key: "sender_tb_color", lable: "Sender Text box Color", color: "#ffffff", elementClassName: "sender-color-box", props: "background-color" },
    { key: "sender_txt_color", lable: "Sender Text Color", color: "#000000", elementClassName: "sender-text-color", props: "color" },
    { key: "link_color", lable: "Link Color", color: "#3498db", elementClassName: "link-color", props: "color" },
    { key: "btn_txt_color", lable: "Icon Color", color: "#212529bf", elementClassName: "button-text-color", props: "color" },
    { key: "option_tb_color", lable: "Option Button Color", color: "#3498db", elementClassName: "option-color", props: "background" },
    { key: "option_txt_color", lable: "Option Text Color", color: "#ffffff", elementClassName: "option-color", props: "color" },
    { key: "footer_bg", lable: "Footer Background", color: "#ffffff", elementClassName: "footer-back-color", props: "background-color" }
  ]);

  const [openBotTestModal, setOpenBotTestModal] = useState(false)
  const [botSettings, setBotSettings] = useState(null)
  const [descDiv, setDescDiv] = useState(true)

  const getIdFromScript = () => {
    const scripts = document.getElementsByTagName('script');
    let scriptSrc = '';

    // Find the script tag that includes your React app (i.e., main.js)
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.includes('chatbot.')) {  // Match your React script file name
        scriptSrc = scripts[i].src;
        break;
      }
    }

    // If the script URL is found, extract the 'id' query parameter
    if (scriptSrc) {
      const urlParams = new URLSearchParams(new URL(scriptSrc).search);
      setShortCode(urlParams.get('id'))
      getBotStyle(urlParams.get('id'))
    }
    else
    {
      getBotStyle("951644555488")
    }
  };

  useEffect(() => {
    colorSettingsArray?.map((settings, index) => {
      const elements = document.querySelectorAll(`.${settings.elementClassName}`);
      elements.forEach((el) => {
        el.style.setProperty(settings.props, settings.color, 'important');
      });
    })
  }, [colorSettingsArray, openBotTestModal]);

  useEffect(() => {
    getIdFromScript()
  }, []);

  console.log("idParam", shortCode, window.location, shortCode);

  const getBotStyle = async (short_code) => {
    let styleArr = [...colorSettingsArray]
    axios.defaults.headers['domain'] = window.location.href;
    axios.defaults.headers['shortcode'] = short_code;
    const response = await CallAPI("BOT", "GET", BOT_EMBED)
    delete axios.defaults.headers['domain'];
    delete axios.defaults.headers['shortcode'];
    setBotSettings(response.res.data.data[0])
    console.log("response apprance----", response, response.res.data.data[0])
    for (let [key, value] of Object.entries(response?.res?.data?.data[0]?.styles)) {
      var foundIndex = styleArr.findIndex(x => x.key == key);
      if (foundIndex != -1) styleArr[foundIndex].color = value;
    }
    setColorSettingsArray(styleArr)
  };

  return (
    <ChatBotProvider>
      {
        <>
          <ChatSocketInitialization shortCode={shortCode}/>
          {openBotTestModal && botSettings
            ? <Templates
              type={"live-bot"}
              selectedTemplate={botSettings?.template_name}
              setOpenBotTestModal={setOpenBotTestModal}
              botBubbleIcon={botSettings?.bot_image || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
              colorSettingsArray={colorSettingsArray}
            />
            : <BottomChatbotMessage>
              {descDiv && <MessageBox>
                <CloseBox href="javascript:void(0)" onClick={() => setDescDiv(false)}>X</CloseBox>
                <MessageBoxText>{botSettings?.chat_bbl_desc || "your bot description here"}</MessageBoxText>
              </MessageBox>}
              <Figure onClick={() => setOpenBotTestModal(true)}>
                <Img
                  src={botSettings?.slugs ? botSettings?.slugs : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                  alt="images-100" /></Figure>
            </BottomChatbotMessage>}
        </>
      }
    </ChatBotProvider>
  )
}
