import React from 'react';
import { useNavigate } from 'react-router-dom';

const Affiliate = () => {
    const navigate = useNavigate();

    return (
        <div>
            <section class="affiliate-grid">
                <div class="container">
                    <div class="banner-home_text with-h1-line text-center">
                        <h1>Become a CoolBiz AI Chatbot Affiliate</h1>

                        <p>Unlock the opportunity to partner with CoolBiz AI Chatbot and earn a steady, recurring income by 
                        promoting our innovative chatbot solution! Joining our affiliate program lets you share a powerful, 
                        high-demand tool with your network while enjoying exclusive benefits and lucrative rewards.</p>
                    </div>

                    <div class="affiliate-program">
                        <figure class="left_img"><img src="images/affiliate-program-demo-img.svg" alt="" /></figure>
                        <article class="right_info">
                            <h4>Why Join Our Affiliate Program?</h4>
                            <h5>Limited-Time Opportunity</h5>
                            <p>Join as a Beta Tester and play a role in shaping the future of CoolBiz AI Chatbot. Test-drive 
                            new tools and experience firsthand the features that will give you an edge in promoting the product.</p>
                            <a href="javascript:void(0)" class="cta-green w-200" onClick={()=>navigate('/affiliate/signup')}>JOIN NOW</a>
                        </article>
                    </div>

                    <hr /> 

                    <div class="affiliate-benefits"> 
                        <header>
                            <h4>Benefits of Becoming a CoolBiz AI Chatbot Affiliate</h4>
                        </header>

                        <div class="affiliate_cards d-flex">
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/personalized-icon.svg" alt="" /></figure>
                                <h4>Personalized Affiliate Links</h4>
                                <p>We provide unique affiliate links to make tracking each of your referrals simple and effective.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/tracking-icon.svg" alt="" /></figure>
                                <h4>Real-Time Tracking and Reporting</h4>
                                <p>Keep a close eye on your progress with our real-time dashboard, allowing you to monitor clicks, conversions, and earnings as they happen.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/payouts-icon.svg" alt="" /></figure>
                                <h4>Automated Monthly Payouts</h4>
                                <p>We believe in hassle-free earnings! Enjoy monthly payouts directly to your account without the need for manual intervention.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/easy-touse-icon.svg" alt="" /></figure>
                                <h4>Easy-to-Use Affiliate Dashboard</h4>
                                <p>Your performance at your fingertips. Our dedicated dashboard makes it easy to view your earnings, commissions, and promotional resources in one convenient place.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/promotional-icon.svg" alt="" /></figure>
                                <h4>Promotional Resources Provided</h4>
                                <p>Access high-converting banners, links, and marketing materials designed to make promoting CoolBiz AI Chatbot easier and more effective.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/extended-icon.svg" alt="" /></figure>
                                <h4>Extended 30-Day Cookie Duration</h4>
                                <p>Benefit from a 30-day cookie window, which means you’ll earn commissions on any purchases made within 30 days of a referral click.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/no-earnings-icon.svg" alt="" /></figure>
                                <h4>No Cap on Earnings</h4>
                                <p>Maximize your income with unlimited earning potential. There’s no cap on your earnings—get rewarded as much as you can promote!</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/multi-tier-icon.svg" alt="" /></figure>
                                <h4>Multi-Tier Affiliate Options</h4>
                                <p>Recruit sub-affiliates and earn a percentage of their referrals, multiplying your earning potential.</p>
                            </div>
                            <div class="affiliate_card">
                                <figure><img src="images/affiliate-icon/access-icon.svg" alt="" /></figure>
                                <h4>Exclusive Early Access to New Features</h4>
                                <p>Affiliates gain early access to updates and new chatbot features before they’re released to the public, keeping you one step ahead in the market.</p>
                            </div>
                        </div>

                        <div class="text-center">
                            <a href="javascript:void(0)" className="cta-green w-200" onClick={()=>navigate('/affiliate/signup')}>JOIN NOW</a>
                        </div>
                    </div> 

                    <hr /> 

                    <div class="affiliate-join_block">
                        <h4>Join Our Affiliate Program</h4>
                        <p>Start earning today and take advantage of CoolBiz AI Chatbot’s cutting-edge technology and high-demand features!</p>
                        <a href="javascript:void(0)" className="cta-green w-200" onClick={()=>navigate('/affiliate/signup')}>JOIN NOW</a>
                    </div>                 
                </div>
            </section>
        </div>
    )
}

export default Affiliate;