import React, { useEffect, useState } from "react";
import ChangePasswordSection from "../ChangePasswordSection";
import { CallAPI } from "../../utils/apiActions";
import SlashScreen from "../SplashScreen";
import { showToast } from "../../helper/toastHelper";
import { LABEL } from "../../utils/constant";
import { BOT_LIST_WITH_STATUS, BOT_STATUS_UPDATE } from "../../utils/APIEndPoints";

export default function Setting() {
  const [chatbots, setChatbots] = useState([]);
  const [loader,setLoader]=useState(false)
  const updateChatBotStatus = async (bot_id, status) => {
    const payload={
      bot_id,
      status
    }
    try {
      const response = await CallAPI("BOT","PUT",BOT_STATUS_UPDATE,payload)
      showToast(response?.msg||"",LABEL.TOAST_TYPE.SUCCESS)
    } catch (error) {
      console.error("Error updating chatbot status:", error);
    }
  };

  const handleToggle = (bot_id) => {
    setChatbots((prevChatbots) => {
      const updatedChatbots = prevChatbots.map((chatbot) =>
        chatbot.bot_id === bot_id
          ? { ...chatbot, status: chatbot.status === 1 ? 0 : 1 }
          : chatbot
      );
      const toggledChatbot = updatedChatbots.find(
        (chatbot) => chatbot.bot_id === bot_id
      );

       updateChatBotStatus(bot_id, toggledChatbot.status);

      return updatedChatbots;
    });
  };
  const fetchBotWithStatus = async () => {
    setLoader(true)
    try {
      const res = await CallAPI("BOT", "GET", BOT_LIST_WITH_STATUS);
      setChatbots(res?.res?.data?.data||[]);
    } catch (err) {
      console.log("err", err);
    }
    finally{
      setLoader(false)
    }
  };
  useEffect(() => {
    fetchBotWithStatus();
  }, []);
  return (
   loader ?<SlashScreen/> : <div className="setting-grid">
      <header>
        <h3>Settings</h3>
      </header>

      <div className="setting-block">
        {chatbots.length && (
          <>
            <div className="chatbot-list">
              <h4>Chatboat List</h4>

              <ul>
                {chatbots.map((chatbot) => (
                  <li key={chatbot?.bot_id}>
                    <span className="name-label">{chatbot?.bot_name}</span>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={chatbot.status === 1}
                        onChange={() => handleToggle(chatbot.bot_id)}
                      />
                      <span className="toggleSlider"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <hr />
          </>
        )}
        <ChangePasswordSection />
      </div>
    </div>
  );
}
