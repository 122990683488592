import React, { useCallback, useEffect } from 'react';
import useDashboard from '../hooks/useDashboard';

const RefundPolicy = () => {

	const { isLoading, getCmsByCmsType, allCMS } = useDashboard();

	useEffect(() => {
		fetchAllCmsList(5);
	}, []);

	const fetchAllCmsList = useCallback((type) => {
		getCmsByCmsType(type);
	}, [getCmsByCmsType]);

	return (
		<div>
			<section class="pp-text-grid">
				<div class="container">

				<div class="banner-home_text with-h1-line text-center">
						<h1>Refund Policy</h1>
					</div>

					{
						allCMS?.cmsData?.map((rp) => (
							<div key={rp.id} class="privacy-policy_block">
								<h3>{rp.title || ""}</h3>
								<h4>{rp.subTitle || ""}</h4>
								<p>{rp.description || ""}</p>
							</div>
						))
					}
				</div>
			</section>
		</div>
	)
}

export default RefundPolicy;