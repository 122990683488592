import React, { useState } from 'react';
const AffiliateSettings = ({ ...props }) => {
    return(
        <div className="setting-grid">
            <header className="">
                <h3>Settings</h3>
            </header>

            <div className="setting-block">
                <div className="chatbot-list">
                <h4>Chatboat List</h4>

                <ul>
                    <li>
                    <span className="name-label">Chatboat 1</span>
                    <label className="toggle-switch">
                        <input type="checkbox" checked />
                        <span className="toggleSlider"></span>
                    </label>
                    </li>
                    <li>
                    <span className="name-label">Chatboat 2</span>
                    <label className="toggle-switch">
                        <input type="checkbox" />
                        <span className="toggleSlider"></span>
                    </label>
                    </li>
                    <li>
                    <span className="name-label">Chatboat 3</span>
                    <label className="toggle-switch">
                        <input type="checkbox" />
                        <span className="toggleSlider"></span>
                    </label>
                    </li>
                </ul>
                </div>

                <hr />

                <div class="col-md-8 col-lg-6">
                    <div class="referralLink">
                        <h6>Referral Link</h6>
                        <div class="group-form d-flex align-items-center gap-3">                            
                            <button className="cta-green">Generate Short code</button>
                            <div class="with-icon">
                                <input type="text" class="form-control" id="txtDomainLink" name="short_code" placeholder="coolbiz/aichatbot/chatbot1/798956..." value="coolbiz/aichatbot/chatbot1/798956..." />
                                <a href="javascript:void(0)"><i class="fa-light fa-clone" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div class="error"></div>
                    </div>
                </div>

                
            </div>
        </div>
    )
}
export default AffiliateSettings