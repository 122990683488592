import React, { useCallback, useEffect, useState } from 'react';
import useDashboard from '../hooks/useDashboard';

const Documentation = () => {

  const { isLoading, getCmsDocument } = useDashboard();
  const [documents, setDocuments] = useState(null);
  const [activeFaq, setActiveFaq] = useState(null);

  useEffect(() => {
    fetchDocument();
  }, []);

  const fetchDocument = useCallback(async () => {
    const response = await getCmsDocument();
    setDocuments(response);
  }, [getCmsDocument]);


  return (
    <section className="body-wrap">

      <div className="documentation-grid">
        <div className="container">
          <div className="banner-home_text with-h1-line text-center">
            <h1>Documentation</h1>
          </div>
        </div>

        <div className="documentation-grid_list">
          <div className="group-link">
            <ul>
              <li>
                <a href="#ChatbotIntegration"
                  className="cta-border nav-link">Chatbot
                  Integration Guides</a>
              </li>
              <li>
                <a href="#FAQs"
                  className="cta-border nav-link">FAQs</a>
              </li>
              <li>
                <a href="#tutorialsVideo"
                  className="cta-border nav-link">Onboarding
                  Tutorials</a>
              </li>
            </ul>
          </div>

          <section id="ChatbotIntegration" className="pt--80">
            <div className="container">
              <div className="chatbot-ig">
                <header>
                  <h3>Chatbot Integration Guides</h3>
                </header>
                <ul>
                  {
                    documents?.cig?.map((cig) => (<li key={cig.id}>
                      <h5>{cig.title || ""}</h5>
                      <p>{cig.description || ""}</p>
                    </li>))
                  }
                  {/* <li>
                    <h5>training material 1</h5>
                    <p>Lorem Ipsum has been the
                      industry's standard dummy text
                      ever since the 1500s, when an
                      unknown printer took a galley
                      of type and scrambled it to make
                      a type specimen book. It has
                      survived not only five
                      centuries, but also the leap
                      into
                      electronic typesetting,
                      remaining essentially unchanged.
                      It was popularised in the 1960s
                      with the release of Letraset
                      sheets containing Lorem Ipsum
                      passages, and more recently with
                      desktop publishing software like
                      Aldus PageMaker including
                      versions of Lorem Ipsum.</p>
                  </li>
                  <li>
                    <h5>training material 2</h5>
                    <p>Lorem Ipsum has been the
                      industry's standard dummy text
                      ever since the 1500s, when an
                      unknown printer took a galley
                      of type and scrambled it to make
                      a type specimen book. It has
                      survived not only five
                      centuries, but also the leap
                      into
                      electronic typesetting,
                      remaining essentially unchanged.
                      It was popularised in the 1960s
                      with the release of Letraset
                      sheets containing Lorem Ipsum
                      passages, and more recently with
                      desktop publishing software like
                      Aldus PageMaker including
                      versions of Lorem Ipsum.</p>
                  </li>
                  <li>
                    <h5>training material 3</h5>
                    <p>Lorem Ipsum has been the
                      industry's standard dummy text
                      ever since the 1500s, when an
                      unknown printer took a galley
                      of type and scrambled it to make
                      a type specimen book. It has
                      survived not only five
                      centuries, but also the leap
                      into
                      electronic typesetting,
                      remaining essentially unchanged.
                      It was popularised in the 1960s
                      with the release of Letraset
                      sheets containing Lorem Ipsum
                      passages, and more recently with
                      desktop publishing software like
                      Aldus PageMaker including
                      versions of Lorem Ipsum.</p>
                  </li>
                  <li>
                    <h5>training material 4</h5>
                    <p>Lorem Ipsum has been the
                      industry's standard dummy text
                      ever since the 1500s, when an
                      unknown printer took a galley
                      of type and scrambled it to make
                      a type specimen book. It has
                      survived not only five
                      centuries, but also the leap
                      into
                      electronic typesetting,
                      remaining essentially unchanged.
                      It was popularised in the 1960s
                      with the release of Letraset
                      sheets containing Lorem Ipsum
                      passages, and more recently with
                      desktop publishing software like
                      Aldus PageMaker including
                      versions of Lorem Ipsum.</p>
                  </li> */}
                </ul>
              </div>
            </div>
          </section>

          <section id="FAQs" className="pt--80">
            <div className="container">
              <div className="faqs-grid">
                <header>
                  <h3>FAQs</h3>
                </header>

                <ul>
                  {
                    documents?.faq?.map((faq, index) => (<li key={faq.id} className={`acc ${activeFaq === index ? 'active' : ""}`} onClick={() => setActiveFaq(index)}>
                      <div className="acc-head">
                        <h5>{faq.title || ""}</h5>
                      </div>
                      <div className={`acc-info ${!(activeFaq === index) ? 'none' : ""}`}>
                        <p>{faq.description || ""}</p>
                      </div>
                    </li>))
                  }
                  {/* <li className="acc active">
                    <div className="acc-head">
                      <h5>CoolBiz AI Chatbot Question 1</h5>
                    </div>
                    <div className="acc-info">
                      <p>Lorem Ipsum has been the
                        industry's standard dummy
                        text ever since the 1500s,
                        when an unknown printer took
                        a galley
                        of type and scrambled it to
                        make a type specimen book.
                        It has survived not only
                        five centuries, but also the
                        leap into
                        electronic typesetting,
                        remaining essentially
                        unchanged. It was
                        popularised in the 1960s
                        with the release of Letraset
                        sheets containing Lorem
                        Ipsum passages, and more
                        recently with desktop
                        publishing software like
                        Aldus PageMaker including
                        versions of Lorem Ipsum.</p>
                    </div>
                  </li>
                  <li className="acc">
                    <div className="acc-head">
                      <h5>CoolBiz AI Chatbot Question 2</h5>
                    </div>
                    <div className="acc-info none">
                      <p>Lorem Ipsum has been the
                        industry's standard dummy
                        text ever since the 1500s,
                        when an unknown printer took
                        a galley
                        of type and scrambled it to
                        make a type specimen book.
                        It has survived not only
                        five centuries, but also the
                        leap into
                        electronic typesetting,
                        remaining essentially
                        unchanged. It was
                        popularised in the 1960s
                        with the release of Letraset
                        sheets containing Lorem
                        Ipsum passages, and more
                        recently with desktop
                        publishing software like
                        Aldus PageMaker including
                        versions of Lorem Ipsum.</p>
                    </div>
                  </li>
                  <li className="acc">
                    <div className="acc-head">
                      <h5>CoolBiz AI Chatbot Question
                        3</h5>
                    </div>
                    <div className="acc-info none">
                      <p>Lorem Ipsum has been the
                        industry's standard dummy
                        text ever since the 1500s,
                        when an unknown printer took
                        a galley
                        of type and scrambled it to
                        make a type specimen book.
                        It has survived not only
                        five centuries, but also the
                        leap into
                        electronic typesetting,
                        remaining essentially
                        unchanged. It was
                        popularised in the 1960s
                        with the release of Letraset
                        sheets containing Lorem
                        Ipsum passages, and more
                        recently with desktop
                        publishing software like
                        Aldus PageMaker including
                        versions of Lorem Ipsum.</p>
                    </div>
                  </li>
                  <li className="acc">
                    <div className="acc-head">
                      <h5>CoolBiz AI Chatbot Question
                        4</h5>
                    </div>
                    <div className="acc-info none">
                      <p>Lorem Ipsum has been the
                        industry's standard dummy
                        text ever since the 1500s,
                        when an unknown printer took
                        a galley
                        of type and scrambled it to
                        make a type specimen book.
                        It has survived not only
                        five centuries, but also the
                        leap into
                        electronic typesetting,
                        remaining essentially
                        unchanged. It was
                        popularised in the 1960s
                        with the release of Letraset
                        sheets containing Lorem
                        Ipsum passages, and more
                        recently with desktop
                        publishing software like
                        Aldus PageMaker including
                        versions of Lorem Ipsum.</p>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </section>

          <section id="tutorialsVideo" className="pt--80">
            <div className="tutorials-video-grid">
              <div className="container">
                <div className="intro-text">
                  <h5>CoolBiz AI Chatbot Question 1</h5>
                  <p>Lorem Ipsum has been the industry's
                    standard dummy text ever since the
                    1500s, when an unknown printer took
                    a galley
                    of type and scrambled it to make a
                    type specimen book. It has survived
                    not only five centuries, but also
                    the leap into
                    electronic typesetting, remaining
                    essentially unchanged. It was
                    popularised in the 1960s with the
                    release of Letraset
                    sheets containing Lorem Ipsum
                    passages, and more recently with
                    desktop publishing software like
                    Aldus PageMaker including
                    versions of Lorem Ipsum.</p>
                </div>
              </div>

              <div className="container larg">
                <header>
                  <h3>Onboarding Tutorials</h3>
                </header>
                <ul className="training-videos">

                  {
                    documents?.ot?.map((ot) => (<li key={ot.id}>
                      <div className="training-videos_block">
                        <a href="javascript:void(0)"
                          className="play-video"><i
                            className="fa-solid fa-circle-play"></i></a>
                        <img
                          src={ot.fileLink || "images/mask-img/mask-group-1.png"}
                          alt={ot.title} />
                      </div>
                    </li>
                    ))
                  }

                  {/* <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-1.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-2.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-3.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-4.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-9.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-8.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-7.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-6.png"
                        alt="mask image" />
                    </div>
                  </li>
                  <li>
                    <div className="training-videos_block">
                      <a href="javascript:void(0)"
                        className="play-video"><i
                          className="fa-solid fa-circle-play"></i></a>
                      <img
                        src="images/mask-img/mask-group-5.png"
                        alt="mask image" />
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </section>

        </div>

      </div>

    </section>
  )
}

export default Documentation;