import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import { COUPON_TABLE_CONSTANT, LABEL, replaceActionCallback, TABLE_CONSTANT } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import ConfirmationModal from "../UI/ConfirmationModal";

export default function PromoCodeManagement() {
  const { getAllCoupons, couponList, deleteCoupon, isLoading } = useCmsManagement();
  const { user } = useAuth();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {

    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }

    fetchAllCoupon();

  }, []);

  function fetchAllCoupon(page=1) {
    const payload = {
      userId: user.id
    }
    getAllCoupons(payload,page);
  }
  const handlePagination=(page)=>{
    fetchAllCoupon(page)
  }
  const pagination = useMemo(() => {
    return {
      current: couponList.currentPage,
      pageSize: couponList.pageSize,
      total: couponList.totalRecords,
      onChange: handlePagination,
    };
  }, [couponList.currentPage, couponList.pageSize, couponList.totalRecords, getAllCoupons]);
  const handleDelete = () => {
    if (selectedItem) {
      const payload = { couponId: selectedItem.id }; // Assuming `item.id` is the unique identifier
      deleteCoupon(payload, afterSuccess)
    }
    cancelDelete();
  };
  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };
  const afterSuccess = (msg) => {
    fetchAllCoupon();
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  }

  // Optimized delete function with useCallback
  const deleteCouponFn = useCallback(
    (item) => {
      console.log(item)
      setSelectedItem(item)
      setDeleteModalVisible(true)
    },
    [deleteCoupon, getAllCoupons, user.id]
  );


  const modifiedActions = replaceActionCallback(
    COUPON_TABLE_CONSTANT.usersManagementactions,
    "Delete",
    deleteCouponFn
  );

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Promocode Management</h3>

          <Link to="/admin/promocode/create" className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            Create New
          </Link>
        </header>
        <CommonTable
          data={couponList?.couponList||[]}
          columns={COUPON_TABLE_CONSTANT.usersManagementcolumns}
          actions={modifiedActions}
          pagination={pagination}
          isPagination={true}
        />
      </div>
      {deleteModalVisible && (
        <ConfirmationModal
          handleDelete={handleDelete}
          cancelDelete={cancelDelete}
        ></ConfirmationModal>
      )}
    </React.Fragment>
  );
}
