import React, { useCallback, useEffect } from 'react';
import useDashboard from '../hooks/useDashboard';

const PrivacyPolicy = () => {

    const { isLoading, getCmsByCmsType, allCMS } = useDashboard();

	useEffect(() => {
		fetchAllCmsList(6);
	}, []);

	const fetchAllCmsList = useCallback((type) => {
		getCmsByCmsType(type);
	}, [getCmsByCmsType]);


    return (
        <div>
            <section class="pp-text-grid">
                <div class="container">
                    <div class="banner-home_text with-h1-line text-center">
                        <h1>Privacy Policy</h1>
                    </div>

                    {
						allCMS?.cmsData?.map((pp) => (
							<div key={pp.id} class="privacy-policy_block">
								<h3>{pp.title || ""}</h3>
								<h4>{pp.subTitle || ""}</h4>
								<p>{pp.description || ""}</p>
							</div>
						))
					}

                    {/* <div class="privacy-policy_block">
                        <h4>Effective Date : October 25th, 2024</h4>
                        <p>At CoolBiz AI Chatbot, we are committed to protecting your privacy. This Privacy Policy explains 
                        how we collect, use, and protect your personal information when you interact with our chatbot services. 
                        We respect the privacy of our subscribers and their users and adhere to strict privacy standards.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Data Collection</h3>
                        <h4>Personal Information</h4>
                        <p>When you subscribe to or use CoolBiz AI Chatbot, we collect information such as names, email addresses, 
                        and phone numbers to manage your subscription and provide our services.</p>
                        
                        <h4>Usage Data</h4>
                        <p>We collect data related to your interactions with the chatbot, including IP addresses, timestamps, and 
                        communication logs. This data is collected for operational, security, and troubleshooting purposes to improve service functionality.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Use of Data</h3>
                        <h4>Affiliate Data</h4>
                        <p>If you participate in the Affiliate Program, we may collect necessary information to manage affiliate earnings 
                        and compliance, including your name, contact information, and payment details. This data is used exclusively for 
                        program management, commission payouts, and legal obligations.</p>
                        
                        <h4>We use the data we collect exclusively for internal purposes to</h4>
                        <ul>
                            <li>Manage your subscription, including payment processing.</li>
                            <li>Provide and improve our chatbot services.</li>
                            <li>Communicate with you regarding your account, subscription status, and updates to our services.</li>
                        </ul>
                        <p><strong>We never sell or share your personal information or your users' data to third parties for marketing or 
                        advertising purposes.</strong> We honor our commitment to safeguarding your privacy by using your data strictly for 
                        operational purposes.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Third-Party Services</h3>
                        <p>CoolBiz AI Chatbot integrates with third-party services (such as CRMs, cloud computing platforms, and other 
                        operational tools). These third-party services have privacy policies and are used strictly for operational purposes 
                        to support the chatbot's functionality. While we ensure these integrations meet our high-security standards, we do 
                        not control their data-handling practices.</p>
                        <p>By using our chatbot, you acknowledge that your data may be shared with these third-party platforms solely to 
                        ensure seamless functionality. We do not share your data with third parties for any marketing or non-operational 
                        purposes.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Data Security</h3>
                        <p>We implement robust security measures to protect your personal information from unauthorized access, alteration, 
                        or disclosure. Our systems utilize the highest level of encryption across all integrated platforms, ensuring your 
                        data is securely transferred and stored.</p>
                        <p>While we maintain high-security standards, no system can be 100% secure, and we cannot guarantee the absolute 
                        security of your information. It is important to recognize that once data is processed or transferred outside our 
                        system (e.g., through third-party platforms integrated with your chatbot), you are responsible for ensuring compliance 
                        with relevant privacy and security regulations.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>HIPAA Compliance</h3>
                        <p>CoolBiz AI Chatbot is designed to support HIPAA-friendly practices. Still, full compliance is only guaranteed 
                        after comprehensive testing and validation of the specific data and configurations you have entered or trained the 
                        chatbot on. You are responsible for conducting <strong>your validation and testing</strong> to ensure that your chatbot complies with 
                        HIPAA standards before going live. Until compliance is confirmed, the chatbot should not be used to handle sensitive 
                        health information.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Data Retention</h3>
                        <p>We retain your data only as long as necessary to fulfill the purposes outlined in this policy. You may request 
                        deletion of your data, and we will comply with such requests under applicable data protection laws.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>User Rights</h3>
                        <p>You can access, modify, or delete your personal information anytime. If you have any requests related to your 
                        data, please contact us at support@coolbiz.info, and we will address them in compliance with applicable regulations.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Changes to the Privacy Policy</h3>
                        <p>We reserve the right to update this Privacy Policy periodically. Continued use of our services after any updates 
                        constitutes acceptance of the revised policy. We encourage you to review this Privacy Policy regularly.</p>
                        <p>For any questions or concerns regarding your privacy, contact us at support@coolbiz.info.</p>
                    </div>

                    <div class="privacy-policy_block">
                        <h3>Affiliate and Agency Data</h3>
                        <h4>Data Collection for Affiliates and Agencies</h4>
                        <p>If you participate in the Affiliate or Agency Program, we may collect necessary information, including names, 
                        contact information, payment details, and other data required for program management, commission payouts, and 
                        legal obligations.</p>
                        
                        <h4>Use of Data</h4>
                        <p>Affiliate and agency data is used exclusively for managing earnings, compliance, and operational purposes. 
                        This data is not shared with third parties for marketing purposes and is only processed as necessary for fulfilling 
                        CoolBiz AI Chatbot’s obligations related to the Affiliate or Agency Program.</p>

                        <h4>Third-Party Services</h4>
                        <p>Certain data related to payments and tax compliance may be processed through third-party services solely to 
                        facilitate the operations of our affiliate and agency programs.</p>

                        <h4>Data Security</h4>
                        <p>Affiliate and agency data is protected with the same level of security and encryption as subscriber data. 
                        We use robust security measures to protect your data from unauthorized access, alteration, or disclosure.</p>
                    </div> */}
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy;