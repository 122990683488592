import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../UI/dropdown";
import ToggleSwitch from "../../UI/toggle-switch";
import FileUpload from "../../UI/file-upload";
import { defaultValuesForUsers, LABEL } from "../../../utils/constant";

import { showToast } from "../../../helper/toastHelper";
import { userSchema } from "../../../utils/validationSchema";
import useCmsManagement from "../../../hooks/useCmsManagement";

const createFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

export default function CreateOrUpdateUsers() {
  const { createUsers,isLoading } = useCmsManagement();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
     resolver: yupResolver(userSchema),
    defaultValues: defaultValuesForUsers,
  });


  const afterSuccess = (msg) => {
    console.log("sas",msg)
    navigate("/admin/role");
    return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
  };

  const onSubmit = (data) => {
    createUsers(data, afterSuccess)
  };

  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>{id ? LABEL.USER.EDIT_HEADER : LABEL.USER.HEADER}</h3>

              {/* <div className="group-form d-md-flex">
                <a href="javascript:void(0)" className="cta-green">
                  {LABEL.CMS.SAVE_AND_CONTINUE}
                </a>
              </div> */}
            </header>

            <div className="chatSettings-grid">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="row">
                      <div class={"col-md-12"}>
                        <div class="field">
                          <label for="textareaChatbotDescription">
                            {LABEL.USER.NAME}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            {...register("fullName")}
                            className={`form-control ${
                              errors.fullName ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Full Name"
                          />
                          {errors.fullName && (
                            <span className="invalid-feedback">
                              {errors.fullName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class={"col-md-12"}>
                        <div class="row">
                          <div class={"col-md-6"}>
                            <div class="field">
                              <label for="textareaChatbotDescription">
                                {LABEL.USER.EMAIL}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                {...register("email")}
                                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Email Address"
                              />
                              {errors.email && (
                                <span className="invalid-feedback">
                                  {errors.email.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class={"col-md-6"}>
                            <div class="field">
                              <label for="textareaChatbotDescription">
                                {LABEL.USER.PHONE}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                {...register("phoneNumber")}
                                className={`form-control ${
                                  errors.phoneNumber ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Your Number"
                              />
                              {errors.phoneNumber && (
                                <span className="invalid-feedback">
                                  {errors.phoneNumber.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      

                      <div class="col-md-12">
                        <div class="field">
                          <div class="group-form d-flex align-items-center gap-3">
                            {/* Submit Button */}
                            <button
                              type="submit"
                              className="cta-green"
                              disabled={!!isLoading}
                            >
                              {!!isLoading && (
                                <i class="fa-solid fa-spinner fa-spin"></i>
                              )}
                              {LABEL.CMS.SAVE_AND_CONTINUE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
