
import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MuiTelInput } from "mui-tel-input";
import { registrationSchema } from "../../utils/validationSchema";
import useAuth from "../../hooks/useAuth";
import { defaultValues } from "../../utils/constant";
import PasswordFields from '../../components/UI/PasswordFields';
import useDashboard from '../../hooks/useDashboard';

export const cleanNumber = (value) => {
    return value.replace(/\s/g, "");
};

const SignUpForm = ({ ...props }) => {
    const navigate = useNavigate();
    let location = useLocation();

    const { loader, register } = useAuth();
    const { checkPromocode } = useDashboard();

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registrationSchema),
        defaultValues: defaultValues,
    });

    function returnRest(data) {
        const { confirmPassword, ...rest } = data;
        return rest
    }

    const onSubmit = async (data) => {
        props.setRegisterData(data)
        if (data.promoCode) {
            const checkPromo = await checkPromocode({
                "promoCode": data.promoCode
            });
            // if(checkPromo?.data){
            //   showToast("Promocode Applied Success", LABEL.TOAST_TYPE.SUCCESS);
            // }
            if (!checkPromo?.data) {
                return
            }
        }

        register(returnRest(data));

        // emai_verification -----> By Sharmishtha
        // let response = register(returnRest(data));
        // if (response) {
        //   props.setView("register")
        // }
    };

    return (
        <div className="form-grid_left">
            <div className="form-grid_gredient">
                <div className="form-grid_block">
                    <figure>
                        <Link to="/">
                            <img src="images/coobiz-logo.svg" alt />
                        </Link>
                    </figure>

                    <div className="loginForm">
                        <header>
                            <h4>Create your account</h4>
                            <p>Please create your account to continue</p>
                        </header>
                        <form
                            id
                            method="get"
                            action
                            role="form"
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                        >
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="field">
                                        <label for="txtName">Name</label>

                                        <Controller
                                            name="fullName"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtName"
                                                    placeholder="Enter Your Name"
                                                />
                                            )}
                                        />

                                        {errors.fullName && (
                                            <p>{errors.fullName.message}</p>
                                        )}
                                    </div>
                                    <div className="field">
                                        <label for="txtEmail">Email</label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtEmail"
                                                    name="txtEmail"
                                                    placeholder="Enter Your Email"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                        {errors.email && <p>{errors.email.message}</p>}
                                    </div>
                                    <div className="field">
                                        <label for="txtPhoneNumber">Phone Number</label>

                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <MuiTelInput
                                                    {...field}
                                                    onChange={(value, info) => {
                                                        field.onChange(cleanNumber(value));
                                                    }}
                                                    id="full-width-text-field-mobile"
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    forceCallingCode
                                                    enableLongNumbers={true}
                                                    autoFormat={false}
                                                    disableAreaCodes={true}
                                                    countryCodeEditable={false}
                                                    autoComplete="off"
                                                    className="customtel"
                                                    placeholder="Enter Your Phone Number"
                                                />
                                            )}
                                        />
                                        {errors.phoneNumber && (
                                            <p>{errors.phoneNumber.message}</p>
                                        )}
                                    </div>
                                    <div className="field">
                                        <label for="txtPassword">Password</label>
                                        <PasswordFields
                                            id="txtPassword"
                                            name="password"
                                            control={control}
                                            errors={errors}
                                            placeholder="Enter Your Password"
                                        />
                                    </div>
                                    <div className="field">
                                        <label for="txtConfirmPassword">
                                            Confirm Password
                                        </label>
                                        <PasswordFields
                                            id="txtConfirmPassword"
                                            name="confirmPassword"
                                            control={control}
                                            errors={errors}
                                            placeholder="Confirm Your Password"
                                        />
                                    </div>

                                    <div className="field">
                                        <label for="txtOrganizationName">
                                            Organization Name
                                        </label>

                                        <Controller
                                            name="organizationName"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtOrganizationName"
                                                    placeholder="Enter organization name"
                                                />
                                            )}
                                        />

                                        {errors.organizationName && (
                                            <p>{errors.organizationName.message}</p>
                                        )}
                                    </div>

                                    <div className="field">
                                        <label for="txtBusinessURL">Business URL</label>

                                        <Controller
                                            name="businessUrl"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtBusinessURL"
                                                    placeholder="Enter Business URL"
                                                />
                                            )}
                                        />

                                        {errors.businessUrl && (
                                            <p>{errors.businessUrl.message}</p>
                                        )}
                                    </div>

                                    <div className="field">
                                        <label for="txtPromoCode">Promo code</label>

                                        <Controller
                                            name="promoCode"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="form-control"
                                                    id="txtPromoCode"
                                                    placeholder="Enter Promo code"
                                                />
                                            )}
                                        />

                                        {errors.promoCode && (
                                            <p>{errors.promoCode.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 text-center mb--20">
                                    <button
                                        type="submit"
                                        disabled={!!loader}
                                        className="cta-green"
                                        id="loginFormSubmit"
                                    >
                                        {!!loader && <i class="fa-solid fa-spinner fa-spin"></i>}
                                        Create Account
                                    </button>
                                </div>
                                {/* <div className="col-12 text-center">
                          <div className="or-devider">
                            <span>or</span>
                          </div>
                        </div>
                        <div className="login-with-social">
                          <a href="javascript:void(0)" className="cta-border">
                            <img
                              src="images/icons/google-icon.png"
                              alt="icon"
                            />
                            Google
                          </a>
                          <a href="javascript:void(0)" className="cta-border">
                            <img
                              src="images/icons/facebook-icon.png"
                              alt="icon"
                            />
                            Facebook
                          </a>
                        </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpForm;