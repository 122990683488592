import React from "react";

const ToggleSwitch = ({ label, checked, value, onChange, disabled=false }) => {
  return (
    <React.Fragment>
      <span>{label}</span>
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className="toggleSlider"></span>
      </label>
    </React.Fragment>
  );
};

export default ToggleSwitch;
