import React from 'react'
import { Link } from 'react-router-dom'

export default function PaymentSuccess() {
  return (
    <div>
      <h1>Payment Success</h1>
      <Link to={'/'}>Go to homegae</Link>
    </div>
  )
}
