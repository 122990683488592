import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import {
  LABEL,
  replaceActionCallback,
  TABLE_CONSTANT,
} from "../../utils/constant";
import ConfirmationModal from "../UI/ConfirmationModal";
import { showToast } from "../../helper/toastHelper";
import { Link, useNavigate } from "react-router-dom";
import TableSearchFilter from "../UI/TableSearchFilter";
import UpdateViewModal from "./users/UpdateViewModal";

export default function UserManagement() {
  const navigate=useNavigate()
  const { getAllUsers, usersList, isLoading, deleteUsers } = useCmsManagement();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterData,setFilterData]=useState({
    search:"",
    filter:""
  })
  const [selectIdsForExport,setSelectedForExport]=useState([])
  const [modal,setModal]=useState({
    show:false,
    data:{}
  })
  const fetchUsers = (page = 1) => {
    getAllUsers(page,filterData);
  };
  const handlePagination = (page = 1) => {
    fetchUsers(page);
  };
  const pagination = useMemo(() => {
    return {
      current: usersList.currentPage,
      pageSize: usersList.pageSize,
      total: usersList.totalRecords,
      onChange: handlePagination,
    };
  }, [usersList.currentPage, usersList.pageSize, usersList.totalRecords]);

  useEffect(() => {
    fetchUsers();
  }, [filterData.search]);
  const afterSuccess = useCallback(
    (msg) => {
      fetchUsers();
      return showToast(msg, LABEL.TOAST_TYPE.SUCCESS);
    },
    [fetchUsers]
  );
  const handleDelete = () => {
    if (selectedItem) {
      deleteUsers(selectedItem.id, afterSuccess);
    }
    cancelDelete();
  };
  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };


  const deletePlanFn = useCallback((item) => {
    console.log(item);
    setSelectedItem(item);
    setDeleteModalVisible(true);
  }, []);

  // const goForEdit = (id) => navigate(`/admin/role/edit/${id}`)
  const editPlanFn = useCallback((item) => {
    setModal({show:true,data:item})
    // goForEdit(item.id)
  }, []);
  let modifiedActions = TABLE_CONSTANT.usersManagementactions;

  modifiedActions = replaceActionCallback(
    modifiedActions,
    LABEL.TABLE_COLUMN.ACTION.DELETE.LABEL,
    deletePlanFn
  );

  modifiedActions = replaceActionCallback(
    modifiedActions,
    LABEL.TABLE_COLUMN.ACTION.EDIT.LABEL,
    editPlanFn
  );
  const handleFilter = (searchValue, selectedColumns) => {
    console.log("Search Value:", searchValue);
    console.log("Selected Columns:", selectedColumns.toLowerCase());
    setFilterData({
      search:searchValue,
      filter:selectedColumns.toLowerCase()
    })
  };
  const onClose=()=>{
    setModal({data:{},show:false})
  }
  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Customer Management</h3>

          <Link to="/admin/role/create" className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            New Customer
          </Link>
          <div className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            Export CSV
          </div>
          <TableSearchFilter defaultColumn={"NAME"} columns={["NAME",'EMAIL',"PLAN",'PROMO']} onFilter={handleFilter} />
        </header>
        <CommonTable
          data={usersList?.users || []}
          columns={TABLE_CONSTANT.usersManagementcolumns}
          actions={modifiedActions}
          pagination={pagination}
          isPagination={true}
          isLoading={isLoading}
          onRowSelectionChange={(data)=>setSelectedForExport(data||[])}
          isSelectedRowFeature={true}
        />
      </div>
      {deleteModalVisible && (
        <ConfirmationModal
          handleDelete={handleDelete}
          cancelDelete={cancelDelete}
        ></ConfirmationModal>
      )}
        {modal.show&&<UpdateViewModal onClose={onClose} item={modal.data} fetchUsers={fetchUsers}/>}
    </React.Fragment>
  );
}
