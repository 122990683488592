import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CallAPI } from "../../utils/apiActions";
import { BOT_SETTINGS, BOT_SETTINGS_PROPS, GENERATE_EMBED } from "../../utils/APIEndPoints";
import validationUtility from "../../utils/Validation";
import { LABEL } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";

export default function BotChatSettings({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const bot_id = (new URLSearchParams(location?.search).get("id")) || null
  const [submissionLoader, setSubmissionLoader] = useState(false)
  const [shortCodeLoader, setShortCodeLoader] = useState(false)
  const [botSettingsProps, setBotSettingProps] = useState({})
  const [isCopied, setIsCopied] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [showPreviewDesc, setShowPreviewDesc] = useState(true);
  const [editContentData, setEditContentData] = useState({
    chat_bbl_desc: "",
    tone: "1",
    language: "1",
    fontHeader: "1",
    fontBody: "1",
    responseLength: "1",
    widgetCheck: 0,
    files: "",
    filesWidget: "",
    filesAvatar: "",
    domain_link: "",
    short_code: ""
  })
  const [stateForErr, setStateForErr] = useState({
    chat_bbl_descErr: "",
    toneErr: "",
    languageErr: "",
    fontHeaderErr: "",
    fontBodyErr: "",
    responseLengthErr: "",
    widgetCheckErr: "",
    filesWidgetErr: "",
    filesAvatarErr: "",
    domain_linkErr: "",
    short_codeErr: "",
    filesTypeErr: null,
    completedStep: ""
  })
  const [dataLoader, setDataLoader] = useState(true)

  useEffect(() => {
    getBotSettingsProps()
    getBotSettings()
  }, []);

  useEffect(() => {
    setShowPreviewDesc(true)
  }, [editContentData.chat_bbl_desc]);

  useEffect(() => {
    if (editContentData.widgetCheck == 1) {
      setPreviewImage(editContentData.filesAvatar)
    }
    else {
      setPreviewImage(editContentData.filesWidget)
    }
  }, [editContentData.widgetCheck, editContentData.filesWidget, editContentData.filesAvatar]);

  const getBotSettings = async () => {
    let response = await CallAPI("BOT", "GET", `${BOT_SETTINGS}/${bot_id}`)
    console.log("response.res.data.data set--", response?.res.data?.data)
    if (response.status) {
      let contentData = response?.res.data?.data
      setDataLoader(false)
      if (contentData?.widgetCheck == 0) {
        contentData.filesWidget = response?.res.data?.data.files
      }
      else {
        contentData.filesAvatar = response?.res.data?.data.files
      }
      props.setFinalStep(response?.res.data?.data.completedStep || 3)
      setEditContentData({ ...editContentData, ...contentData })
    }
    else {
      showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
    }
  }

  console.log("editContentData", editContentData)
  const getBotSettingsProps = async () => {
    let response = await CallAPI("BOT", "GET", `${BOT_SETTINGS_PROPS}/${bot_id}`)
    setBotSettingProps(response?.res.data?.data)
    console.log("response.res.data.data--", response?.res.data?.data)
  }

  const handleFormInput = (e, type = '') => {
    console.log("event----", e.target.name)
    let loEditedContent = { ...editContentData }
    let loStateForErr = { ...stateForErr }
    if (type == 'file') {
      let file = e.target.files[0]
      if (file?.type == 'image/png') {
        if (!editContentData.widgetCheck) {
          loEditedContent.filesWidget = file
          loStateForErr.filesWidgetErr = ''
        }
        else {
          loEditedContent.filesAvatar = file
          loStateForErr.filesAvatarErr = ''
        }
      }
      else {
        if (!editContentData.widgetCheck) {
          loStateForErr.filesWidgetErr = 'Only .png is supported'
        }
        else {
          loStateForErr.filesAvatarErr = 'Only .png is supported'
        }
      }
    }
    else if (type == 'check') {
      loEditedContent.widgetCheck = e.target.checked ? 1 : 0
      loStateForErr.widgetCheckErr = ''
      loStateForErr.filesWidgetErr = ''
      loStateForErr.filesAvatarErr = ''
    }
    else {
      loEditedContent[e.target.name] = e.target.value
      loStateForErr[e.target.name + 'Err'] = ''
    }
    setEditContentData({ ...editContentData, ...loEditedContent })
    setStateForErr({ ...stateForErr, ...loStateForErr })
  }

  const handleSaveStep4 = async () => {
    setSubmissionLoader(true)
    let mandetoryField = [{ key: "chat_bbl_desc", value: "Description" }, { key: "files", value: "Files" }, { key: "domain_link", value: "Domain Link" }, { key: "short_code", value: "Short Code" }]
    let loStateForErr = { ...stateForErr }
    let flag = true
    mandetoryField.map((field, ind) => {
      if (field.key == "files") {
        if (editContentData.widgetCheck == 0 && (editContentData.filesWidget == null || editContentData.filesWidget == undefined || editContentData.filesWidget == "")) {
          flag = false
          loStateForErr.filesWidgetErr = "Please upload a widget"
          loStateForErr.filesTypeErr = "widget"
        }
        else if (editContentData.widgetCheck == 1 && (editContentData.filesAvatar == null || editContentData.filesAvatar == undefined || editContentData.filesAvatar == "")) {
          flag = false
          loStateForErr.filesAvatarErr = "Please upload a avatar"
          loStateForErr.filesTypeErr = "avatar"
        }
      }
      if (field.key == "domain_link" && !!editContentData.domain_link && !validationUtility.validUrl(editContentData.domain_link)) {
        flag = false
        loStateForErr.domain_linkErr = "Not a valid domain link"
      }
      if ((editContentData[field.key] == null || editContentData[field.key] == "" || editContentData[field.key] == undefined)) {
        flag = false
        loStateForErr[field.key + 'Err'] = field.key == "short_code" ? "Please generate your short code to complete bot configuration." : `${field.value} is required`
      }
    })
    console.log("loStateForErr---", loStateForErr)
    setStateForErr(loStateForErr)
    if (!!flag) {
      let method = editContentData.completedStep == 4 ? "PUT" : "POST"
      const formData = new FormData();
      let files = editContentData.widgetCheck == 0 ? editContentData.filesWidget : editContentData.filesAvatar
      formData.append("files", files);
      Object.entries(editContentData).forEach(([key, value]) => {
        if ((key != "filesWidget" && key != "filesAvatar" && key != "files")) {
          formData.append(key, value);
        }
      });
      formData.append("step", 4)
      if (method == "POST") {
        formData.append("bot_id", bot_id)
      }
      let response = await CallAPI("BOT", method, BOT_SETTINGS, formData, "file")
      if (response.status) {
        showToast(response.msg, LABEL.TOAST_TYPE.SUCCESS);
        navigate('/my-chatbots')
      }
      else {
        showToast(response.msg, LABEL.TOAST_TYPE.ERROR);
      }
    }
    setSubmissionLoader(false)
  }

  const handleGenerateShortCode = async () => {
    setShortCodeLoader(true)
    const response = await CallAPI("BOT", "POST", GENERATE_EMBED, { bot_id: bot_id });
    console.log("response---", response)
    setEditContentData({ ...editContentData, short_code: response.res.data.short_code })
    setShortCodeLoader(false)
    setStateForErr({ ...stateForErr, short_codeErr: "" })
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHORT_CODE_BUILD_PATH}?id=${editContentData.short_code}`).then(() => {
      setIsCopied(true); // Show the "link copied" message
      setTimeout(() => {
        setIsCopied(false); // Hide the message after 3 seconds
      }, 2000);
      // alert('Text copied to clipboard!');
    }).catch((err) => {
      console.error('Error copying text: ', err);
    });
  };

  console.log("previewImage---", previewImage, editContentData)

  return (
    <div className="mychatbot-grid">
      <div className="tab-content-grid">
        <div className="tab-content active">
          <div className="chatbot-history-grid">
            <header className="d-md-flex align-items-center justify-content-between">
              <h3>Bot Chat Settings</h3>
              <div className="group-form d-md-flex">
                <button disabled={submissionLoader} className="cta-green" onClick={() => handleSaveStep4()}>
                  {submissionLoader && <i class="fa-solid fa-spinner fa-spin"></i>}
                  Save and continue
                </button>
              </div>
            </header>
            {dataLoader
              ? <div className="page-loader">
                <img src="images/loader-bouncing-circles.svg" alt="" />
              </div>
              : <div className="chatSettings-grid">
                <form>
                  <div class="row">
                    <div class="col-md-10 col-lg-8">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="field">
                            <label for="textareaChatbotDescription">Chatbot Description *</label>
                            <textarea
                              class="form-control"
                              name="chat_bbl_desc"
                              id="textareaChatbotDescription"
                              rows="5" placeholder='Type...'
                              value={editContentData.chat_bbl_desc}
                              onChange={(e) => handleFormInput(e)} />
                            {stateForErr.chat_bbl_descErr && <span>{stateForErr.chat_bbl_descErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectTone">Tone</label>
                            <select class="form-select" value={editContentData.tone} name="tone" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.tone?.length > 0 && botSettingsProps?.tone.map((tone, ind) => (
                                <option value={tone.pid}>{tone.pname}</option>
                              ))}
                            </select>
                            {stateForErr.toneErr && <span>{stateForErr.toneErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectLanguage">Language</label>
                            <select class="form-select" name="language" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.language?.length > 0 && botSettingsProps?.language.map((language, ind) => (
                                <option value={language.pid}>{language.pname}</option>
                              ))}
                            </select>
                            {stateForErr.languageErr && <span>{stateForErr.languageErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontHeader">Font Header</label>
                            <select class="form-select" name="fontHeader" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.fontHeader?.length > 0 && botSettingsProps?.fontHeader.map((fontHeader, ind) => (
                                <option value={fontHeader.pid}>{fontHeader.pname}</option>
                              ))}
                            </select>
                            {stateForErr.fontHeaderErr && <span>{stateForErr.fontHeaderErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontBody">Font Body</label>
                            <select class="form-select" name="fontBody" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.fontBody?.length > 0 && botSettingsProps?.fontBody.map((fontBody, ind) => (
                                <option value={fontBody.pid}>{fontBody.pname}</option>
                              ))}
                            </select>
                            {stateForErr.fontBodyErr && <span>{stateForErr.fontBodyErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <label for="selectFontBody">Response Length</label>
                            <select class="form-select" name="responseLength" onChange={(e) => handleFormInput(e)}>
                              {botSettingsProps?.responseLength?.length > 0 && botSettingsProps?.responseLength.map((responseLength, ind) => (
                                <option value={responseLength.pid}>{responseLength.pname}</option>
                              ))}

                            </select>
                            {stateForErr.responseLengthErr && <span>{stateForErr.responseLengthErr}</span>}
                          </div>
                        </div>
                        <div class="col-12 mb-4">
                          <div class="d-flex align-items-center gap-3 text-14">
                            <span>Upload widget</span>
                            <label class="toggle-switch">
                              <input type="checkbox" name="widgetCheck" checked={editContentData.widgetCheck == 1 || false} onChange={(e) => handleFormInput(e, "check")} />
                              <span class="toggleSlider"></span>
                            </label>
                            <spam class="text-grey">Upload Chatbot Avatar</spam>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field">
                            <div class={!editContentData.widgetCheck ? "file-upload-container" : "file-upload-container disabled"}>
                              {!editContentData.filesWidget && <div class="image-preview" id="image-preview">
                                <span><img src="images/icons/upload-img-icon.svg" alt="upload icon" /></span>
                              </div>}
                              <div className="uploadWidget">
                                <label class="file-upload-label" for="file-upload">{editContentData.widgetCheck ? "Chat Bubble Icon" : "Chat Bubble Icon *"}</label>
                                <input
                                  type="file"
                                  id="file-upload"
                                  class="file-upload-input"
                                  accept="image/*"
                                  onClick={e => (e.target.value = null)}
                                  onChange={(e) => handleFormInput(e, "file")} />
                                {!!editContentData.filesWidget && <img
                                  // alt={editContentData.template_name}
                                  src={editContentData.filesWidget ? typeof (editContentData.filesWidget) == "object" ? URL.createObjectURL(editContentData.filesWidget) : editContentData.filesWidget : ''}
                                  sx={{ width: 56, height: 56 }}
                                />}
                              </div>
                            </div>
                            {stateForErr.filesWidgetErr && <span>{stateForErr.filesWidgetErr}</span>}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="field">
                            <div class={editContentData.widgetCheck ? "file-upload-container" : "file-upload-container disabled"}>
                              {!editContentData.filesAvatar && <div class="image-preview" id="image-preview">
                                <span><img src="images/icons/upload-img-icon.svg" alt="upload icon" /></span>
                              </div>}
                              <div className="uploadWidget">
                                <label class="file-upload-label" for="file-upload">{editContentData.widgetCheck ? "Upload Chatbot Avatar *" : "Upload Chatbot Avatar"}</label>
                                <input type="file"
                                  id="file-upload"
                                  class="file-upload-input"
                                  accept="image/*"
                                  onChange={(e) => handleFormInput(e, "file")}
                                  onClick={e => (e.target.value = null)} />
                                {!!editContentData.filesAvatar && <img
                                  // alt={editContentData.template_name}
                                  src={editContentData.filesAvatar ? typeof (editContentData.filesAvatar) == "object" ? URL.createObjectURL(editContentData.filesAvatar) : editContentData.filesAvatar : ''}
                                  sx={{ width: 56, height: 56 }} />}
                              </div>
                            </div>
                            {stateForErr.filesAvatarErr && <span>{stateForErr.filesAvatarErr}</span>}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="field">
                            <label for="txtDomainLink">Domain Link *</label>
                            <input type="text" value={editContentData.domain_link} class="form-control" id="txtDomainLink" name="domain_link" placeholder="https/domailname/page_name..." onChange={(e) => handleFormInput(e)} />
                            {stateForErr.domain_linkErr && <span>{stateForErr.domain_linkErr}</span>}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="field">
                            <div class="group-form d-flex align-items-center gap-3">
                              {!!editContentData.short_code
                                ? <label for="txtDomainLink">Short Code</label>
                                : <>
                                  <a href="javascript:void(0)" class="cta-green" onClick={() => handleGenerateShortCode()}>
                                    {shortCodeLoader && <i class="fa-solid fa-spinner fa-spin"></i>}
                                    GENERATE SHORT CODE *
                                  </a>
                                </>}

                              <div class="with-icon">
                                <input type="text" disabled value={!!editContentData.short_code ? `${process.env.REACT_APP_SHORT_CODE_BUILD_PATH}?id=${editContentData.short_code}` : ""} class="form-control" id="txtDomainLink" name="short_code" placeholder="coolbiz/aichatbot/chatbot1/798956..." />
                                {!!editContentData.short_code && <a href="javascript:void(0)" onClick={copyToClipboard} ><i class="fa-light fa-clone"></i></a>}
                                {isCopied && (
                                  <span style={{ marginLeft: '10px', color: 'green' }}>
                                    Link copied successfully!
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="error">{stateForErr.short_codeErr && <span>{stateForErr.short_codeErr}</span>}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>}

          </div>
        </div>
      </div>

      {/* <div className="bottom-chatbot_message none">
        <figure>
          <img
            src={previewImage ? typeof (previewImage) == "object" ? URL.createObjectURL(previewImage) : previewImage : "images/images-100.png"}
            alt="images-100" /></figure>
        <span>{editContentData.chat_bbl_desc || "your bot description here"}</span>
      </div> */}

      {!dataLoader && <div className="bottom-chatbot_message2">
        <div className={showPreviewDesc ? "messageBox" : "messageBox none"}>
          <a href="javascript:void(0)" className="closeBox" onClick={()=>setShowPreviewDesc(false)}>X</a>
          <p>{editContentData.chat_bbl_desc || "your bot description here"}</p>
        </div>
        <figure>
          <img
            src={previewImage ? typeof (previewImage) == "object" ? URL.createObjectURL(previewImage) : previewImage : "images/images-100.png"}
            alt="images-100" /></figure>
      </div>}
    </div>
  )
}
