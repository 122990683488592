import React, { Suspense, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import SplashScreen from "./components/SplashScreen"
import Home from './pages/Home';
import AuthLayout from './layouts/AuthLayout';
import GuestLayout from './layouts/GuestLayout';
import AuthGuard from './guards/AuthGuard';
import Error from './pages/404';
import Pricing from './pages/Pricing';
import Documentation from './pages/Documentation';
import ContactUs from './pages/ContactUs';
import Features from './pages/Features';
import UnAuthGuard from './guards/UnAuthGuard';
import UnAuthLayout from './layouts/UnauthLayout';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import SubscriptionPlans from './pages/SubscriptionPlans';
import ChatHistory from './pages/ChatHistory';
import Chatbots from './pages/Chatbots';
import Analytics from './pages/Analytics';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Settings from './pages/Settings';
import BotSettings from './pages/BotSettings';
import SubscriptionManagement from './components/admin/SubscriptionManagement';
import UserManagement from './components/admin/UserManagement';
import TransactionManagement from './components/admin/TransactionManagement';
import PromoCodeManagement from './components/admin/PromoCodeManagement';
import Cms from './components/admin/Cms';
import AdminGuard from './guards/AdminGuard';
import GeneratePromocode from './components/admin/promocode/GeneratePromocode';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import Affiliate from './pages/Affiliate';
import Terms from './pages/Terms';
import PlanManagement from './components/admin/PlanManagement';
import CreateOrUpdatePlan from './components/admin/plan/CreateOrUpdatePlan';
import PaymentStatus from './components/payment-status/PaymentStatus';
import PaymentSuccess from './components/payment-status/PaymentSuccess';
import PaymentFailure from './components/payment-status/PaymentFailure';
import AdminLogin from './components/admin/AdminLogin';
import CreateOrUpdateCms from './components/admin/cms/CreateOrUpdateCms';
import ContactUsManagement from './components/admin/ContactUsManagement';
import PlanList from './components/my-subscription/PlanList';
import CreateOrUpdateUsers from './components/admin/users/CreateOrUpdateUsers';
import AffiliateGuard from './guards/AffiliateGuard/AffiliateGuard';
import AffiliateDashboard from './components/Affiliate/AffiliateDashboard';
import AffiliateSettings from './components/Affiliate/AffiliateSettings';
import AffiliateReferalActivity from './components/Affiliate/AffiliateReferalActivity';
import AffiliateEarning from './components/Affiliate/AffiliateEarning';
import LiveChat from './components/live-chat/LiveChat';
import EmailVerification from './pages/EmailVerification';
import AgencyGuard from './guards/AgencyGuard';
import AccessManagement from './components/accessManagement/AccessManagement';
import SubAccount from './components/subAccount/SubAccount';



export const renderRoutes = (routes = []) => (
  <Suspense fallback={<SplashScreen />}>
    <Routes>
      {routes.map((route, i) => {
        // const guard = route.guard || Fragment;
        const layout = route.layout || Fragment;
        // const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              layout == "auth"
                ? <AuthGuard>
                  <AuthLayout>
                    {(route.path == "/dashboard" && <Dashboard />)}
                    {(route.path == "/my-chatbots" && <Chatbots />)}
                    {(route.path == "/bot-settings" && <BotSettings />)}
                    {(route.path == "/chat-history" && <ChatHistory />)}
                    {(route.path == "/my-plan" && <SubscriptionPlans />)}
                    {(route.path == "/profile" && <Profile />)}
                    {(route.path == "/analytics" && <Analytics />)}
                    {(route.path == "/settings" && <Settings />)}
                    {(route.path == "/payment-status" && <PaymentStatus />)}
                    {(route.path == "/payment-success" && <PaymentSuccess />)}
                    {(route.path == "/payment-failure" && <PaymentFailure />)}
                    {(route.path == "/plan-list" && <PlanList />)}
                    {(route.path == "/help" && <Documentation />)}
                    {(route.path == "/role" && <UserManagement />)}
                    {(route.path == "/role/create" && <CreateOrUpdateUsers />)}
                    {(route.path == "/role/edit/:id" && <CreateOrUpdateUsers />)}
                    {(route.path == "/demo/path1" && <SubAccount />)}
                    {(route.path == "/demo/path2" && <CreateOrUpdateUsers />)}
                    {(route.path == "/demo/path3" && <CreateOrUpdateUsers />)}
                    {(route.path == "/demo/path4" && <CreateOrUpdateUsers />)}
                  </AuthLayout>
                </AuthGuard>
                : layout == "admin"
                  ? <AdminGuard>
                    <AuthLayout>
                      {(route.path == "/admin/dashboard" && <Dashboard />)}
                      {(route.path == "/admin/my-chatbots" && <Chatbots />)}
                      {(route.path == "/admin/bot-settings" && <BotSettings />)}
                      {(route.path == "/admin/chat-history" && <ChatHistory />)}
                      {(route.path == "/admin/profile" && <Profile />)}
                      {(route.path == "/admin/analytics" && <Analytics />)}
                      {(route.path == "/admin/settings" && <Settings />)}
                      {(route.path == "/admin/role" && <UserManagement />)}
                      {(route.path == "/admin/role/create" && <CreateOrUpdateUsers />)}
                      {(route.path == "/admin/role/edit/:id" && <CreateOrUpdateUsers />)}
                      {(route.path == "/admin/subscription-management" && <SubscriptionManagement />)}
                      {(route.path == "/admin/transaction-management" && <TransactionManagement />)}
                      {(route.path == "/admin/promocode-management" && <PromoCodeManagement />)}
                      {(route.path == "/admin/promocode/create" && <GeneratePromocode />)}
                      {(route.path == "/admin/plan" && <PlanManagement />)}
                      {(route.path == "/admin/plan/create" && <CreateOrUpdatePlan />)}
                      {(route.path == "/admin/plan/edit/:id" && <CreateOrUpdatePlan />)}
                      {(route.path == "/admin/cms" && <Cms />)}
                      {(route.path == "/admin/cms/create" && <CreateOrUpdateCms />)}
                      {(route.path == "/admin/cms/edit/:id" && <CreateOrUpdateCms />)}
                      {(route.path == "/admin/contact-us-management" && <ContactUsManagement />)}
                      {(route.path == "/admin/access-management" && <AccessManagement />)}

                    </AuthLayout>
                  </AdminGuard>
                  : layout == "affiliate"
                    ? <AffiliateGuard>
                      <AuthLayout>
                        {(route.path == "/affiliate/dashboard" && <AffiliateDashboard />)}
                        {(route.path == "/affiliate/settings" && <AffiliateSettings />)}
                        {(route.path == "/affiliate/referal-activity" && <AffiliateReferalActivity />)}
                        {(route.path == "/affiliate/profile" && <Profile />)}
                        {(route.path == "/affiliate/earning" && <AffiliateEarning />)}
                      </AuthLayout>
                    </AffiliateGuard>
                    : layout == "agency"
                      ? <AgencyGuard>
                        <AuthLayout>
                          {(route.path == "/agency/dashboard" && <Dashboard />)}
                          {(route.path == "/agency/my-chatbots" && <Chatbots />)}
                          {(route.path == "/agency/bot-settings" && <BotSettings />)}
                          {(route.path == "/agency/chat-history" && <ChatHistory />)}
                          {(route.path == "/agency/profile" && <Profile />)}
                          {(route.path == "/agency/analytics" && <Analytics />)}
                          {(route.path == "/agency/settings" && <Settings />)}
                          {(route.path == "/agency/role" && <UserManagement />)}
                          {(route.path == "/agency/role/create" && <CreateOrUpdateUsers />)}
                          {(route.path == "/agency/role/edit/:id" && <CreateOrUpdateUsers />)}
                          {(route.path == "/agency/my-plan" && <SubscriptionPlans />)}
                          {(route.path == "/agency/subscription-management" && <SubscriptionManagement />)}
                          {(route.path == "/agency/transaction-management" && <TransactionManagement />)}
                          {(route.path == "/agency/promocode-management" && <PromoCodeManagement />)}
                          {(route.path == "/agency/promocode/create" && <GeneratePromocode />)}
                          {(route.path == "/agency/plan" && <PlanManagement />)}
                          {(route.path == "/agency/plan/edit/:id" && <CreateOrUpdatePlan />)}
                          {(route.path == "/agency/branding-management" && <Cms />)}
                          {(route.path == "/agency/branding-management/create" && <CreateOrUpdateCms />)}
                          {(route.path == "/agency/branding-management/edit/:id" && <CreateOrUpdateCms />)}
                          {(route.path == "/agency/payment-management" && <ContactUsManagement />)}
                        </AuthLayout>
                      </AgencyGuard>
                      : layout == "unauth"
                        ? route.guard == "unauth"
                          ? <UnAuthGuard>
                            <UnAuthLayout isHeaderFooter={route.isHeaderFooter == false ? false : true}>
                              {(route.path == "/" && <Home />)}
                              {(route.path == "/login" && <Login type="login" userType="user" />)}
                              {(route.path == "/admin/login" && <AdminLogin type="adminlogin" />)}
                              {(route.path == "/signup" && <Signup type="signup" userType="user" />)}
                              {(route.path == "/affiliate/signup" && <Signup type="signup" userType="user" />)}
                              {(route.path == "/forget-password" && <Login userType="user" type="forgetPass" />)}
                              {(route.path == "/admin/forget-password" && <Login userType="admin" type="forgetPass" />)}
                              {(route.path == "/reset-password" && <Login userType="user" type="resetPass" />)}
                              {(route.path == "/admin/reset-password" && <Login userType="admin" type="resetPass" />)}
                              {(route.path == "/email-verification" && <EmailVerification userType="user" type="emailVerification" />)}
                              {(route.path == "/payments-status" && <PaymentStatus />)}
                              {(route.path == "/payments-success" && <PaymentSuccess />)}
                              {(route.path == "/payments-failure" && <PaymentFailure />)}
                            </UnAuthLayout>
                          </UnAuthGuard>
                          : <GuestLayout>
                            {(route.path == "/pricing" && <Pricing />)}
                            {(route.path == "/features" && <Features />)}
                            {(route.path == "/documentation" && <Documentation />)}
                            {(route.path == "/contact-us" && <ContactUs />)}
                            {(route.path == "/privacy-policy" && <PrivacyPolicy />)}
                            {(route.path == "/refund-policy" && <RefundPolicy />)}
                            {(route.path == "/affiliate" && <Affiliate />)}
                            {(route.path == "/terms-of-service" && <Terms />)}
                          </GuestLayout>
                        : <LiveChat />
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  //Unauth Route -- General
  {
    exact: true,
    path: '/',
    layout: 'unauth',
    guard: 'unauth'
  },

  {
    exact: true,
    path: '/email-verification',
    layout: 'unauth',
    guard: 'unauth'
  },

  {
    exact: true,
    path: '/login',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/reset-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/signup',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/forget-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/payments-status',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/payments-success',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: true
  },

  {
    exact: true,
    path: '/payments-failure',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: true
  },

  //Unauth Route -- Admin
  {
    exact: true,
    path: '/admin/login',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/admin/forget-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/admin/reset-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  //Unauth Route -- Affiliate
  {
    exact: true,
    path: '/affiliate/signup',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/affiliate/login',
    layout: 'unauth',
    isAdmin: true,
  },

  {
    exact: true,
    path: '/affiliate/forget-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  {
    exact: true,
    path: '/affiliate/reset-password',
    layout: 'unauth',
    guard: 'unauth',
    isHeaderFooter: false
  },

  //Guest Route
  {
    exact: true,
    path: '/contact-us',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/features',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/pricing',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/privacy-policy',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/refund-policy',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/affiliate',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/terms-of-service',
    layout: 'unauth'
  },

  {
    exact: true,
    path: '/documentation',
    layout: 'unauth'
  },

  // User / Subscriber  Route

  {
    exact: true,
    path: '/dashboard',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/plan-list',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/my-chatbots',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/analytics',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/chat-history',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/my-plan',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/profile',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/settings',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/payment-status',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/payment-success',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/payment-failure',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/bot-settings',
    layout: 'auth'
  },

  {
    exact: true,
    path: '/help',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/role',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/role/create',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/role/edit/:id',
    layout: 'auth',
  },

  //Admin Route

  {
    exact: true,
    path: '/admin/dashboard',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/my-chatbots',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/bot-settings',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/chat-history',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/profile',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/analytics',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/settings',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/role',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/role/create',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/role/edit/:id',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/subscription-management',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/transaction-management',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/promocode-management',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/promocode/create',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/plan',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/plan/create',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/plan/edit/:id',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/contact-us-management',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/cms/create',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/cms/edit/:id',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/cms',
    layout: 'admin',
  },

  {
    exact: true,
    path: '/admin/access-management',
    layout: 'admin',
  },


  //Agency Route
  {
    exact: true,
    path: '/agency/dashboard',
    layout: 'agency',
  },

  {
    exact: true,
    path: 'agency/my-chatbots',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/bot-settings',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/chat-history',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/profile',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/analytics',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/settings',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/role',
    layout: 'agency',
  },
  
  {
    exact: true,
    path: '/agency/role/create',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/role/edit/:id',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/my-plan',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/subscription-management',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/transaction-management',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/promocode-management',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/promocode/create',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/plan',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/plan/edit/:id',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/branding-management',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/branding-management/create',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/branding-management/edit/:id',
    layout: 'agency',
  },

  {
    exact: true,
    path: '/agency/payment-management',
    layout: 'agency',
  },
  
  //Affiliate Route

  {
    exact: true,
    path: '/affiliate/dashboard',
    layout: 'affiliate',
  },

  {
    exact: true,
    path: '/affiliate/settings',
    layout: 'affiliate',
  },

  {
    exact: true,
    path: '/affiliate/referal-activity',
    layout: 'affiliate',
  },

  {
    exact: true,
    path: '/affiliate/profile',
    layout: 'affiliate',
  },

  {
    exact: true,
    path: '/affiliate/earning',
    layout: 'affiliate',
  },

  //Demo Route

  {
    exact: true,
    path: '/demo/path1',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/demo/path2',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/demo/path3',
    layout: 'auth',
  },

  {
    exact: true,
    path: '/demo/path4',
    layout: 'auth',
  },

  //Error Route

  {
    path: '*',
    layout: 'error'
  }

];

export default routes;
