// import React from 'react';
// import SignIn from '../components/SignIn'
// import ForgetPass from '../components/signin-signup/ForgetPass';
// import { useLocation, useNavigate } from 'react-router-dom';
// import ResetPassword from '../components/signin-signup/ResetPassword';

// const Login = ({ ...props }) => {
//     const navigate = useNavigate();
//     let location = useLocation();

//     return (
//         location.pathname === "/login"
//             ? <SignIn />
//             : location.pathname === "/forget-password"
//                 ? <ForgetPass userType="user" />
//                 : <ResetPassword userType="user" type={props.type}/>
//     )
// }

// export default Login;

import React from "react";
import LogInBG from "../components/signin-signup/LogInBG";
import LogInForm from "../components/signin-signup/LogInForm";
import ForgetPass from "../components/signin-signup/ForgetPass";
import ResetPassword from "../components/signin-signup/ResetPassword";
import useAuth from "../hooks/useAuth";
const Login = ({ ...props }) => {

    const { isEmailVerified } = useAuth();

    return (
        <div id="window">
            <section className="body-wrap">
                <div className="container">
                    <div className="form-grid">
                        {props.type == "login"
                            ? <LogInForm />
                            : props.type == "forgetPass"
                                ? <ForgetPass type={props.type} userType={props.userType} />
                                : <ResetPassword type={props.type} userType={props.userType} />}
                        {props.userType == "user" && <LogInBG />}
                    </div>
                </div>
            </section>
            {/* End Content  */}
        </div>
    );
};

export default Login;