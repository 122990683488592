import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCmsManagement from "../../hooks/useCmsManagement";
import CommonTable from "../UI/Table";
import { CMS_TABLE_CONSTANT, LABEL, replaceActionCallback } from "../../utils/constant";
import { showToast } from "../../helper/toastHelper";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "../UI/ConfirmationModal";
import { create } from "zustand";

export const tabs = [
  { id: 4, to: "#cmsList", label: "Chatbot Features" },
  { id: 1, to: "#cmsList", label: "Chatbot Integration Guides" },
  { id: 2, to: "#cmsList", label: "FAQs" },
  { id: 3, to: "#cmsList", label: "Onboarding Tutorials" },
  { id: 7, to: "#cmsList", label: "Terms of Service" },
  { id: 6, to: "#cmsList", label: "Privacy Policy" },
  { id: 5, to: "#cmsList", label: "Refund Policy" },
];
const useTab = create((set) => ({
  activeTab: 4,
  setActiveTab:(id)=>set(()=>({activeTab:id})),
}))

export default function Cms() {

  const navigate = useNavigate();
  const { isLoading, getAllCMS, cmsList, deleteCms } = useCmsManagement();
  const { user } = useAuth();
  // const [activeTab, setActiveTab] = useState(4);
  const {activeTab,setActiveTab}=useTab((state)=>state)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleTabClick = (id) => {
    setActiveTab(id);
    fetchAllCmsList(id, 1)
  };


  useEffect(() => {

    if (!user.id) {
      return showToast(LABEL.USER.USER_ID_NOT_FOUND, LABEL.TOAST_TYPE.ERROR);
    }
  
    fetchAllCmsList(activeTab);

  }, []);

  const fetchAllCmsList = useCallback((type, page = 1) => {
    getAllCMS(type, page);
  }, [getAllCMS]);

  const afterSuccess = useCallback(
    (msg) => {
      fetchAllCmsList(activeTab || 1);
      return showToast("Record deleted successfully!", LABEL.TOAST_TYPE.SUCCESS);
    },
    [fetchAllCmsList]
  );

  function handlePagination(page) {

    fetchAllCmsList(activeTab, page)
  }

  const pagination = useMemo(() => {
    return {
      current: cmsList.currentPage,
      pageSize: cmsList.pageSize,
      total: cmsList.totalRecords,
      onChange: handlePagination,
    };
  }, [cmsList.currentPage, cmsList.pageSize, cmsList.totalRecords, fetchAllCmsList]);
  const handleDelete = () => {
    if (selectedItem) {
      deleteCms(selectedItem.id, afterSuccess)
    }
    cancelDelete();
  };
  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedItem(null);
  };
  const deletePlanFn = useCallback(
    (item) => {
      setSelectedItem(item)
      setDeleteModalVisible(true)
    },
    [deleteCms, getAllCMS, user.id]
  );

  const goForEdit = (id) => navigate(`/admin/cms/edit/${id}`)

  // Custom edit function

  const editPlanFn = useCallback(
    (item) => {
      goForEdit(item.id)
    },
    []
  );

  let modifiedActions = CMS_TABLE_CONSTANT.usersManagementactions;

  modifiedActions = replaceActionCallback(modifiedActions, LABEL.TABLE_COLUMN.ACTION.DELETE.LABEL, deletePlanFn);

  modifiedActions = replaceActionCallback(modifiedActions, LABEL.TABLE_COLUMN.ACTION.EDIT.LABEL, editPlanFn);

  const filteredColumns = useMemo(() => {
    const columns = [...CMS_TABLE_CONSTANT.usersManagementcolumns];
    if (![5, 6, 7].includes(activeTab)) {
      return columns.filter((column) => column.key !== "subTitle");
    }
    return columns;
  }, [activeTab]);

  return (
    <React.Fragment>
      <div className="all-chatboat">
        <header className="d-md-flex align-items-center justify-content-between">
          <h3>Informative Content Management</h3>

          <Link to="/admin/cms/create" className="cta-green">
            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
            Add New Content
          </Link>
        </header>
        
        {/* ************************TABS********************* */}

        <section className="body-wrap">
          <div className="documentation-grid">
            <div className="documentation-grid_list">
              <div className="group-link">
                <ul style={{ gap: "10px" }}>
                  {/* <li>
                    <Link
                      replace
                      to="#cmsList"
                      className="cta-border-tab nav-link"
                    >
                      Chatbot Integration Guides
                    </Link>
                  </li> */}
                  {tabs.map((tab) => (
                    <li key={tab.id}>
                      <Link
                        replace
                        to={tab.to}
                        className={`cta-border-tab nav-link ${activeTab === tab.id ? "active" : ""
                          }`}
                        onClick={() => handleTabClick(tab.id)}
                      >
                        {tab.label}
                      </Link>
                    </li>
                  ))}
                  {/* <li>
                    <Link
                      replace
                      to="#FAQs"
                      className="cta-border-tab nav-link"
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      replace
                      to="#tutorialsVideo"
                      className="cta-border-tab nav-link"
                    >
                      Onboarding Tutorials
                    </Link>
                  </li>
                  <li>
                    <Link
                      replace
                      to="#tutorialsVideo"
                      className="cta-border-tab nav-link"
                    >
                      Chatbot Features
                    </Link>
                  </li>
                  <li>
                    <Link
                      replace
                      to="#tutorialsVideo"
                      className="cta-border-tab nav-link"
                    >
                      Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      replace
                      to="#tutorialsVideo"
                      className="cta-border-tab nav-link"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      replace
                      to="#tutorialsVideo"
                      className="cta-border-tab nav-link"
                    >
                      Terms of Service
                    </Link>
                  </li> */}
                </ul>
              </div>

              <section id="cmsList" className="pt--80">
                <div className="container">
                  <div className="chatbot-ig">
                    {/* **********************TABLE******************* */}

                    <React.Fragment>
                      <CommonTable
                        data={cmsList?.cmsData || []}
                        columns={filteredColumns}
                        actions={modifiedActions}
                        pagination={pagination}
                        isPagination={true}
                        loading={isLoading}
                      />
                    </React.Fragment>

                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      {deleteModalVisible && (
        <ConfirmationModal
          handleDelete={handleDelete}
          cancelDelete={cancelDelete}
        ></ConfirmationModal>
      )}
    </React.Fragment>
  );
}
