import React, { useCallback, useEffect } from 'react';
import useDashboard from '../hooks/useDashboard';

const Terms = () => {
  const { isLoading, getCmsByCmsType, allCMS } = useDashboard();

  useEffect(() => {
    fetchAllCmsList(7);
  }, []);

  const fetchAllCmsList = useCallback((type) => {
    getCmsByCmsType(type);
  }, [getCmsByCmsType]);

  return (
    <div>
      <section class="pp-text-grid">
        <div class="container">
          <div class="banner-home_text with-h1-line text-center">
            <h1>Terms of Service</h1>
          </div>

          {
            allCMS?.cmsData?.map((tns) => (<div class="privacy-policy_block" key={tns.id}>
              <h3>{tns.title || ""}</h3>
              <h4>{tns.subTitle || ""}</h4>
              <p>{tns.description || ""}</p>
            </div>
            ))
          }

          {/* <div class="privacy-policy_block">
            <h4>Effective Date : October 25th, 2024</h4>
            <p>CoolBiz AI Chatbot offers a clear and straightforward refund policy for subscription-based services.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Free Trial</h3>
            <p>Welcome to CoolBiz AI Chatbot, provided by CoolBiz Web Design. By accessing or using our chatbot services, you agree to these Terms of Service, which govern your subscription, interactions, and responsibilities. Please read them carefully.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Acceptance of Terms</h3>
            <p>By using our chatbot or subscribing to any services offered by CoolBiz AI Chatbot, you confirm your agreement to these Terms of Service. If you disagree, please do not use our services. These terms apply whether you are in a free trial or paying for a subscription.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Subscription and Billing</h3>
            <h4>14-Day Free Trial</h4>
            <p>We offer a 14-day free trial for all users. If you cancel within this period, you will not be charged.</p>

            <h4>Monthly Billing Cycle</h4>
            <p>Billing occurs on a recurring monthly basis. Subscriptions are non-refundable after the start of a billing period. If you cancel, your subscription will remain active until the end of the current billing cycle, at which point the cancellation will take effect.</p>

            <h4>Annual Billing Cycle</h4>
            <p>Payment is made in full upfront for annual subscriptions. If you cancel an annual plan before the year is complete, we offer pro-rated refunds under extenuating circumstances (e.g., permanent business closure due to unforeseen events like illness or significant life changes). The refund will be calculated based on the number of months used, at the monthly rate for the remaining months, minus a cancellation fee equivalent to one month’s subscription or 10% of the remaining balance, whichever is greater. However, we reserve the right to request supporting documentation to process such refunds.</p>

            <h4>Non-Refundable Policy for Voluntary Cancellation</h4>
            <p>If you voluntarily cancel your annual subscription (i.e., for reasons other than extenuating circumstances), no refunds will be issued, and your service will continue until the end of the current term.</p>
          </div>

          <div class="privacy-policy_block">
            <h4>Use of the Services</h4>
            <p>CoolBiz AI Chatbot services, including third-party integrations (CRMs, cloud computing platforms, subscriber websites, and data, etc.), are provided “as is.” We make no guarantees regarding uptime, accuracy, or reliability. Your use of the chatbot on your website is at your own risk, and you agree to comply with all relevant laws and regulations, including privacy and data protection laws such as HIPAA and GDPR, where applicable.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Liability and Third-Party Services</h3>
            <p>CoolBiz Web Design is not responsible for the performance of third-party services (including CRMs, cloud platforms, subscriber websites, etc.) that you may integrate with the chatbot. You are solely responsible for ensuring compliance with any laws, including privacy and security regulations when using these services.</p>

            <h4>Pricing and Service Errors</h4>
            <p>We are not liable for any discrepancies in pricing or services that may occur due to chatbot errors. If the chatbot provides incorrect information, such as pricing or service details, you must verify the information before acting on it. You agree that your team must verify any commitments, pricing, or services communicated by the chatbot, and CoolBiz Web Design is not responsible for errors made by your chatbot in this context.</p>

            <h3>Transparency Notice</h3>
            <p>We highly recommend that you clearly disclose to your users that they interact with an AI-powered chatbot, not a human. Although the chatbot is highly trained, it is not infallible, and users should understand this distinction to avoid misunderstandings or miscommunications. You are encouraged to regularly test the chatbot and verify its outputs to ensure accuracy and appropriate behavior on your website.</p>

            <h3>Beta Testing</h3>
            <p>CoolBiz AI Chatbot is offered "as is" without any guarantees or warranties during beta testing phases. By participating in beta testing, you acknowledge that the chatbot is in development and its performance may vary. We are not liable for any issues or damages resulting from your use of the chatbot during the beta testing phase.</p>

            <h3>HIPAA Compliance</h3>
            <p>While CoolBiz AI Chatbot is designed to support HIPAA compliance, this compliance is only guaranteed after thorough testing and formal validation of the specific data and configurations you have entered or trained the chatbot on. You agree that until you have tested and validated your own data and chatbot configurations to ensure they meet HIPAA standards, the chatbot should not be used to handle sensitive health or regulated information. Before going live, it is your responsibility to test and verify that your chatbot's use complies with relevant privacy and security regulations, including HIPAA and GDPR. CoolBiz Web Design is not liable for non-compliance arising from untested or improperly configured chatbot settings.</p>

          </div>

          <div class="privacy-policy_block">
            <h3>Content and User Responsibility</h3>
            <p>You are fully responsible for the content generated by your chatbot and for ensuring that it complies with all relevant laws and regulations. CoolBiz Web Design is not liable for any content or outcomes from using the chatbot on third-party websites or platforms. You agree to defend, indemnify, and hold harmless CoolBiz Web Design from any claims, damages, or liabilities arising from your use of the chatbot.</p>

            <h4>User Data Handling</h4>
            <p>The chatbot may process user data, including personal information. You are responsible for handling and storing this data in compliance with applicable laws, including but not limited to GDPR and CCPA. CoolBiz Web Design assumes no responsibility for your compliance with such laws.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Amendments</h3>
            <p>We reserve the right to modify these terms at any time. Continued service use after changes are made constitutes acceptance of the updated terms.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Termination</h3>
            <h4>By You</h4>
            <p>You may cancel your subscription at any time. Cancellation becomes effective at the end of the billing period.</p>

            <h4>By CoolBiz Web Design</h4>
            <p>We may terminate your access to the service for failure to comply with these terms, non-payment, or any harmful conduct without refunding any prepaid fees.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Dispute Resolution</h3>
            <p>All disputes will be resolved through binding arbitration under the Commercial Arbitration Rules of the American Arbitration Association, with arbitration held in Redding, California.
              For support, email us at support@coolbiz.info.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Affiliate Program</h3>
            <h4>Participation in the Affiliate Program</h4>
            <p>By joining the CoolBiz AI Chatbot Affiliate Program, affiliates agree to abide by the terms governing affiliate commissions, compliance, and promotional practices. Affiliates are responsible for their own tax obligations related to income earned from the affiliate program.</p>

            <h4>Commission Earnings</h4>
            <p>Affiliates earn a 30% recurring commission on approved subscription referrals, including renewals. Commissions earned in a given month are paid out by the 15th of the following month. If the 15th falls on a weekend or holiday, payouts will be processed the next business day.</p>

            <h4>Tax Obligations</h4>
            <p>Affiliates are solely responsible for all income taxes and other applicable taxes, including but not limited to VAT, GST, and any local, state, or federal taxes. CoolBiz AI Chatbot is not responsible for withholding taxes on behalf of affiliates, and affiliates are required to comply with all relevant tax regulations in their jurisdictions.</p>

            <h4>Compliance and Transparency</h4>
            <p>Affiliates must promote CoolBiz AI Chatbot truthfully, adhering to advertising standards. Misleading claims or unauthorized promotions may result in account termination and forfeiture of earnings.</p>

            <h4>Program Modifications</h4>
            <p>CoolBiz AI Chatbot reserves the right to adjust affiliate terms, including commission rates and eligibility requirements, with prior notice. All payouts are managed through a third-party affiliate management system, and CoolBiz AI Chatbot is not liable for delays or errors in processing affiliate payouts.</p>
          </div>

          <div class="privacy-policy_block">
            <h3>Agency Responsibilities</h3>
            <h4>Participation in Agency Program</h4>
            <p>Agencies participating in the CoolBiz AI Chatbot program are responsible for reselling services in compliance with all applicable tax regulations, including the collection and remittance of any required taxes on behalf of their clients.</p>

            <h4>Client Tax Compliance</h4>
            <p>Agencies are required to charge, collect, and remit applicable taxes (such as sales tax, VAT, or GST) on resold subscriptions. Agencies bear full responsibility for ensuring that these taxes are collected and remitted accurately and in compliance with their jurisdiction's tax laws.</p>

            <h4>Independent Tax Responsibility</h4>
            <p>Agencies are solely responsible for handling all onboarding processes for their own clients, creating subscription plans, and managing billing. Agencies must establish their own pricing and plan structures and are responsible for client communication and assistance related to onboarding, usage, and billing inquiries.</p>

            <h4>Program Modifications</h4>
            <p>CoolBiz AI Chatbot reserves the right to modify agency terms, with notice provided as necessary. Agencies must remain compliant with any updated terms to continue participating in the program.</p>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Terms;