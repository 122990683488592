import React, { createContext, useEffect, useState } from 'react';
import { CMS_BASE_URL, lOGIN, ME, SIGN_UP } from '../utils/APIEndPoints';
import { CallAPI } from '../utils/apiActions';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../helper/toastHelper';
import { LABEL } from '../utils/constant';
import { date } from 'yup';

const coolBizAdminState = {
  usersList: [],
  couponList: [],
  planList: [],
  planDescriptionList: [],
  cmsList: [],
  contactUsUsers: [],
  isLoading: false,
};

const AdminContext = createContext({
  ...coolBizAdminState
});

export const AdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useState(coolBizAdminState);

  const manageAdminState = (key, value) => dispatch((prev) => ({ ...prev, [key]: value }))

  const getAllUsers = async (page=1) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.ALL_USERS}?page=${page}`);
    manageAdminState('isLoading', false);
    if (response?.res?.data?.status === 200) {
      console.log("res_user",response?.res?.data?.data)
      manageAdminState('usersList', response?.res?.data?.data || []);
    }
  }
  const deleteUsers=async(userID="",cb)=>{
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "DELETE", `${CMS_BASE_URL.DELETE_USERS_BY_ADMIN}/${parseInt(userID)}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message)
    }
  }
  const createUsers=async(data,cb)=>{
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", `${CMS_BASE_URL.CREATE_USERS_BY_ADMIN}`,data);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 201) {
      cb(response.res.data.message)
    }
  }
  const createCoupon = async (body, cb) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.COUPON.CREATE, body);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message);
    }
  }

  const getAllCoupons = async (payload,page=1) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.COUPON.GET_ALL}?page=${page}&userId=${payload.userId}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      manageAdminState('couponList', response?.res?.data?.data || {});
    }
  }

  const deleteCoupon = async (payload, cb) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.COUPON.DELETE, payload);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message)
    }
  }

  const getAllPlan = async (payload,page=1) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.PLAN.GET_ALL}?page=${page}&userId=${payload.userId}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      manageAdminState('planList', response?.res?.data?.data || []);
    }
  }
  

  const createPlan = async (body, cb, id) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", id ? `${CMS_BASE_URL.PLAN.UPDATE}/${id}` : CMS_BASE_URL.PLAN.CREATE, body);
    manageAdminState('isLoading', false);
    if (response?.res?.data?.status === 200) {
      cb(response.res.data.message);
    }
  }
  const editPlan = async (body, cb, id) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST",  `${CMS_BASE_URL.PLAN.UPDATE}/${id}` ,body);
    manageAdminState('isLoading', false);
    if (response?.res?.data?.status === 200) {
      cb(response.res.data.message);
    }
  }
  
  const getAllPlanDescription = async (payload) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST", CMS_BASE_URL.PLAN_DESCRIPTION.GET_ALL, payload);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      manageAdminState('planDescriptionList', response?.res?.data?.data || []);
    }
  }

  const getPlanById = async (id) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.PLAN.GET_BY_ID}${id}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      return response?.res?.data?.data || [];
    }
  }

  const createCms = async (body, cb, type) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "POST",CMS_BASE_URL.CMS.CREATE, body, type);
    manageAdminState('isLoading', false);
    if (response?.res?.data?.status === 201) {
      cb(response.res.data.message);
    }
  }

  const updateCms = async (body, cb, id, type) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "PUT",`${CMS_BASE_URL.CMS.UPDATE}${id}`, body, type);
    manageAdminState('isLoading', false); 
    console.log(response)
    if (response?.res?.data?.success) {
      cb(response.res.data.message);
    }
  }

  const getAllCMS = async (type = 1, page = 1, limit = 10) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_ALL}${type}?page=${page}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      manageAdminState('cmsList', response?.res?.data?.data || []);
    }
  }

  const getAllContactUs = async (page = 1) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `contact-us?page=${page}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      manageAdminState('contactUsUsers', response?.res?.data?.data || []);
    }
  }

  const getCMSById = async (id) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "GET", `${CMS_BASE_URL.CMS.GET_BY_ID}${id}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      // manageAdminState('cmsList', response?.res?.data?.data || []);
      return response?.res?.data?.data || [];
    }
  }

  const deleteCms = async (id, cb) => {
    manageAdminState('isLoading', true);
    const response = await CallAPI("WEBSITE", "DELETE",`${CMS_BASE_URL.CMS.DELETE}${id}`);
    manageAdminState('isLoading', false);
    if (response?.res?.status === 200) {
      cb(response.res.data.message)
    }
  }

  const coolBizAdminValues = {
    ...state,
    getAllUsers,
    deleteUsers,
    createCoupon,
    getAllCoupons,
    deleteCoupon,
    getAllPlan,
    getAllPlanDescription,
    createPlan,
    getPlanById,
    createCms,
    getAllCMS,
    deleteCms,
    updateCms,
    getCMSById,
    getAllContactUs,
    createUsers
  }

  return (
    <AdminContext.Provider
      value={coolBizAdminValues}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
