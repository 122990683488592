import React, { useState } from "react";

const CommonTable = ({
  data = [],
  columns = [],
  actions = [],
  loading = false,
  pagination = {},
  isPagination = false,
  onRowSelectionChange,
  isSelectedRowFeature = false, // New Prop
}) => {
  const {
    current = 1,
    pageSize = 10,
    total = 0,
    onChange,
  } = pagination;

  const [selectedRows, setSelectedRows] = useState(new Set());

  const handleSelectAll = (e) => {
    const currentPageRowIds = data.map((item) => item.id);
    const updatedSelectedRows = new Set(selectedRows);

    if (e.target.checked) {
      currentPageRowIds.forEach((id) => updatedSelectedRows.add(id));
    } else {
      currentPageRowIds.forEach((id) => updatedSelectedRows.delete(id));
    }

    setSelectedRows(updatedSelectedRows);
    if (onRowSelectionChange) onRowSelectionChange([...updatedSelectedRows]);
  };

  const handleRowSelect = (id) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(id)) {
      updatedSelectedRows.delete(id);
    } else {
      updatedSelectedRows.add(id);
    }

    setSelectedRows(updatedSelectedRows);
    if (onRowSelectionChange) onRowSelectionChange([...updatedSelectedRows]);
  };

  const isAllCurrentPageSelected =
    data.length > 0 &&
    data.every((item) => selectedRows.has(item.id));

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead className="table-light">
            <tr>
              {/* Conditionally Render Select All Checkbox */}
              {isSelectedRowFeature && (
                <th className="text-center">
                  <input
                    type="checkbox"
                    checked={isAllCurrentPageSelected}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {columns.map((col) => (
                <th
                  key={col.key}
                  className={`text-${col.align || "left"}`}
                  style={{ minWidth: col.minWidth || "auto" }}
                >
                  {col.title}
                </th>
              ))}
              {actions && <th className="text-center action">Action</th>}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  colSpan={columns.length + (actions ? 2 : 1)}
                  className="text-center"
                >
                  Loading...
                </td>
              </tr>
            ) : data && data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id}>
                  {/* Conditionally Render Row Checkbox */}
                  {isSelectedRowFeature && (
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={selectedRows.has(item.id)}
                        onChange={() => handleRowSelect(item.id)}
                      />
                    </td>
                  )}
                  {columns.map((col) => (
                    <td
                      key={col.key}
                      className={`text-${col.align || "left"}`}
                      style={{ wordBreak: col.wordBreak || "break-word" }}
                    >
                      {col.render
                        ? col.render(item[col.key], item)
                        : item[col.key]}
                    </td>
                  ))}
                  {actions && (
                    <td className="text-center action">
                      {actions.map((action, idx) => (
                        <a
                          key={idx}
                          href={action.href || "javascript:void(0)"}
                          onClick={() => action.onClick(item)}
                          title={action.label}
                          className="mx-1"
                        >
                          <img src={action.icon} alt={action.label} />
                        </a>
                      ))}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length + (actions ? 2 : 1)}
                  className="text-center"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isPagination && (
        <React.Fragment>
          <div className="pagination-table">
            <button
              disabled={current === 1}
              onClick={() => onChange(current - 1)}
            >
              {"<<"}
            </button>

            {[...Array(Math.ceil(total / pageSize))].map((_, index) => (
              <button
                key={index}
                disabled={current === index + 1}
                onClick={() => onChange(index + 1)}
                className={current === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}

            <button
              disabled={current === Math.ceil(total / pageSize)}
              onClick={() => onChange(current + 1)}
            >
              {">>"}
            </button>
          </div>

          <div>
            Showing {(current - 1) * pageSize + 1} to{" "}
            {Math.min(current * pageSize, total)} of {total} entries
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CommonTable;
