import { useEffect } from "react";
import useChatBot from "../hooks/useChatBot";

export default function ChatSocketInitialization({ ...props }) {
    const { updateConnectedShortCode } = useChatBot();

    useEffect(() => {
        updateConnectedShortCode(props.shortCode || "951644555488")
    }, [props?.shortCode]);

}