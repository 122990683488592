import React, { useState } from 'react';
const AffiliateReferalActivity = ({ ...props }) => {
    return(
        
        <div>
            
    
            <div className="all-chatboat">
                <header className="d-md-flex align-items-center justify-content-between">
                    <h3>Referral Activity</h3>
                    
                    <div class="group-form d-md-flex">
                       <div className="filter-wrap">
                            <div className="filter-wrap_title">Filter by</div>
                            <div className="filter-wrap_block d-md-flex">
                                <input type="date" class="form-control w-140" id="" name=""></input>
                                <input type="date" class="form-control w-140" id="" name=""></input>

                                <select class="form-select w-140 select-border-gradient">
                                    <option value="" disabled="">STATUS</option>
                                    <option value="0">All</option>
                                    <option value="1">Status 1</option>
                                    <option value="2">Status 1</option>
                                </select>

                                <select class="form-select w-140">
                                    <option value="" disabled="">All User</option>
                                    <option value="0">All</option>
                                    <option value="1">Document</option>
                                    <option value="2">URL</option>
                                    <option value="3">Calender URL</option>
                                </select>

                                <button className="cta-white">Filter</button>
                            </div>
                       </div>

                        <a href="javascript:void(0)" className="cta-green">
                            <i className="fa-solid fa-sparkles" aria-hidden="true"></i>
                            Send New Invitation
                        </a>
                    </div>
                    
                </header>

                <div className="modalOverlay">
                    <div className="modal-container width-sm modal-dark-bg">
                        <a className="javascript:void(0)" class="modalClose"><img src="/images/icons/modal-close-icon.svg" alt="" /></a>
                        <div className="modal-form-grid pt-3 mt-3">
                            <div className="border-box-grey p-4">
                                <form className="form-step-2 text-center">
                                    <h3>Invite New User</h3>
                                    <div className="field text-left">
                                        <input type="text" class="form-control" id="" name="userName" placeholder="User Name" value="" />
                                    </div>
                                    <div className="field text-left">
                                        <input type="text" class="form-control" id="" name="companyName" placeholder="Company Name" value="" />
                                    </div>
                                    <div className="field text-left">
                                        <input type="text" class="form-control" id="" name="websiteURL" placeholder="Website URL" value="" />
                                    </div>
                                    <div className="field text-left">
                                        <input type="text" class="form-control" id="" name="Email" placeholder="Email" value="" />
                                    </div>
                                    <div className="field text-left">
                                        <input type="text" class="form-control" id="" name="phoneNumber" placeholder="Phone Number" value="" />
                                    </div>
                                    <div className="field  mb-0">
                                        <button className="cta-green" type="button">Update Knowledge</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table align-middle">
                    <thead>
                        <tr>
                        <th className="text-left">User name</th>
                        <th className="text-center">Email ID</th>
                        <th className="text-center">Invite date</th>
                        <th className="text-center">Converted date</th>
                        <th className="text-center">Invitation Link</th>
                        <th className="text-center">Status</th>
                        <th className="text-center action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-red">Pending</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-red">Pending</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-red">Pending</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo.. <a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-green">Converted</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">Elizabath Garsia</td>
                            <td className="text-center">elizabath.garsia@email.com</td>
                            <td className="text-center">03 Oct 24</td>
                            <td className="text-center">04 Oct 24</td>
                            <td className="text-center with-invitation-link">https:/mysite/demo...<a href="javascript:void(0)"><img src="/images/icons/copy-icon-w.svg" alt="" /></a></td>
                            <td className="text-center"><a href="javascript:void(0)" className="cta-red">Pending</a></td>
                            <td className="text-center action">
                                <a href="javascript:void(0)"><img src="/images/icons/edit-icon.svg"
                                alt="" /></a>
                                <a href="javascript:void(0)"><img src="/images/icons/delete-icon.svg"
                                alt="" /></a>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>  
        </div>
        
    )
}
export default AffiliateReferalActivity