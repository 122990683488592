import React from 'react';
// import {
//   Box,
//   LinearProgress,
// } from '@mui/material';

const SlashScreen = () => {

  return (
    <div className="page-loader">
      <img src="images/loader-bouncing-circles.svg" alt="" />
    </div>
  );
}

export default SlashScreen;
