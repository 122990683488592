import React, { createContext, useEffect, useReducer, useState } from 'react';
import io from 'socket.io-client';
import { socketConnection } from '../utils/socket';

const initialAuthState = {
    receivedQuestionType: '',
    connectedShortCode: '',
    globalSocket: null,
    messageList: [],
    receivedMessage: null,
    responseDelay: false
};

const reducer = (state, action) => {
    console.log("action---", action)
    switch (action.type) {
        case 'GLOBAL_SOCKET_CONNECTION': {
            return {
                ...state,
                globalSocket: action.payload,
            };
        }
        case 'MSG_LIST_UPDATE': {
            return {
                ...state,
                messageList: action.payload,
            };
        }
        case 'RECEIVED_MSG_UPDATE': {
            return {
                ...state,
                receivedMessage: action.payload,
            };
        }
        case 'RECEIVED_QUESTION_TYPE': {
            return {
                ...state,
                receivedQuestionType: action?.payload,
            };
        }
        case 'SET_CONNECTED_SHORT_CODE': {
            return {
                ...state,
                connectedShortCode: action.payload,
            };
        }
        case 'DELAY_UPDATE': {
            return {
                ...state,
                responseDelay: action.payload,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const ChatBotContext = createContext({
    ...initialAuthState,
    updateDelay: () => Promise.resolve(),
    setGlobalSocket: () => Promise.resolve(),
    updateReceivedMsg: () => Promise.resolve(),
    updateMsgList: () => Promise.resolve(),
    updateConnectedShortCode: () => Promise.resolve(),
    updateReceivedQuestionType: () => Promise.resolve(),
});

export const ChatBotProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        if (!!state.connectedShortCode) {
            const ws = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}${state.connectedShortCode}/`);

            ws.onopen = () => {
                console.log('WebSocket connected');
            };
            setGlobalSocket(ws)

            ws.onmessage = (data) => {
                updateDelay(false)
                console.log('WebSocket onmessage', data, JSON.parse(data.data));
                updateReceivedMsg({ ...JSON.parse(data?.data).message?.bot, directionType: "receive", isAnswered:false });
                updateReceivedQuestionType(JSON.parse(data?.data).message?.bot.type)
            };

            // ws.send(JSON.stringify({"message":{"id":"1","user_message":"hi"}}))

            // ws.onclose = () => {
            //     console.log('WebSocket disconnected');
            // };

            // ws.onerror = (error) => {
            //     console.error('WebSocket error:', error);
            // };

            // ws.receive = (data) => {
            //     console.log('WebSocket Receive', data);
            // };

            return () => {
                ws.close();;
            };
        }
    }, [state.connectedShortCode]);

    useEffect(() => {
        if (!!state.receivedMessage) {
            let laMsgList = [...state.messageList]
            laMsgList.push(state.receivedMessage)
            console.log("laMsgList---", laMsgList)
            updateMsgList([...laMsgList])
        }
        updateReceivedMsg(null)
    }, [state.receivedMessage]);

    const updateDelay = (value) => {
        dispatch({ type: 'DELAY_UPDATE', payload: value })
    }

    const updateReceivedMsg = (value) => {
        dispatch({ type: 'RECEIVED_MSG_UPDATE', payload: value })
    }

    const updateReceivedQuestionType = (value) => {
        dispatch({ type: 'RECEIVED_QUESTION_TYPE', payload: value })
    }

    const updateMsgList = (value) => {
        dispatch({ type: 'MSG_LIST_UPDATE', payload: value })
    }

    const setGlobalSocket = (value) => {
        dispatch({ type: 'GLOBAL_SOCKET_CONNECTION', payload: value })
    }

    const updateConnectedShortCode = (value) => {
        dispatch({ type: 'SET_CONNECTED_SHORT_CODE', payload: value })
    }

    return (
        <ChatBotContext.Provider
            value={{
                ...state,
                updateDelay,
                setGlobalSocket,
                updateReceivedMsg,
                updateMsgList,
                updateConnectedShortCode,
                updateReceivedQuestionType
            }}
        >
            {children}
        </ChatBotContext.Provider>
    );
};

export default ChatBotContext;
