import React from "react";
import { useNavigate } from "react-router-dom";

const SignUpBG = () => {
    const navigate = useNavigate();
    return (
        <div
            className="form-grid_right"
            style={{
                backgroundImage: 'url(images/login-bg.jpg)',
            }}
        >
            <div className="form-right-img-block create-acc">
                <div className="btn-grid">
                    <a
                        href="javascript:void(0)"
                        className="cta-white"
                        onClick={() => navigate("/login")}
                    >
                        Sign In
                    </a>
                </div>
                <header>
                    <h2>
                        <span>Unlock</span> the power of
                    </h2>
                    <h3>CoolBiz AI Chatbot</h3>
                </header>
                <figure>
                    <img src="images/create-account-img.svg" alt />
                </figure>
            </div>
        </div>
    )
}
export default SignUpBG